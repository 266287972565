import * as _ from 'lodash';
import { FirestoreDatabaseService } from '../shared/FirestoreDatabase';

const TABLE_NAME = '/users';

export class UserFirebaseService extends FirestoreDatabaseService {
  constructor() {
    super(TABLE_NAME);
  }

  async addUser(user) {
    const isUserExistsResult = await this.isExist('email', '==', user.email);

    if (!_.isNull(isUserExistsResult)) {
      const userInDB = await this.getById(isUserExistsResult);
      return userInDB;
    }

    const newUserId = await this.add(user);

    const newUserInDB = _.assign({}, user, { id: newUserId });
    return newUserInDB;
  }

  async deleteUser(user) {
    await this.delete(user.id);
  }

  async updateUserData(user, token) {
    if (_.isEmpty(token)) {
      return;
    }

    const userId = _.get(user, 'id');

    const userDataInDB = await this.getById(userId);

    const tokenInDB = _.get(userDataInDB, 'token');

    if (tokenInDB === token) {
      return;
    }

    const userForUpdate = _.assign({}, userDataInDB, { token: token });
    await this.update(userId, userForUpdate);
    return userForUpdate;
  }

  async updateUser(user) {
    const userId = _.get(user, 'id');

    const userDataInDB = await this.getById(userId);

    const userForUpdate = _.assign({}, userDataInDB, user);

    const updatedUser = await this.update(userId, userForUpdate);
    return updatedUser;
  }
}
