import React from "react";
import { IoMdCreate, IoIosArrowBack, IoMdClose } from "react-icons/io";
import { withRouter } from "react-router-dom";

import { NavLinkHelper } from "../../helpers/NavLink";

import "./Header.scss";
import { useLocalStorage } from "../../shared/LocalStorageHook";

const Header = ({
  title,
  setAppPagesStatus,
  history,
  clickedIcon,
  setSignInPageStatus,
  setClickedIcon = () => {},
}) => {
  // eslint-disable-next-line
  const [localStorageCoffee, setLocalStorageCoffee] = useLocalStorage(
    "coffee",
    null
  );

  const currentClickedIcon = NavLinkHelper.getCurrentNavIcon(
    history.location.pathname
  );

  if (currentClickedIcon !== clickedIcon) {
    setClickedIcon(currentClickedIcon);
  }

  const onBackArrowClick = () => {
    setLocalStorageCoffee(null);
    history.goBack();
  };

  const openAvailabilityPage = () => {
    setAppPagesStatus({ availabilityPageStatus: true });
  };

  const headerTitle = (title) => {
    if (title === "Log In" || title === "Sign Up") {
      return (
        <div className="back-button-with-icon">
          <IoMdClose
            className="close-navigation"
            onClick={() => {
              setSignInPageStatus(false);
            }}
          />
          <h1>{title}</h1>
        </div>
      );
    }
    return (
      <div className="back-button-with-icon">
        <IoIosArrowBack
          className="back-navigation"
          onClick={onBackArrowClick}
        />
        <h1>{title}</h1>
      </div>
    );
  };

  return (
    <header>
      {title && headerTitle(title)}
      {!title && (
        <React.Fragment>
          <h1>CoffeeTalk</h1>
          <p onClick={openAvailabilityPage} className="edit-my-availability">
            Filter results
            <IoMdCreate />
          </p>
        </React.Fragment>
      )}
    </header>
  );
};

export default withRouter(Header);
