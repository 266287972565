import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ConfirmPayment from "./ConfirmPayment";
import { STRIPE_API_KEY } from "../../shared/constants";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
const stripePromise = loadStripe(STRIPE_API_KEY);

export default function StripeModal({ user }) {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    appearance,
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      <ConfirmPayment />
    </Elements>
  );
}