import React, {useState, useRef, useEffect} from "react";
import {AiOutlineSearch} from "react-icons/ai";

import CoffeeCard from "../../containers/CoffeeCard";
import * as _ from "lodash";

import "./Home.scss";
import {RelationControlCoffeeService} from "../../services/RelationControlCoffee";
import {PermissionsService} from "../../services/Permissions";
import {getFakeCards} from "../../helpers/FakeCards";

import moment from "moment";
import GrabCoffee from "../../containers/GrabCoffee";
import Spinner from "../spinner";
import {FormatHelper} from "../../helpers";
import {MeetingTypes} from "../../shared/MeetingTypes";

const Home = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const defaultSelectedCoffeeId = queryParams.get('coffeeId');
  const defaultStatus = queryParams.get('status');
  const {user, setTitle, setSignInPageStatus, setPopUpNotificationText} = props;
  const searchInput = useRef(null);
  const [coffees, setCoffees] = useState([]);

  const [activeGrabCoffee, setActiveGrabCoffee] = useState({});
  const [filteredCoffees, setFilteredCoffees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isAccessToUserLocationEnabled, setAccessToUserLocation] = useState(
    null
  );

  useEffect(() => {
    async function getAccessToUserLocation() {
      await PermissionsService.getLocationPermission().then((access) => {
        setAccessToUserLocation(access);
      });
    }

    getAccessToUserLocation();

    if (props.signInPageStatus) {
      return;
    }

    setTitle(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllCoffees();
    // eslint-disable-next-line
  }, [
    user.availabilityFrom,
    user.availabilityTo,
    // eslint-disable-next-line
    user?.filter?.zoom,
    // eslint-disable-next-line
    user?.filter?.shops,
    isAccessToUserLocationEnabled,
  ]);

  useEffect(() => {
    if (defaultStatus) {
      setPopUpNotificationText({text: defaultStatus});
    }
  }, [defaultStatus])

  const setCards = (allCoffees) => {
    if (_.isNull(isAccessToUserLocationEnabled)) {
      return [];
    }

    const fakeCards = getFakeCards();

    const fakeAndUserCardsUnion = _.sortBy(
      allCoffees.concat(fakeCards),
      "time_from"
    );

    const cardsForView = fakeAndUserCardsUnion
      .filter((c) => c.type === MeetingTypes.LIVE)
      .concat(
        fakeAndUserCardsUnion.filter((c) => c.type !== MeetingTypes.LIVE)
      );

    if (!isAccessToUserLocationEnabled) {
      if (user?.filter?.zoom === false) {
        setCoffees([]);
        return;
      }

      const filteredCoffees = cardsForView.filter((c) => {
        const cfs = _.get(c, "coffees", null);
        return cfs === null;
      });
      setCoffees(filteredCoffees);
      return;
    }

    if (user?.filter?.zoom && !user?.filter?.shops) {
      const filteredCoffees = cardsForView.filter((c) => {
        const cfs = _.get(c, "coffees", null);
        return cfs === null;
      });
      setCoffees(filteredCoffees);
      return;
    }

    if (!user?.filter?.zoom && user?.filter?.shops) {
      const filteredCoffees = cardsForView.filter((c) => {
        const cfs = _.get(c, "coffees", null);
        return cfs !== null;
      });
      setCoffees(filteredCoffees);
      return;
    }

    setCoffees(cardsForView);
  };

  const getAllCoffees = async () => {
    setIsLoading(true);

    // check is user availability less than current time, reset user availability
    let availabilityFrom = user.availabilityFrom;
    let availabilityTo = user.availabilityTo;

    if (availabilityTo < moment().unix()) {
      const defaultAvailability = FormatHelper.getEmptyUserInfoForRedux();
      availabilityFrom = defaultAvailability.availabilityFrom;
      availabilityTo = defaultAvailability.availabilityTo;
    }

    const allCoffees = await RelationControlCoffeeService.getAllCoffee(
      availabilityFrom,
      availabilityTo
    );

    setCards(allCoffees);
    setIsLoading(false);

    setFilteredCoffees([]);
    _.set(searchInput, "current.value", "");
    return allCoffees;
  };

  const searchCoffees = (event) => {
    const resultCoffees = _.filter(coffees, (coffee) => {
      return coffee.description.includes(event.target.value);
    });
    setFilteredCoffees(resultCoffees);
  };

  const grabToCoffee = async (coffee) => {
    if (_.isEmpty(user.id)) {
      setSignInPageStatus(true);
      return;
    }

    setActiveGrabCoffee(coffee);
  };

  const filterCoffeesAfterCancel = (coffeeId) => {
    const updatedCoffees = coffees.filter((c) => c.id !== coffeeId);
    setCoffees(updatedCoffees);
  };

  const renderCardList = () => {
    const cardList = filteredCoffees.length ? filteredCoffees : coffees;
    return (
      <React.Fragment>
        {isLoading && (
          <Spinner
            loaderContainerName="home-page-loader-container"
            blockName="home-page-container"
          />
        )}
        {cardList.map((coffee, index) => {
          const isAlmostOverTime =
            moment.unix(coffee.time_to).diff(moment().unix(), "minutes") < 20 ||
            coffee.type === MeetingTypes.LIVE;

          return (
            <CoffeeCard
              key={coffee.description + index}
              grabToCoffee={grabToCoffee}
              coffee={coffee}
              user={coffee.user}
              isAlmostOverTime={isAlmostOverTime}
              status={coffee.status}
              setPopUpNotificationText={props.setPopUpNotificationText}
              setIsLoading={setIsLoading}
              setSignInPageStatus={setSignInPageStatus}
              filterCoffeesAfterCancel={filterCoffeesAfterCancel}
              defaultSelectedCoffeeId={defaultSelectedCoffeeId}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const errorText = () => {
    if (!coffees.length && !isLoading) {
      return "We didn't find talks in your location for this time, please try different time frames";
    }

    if (
      sessionStorage?.availabilityTo < moment().unix() &&
      moment().get("hours") !== 23
    ) {
      return "If you can't see any results, please check your filter results to find a match";
    }

    return false;
  };

  if (!_.isEmpty(activeGrabCoffee)) {
    return (
      <div className="home-page-container">
        <GrabCoffee
          coffee={activeGrabCoffee}
          user={user}
          setActiveGrabCoffee={setActiveGrabCoffee}
        />
      </div>
    );
  }

  return (
    <div className="home-page-container">
      {!_.isEmpty(activeGrabCoffee) && (
        <GrabCoffee
          coffee={activeGrabCoffee}
          user={user}
          setActiveGrabCoffee={setActiveGrabCoffee}
        />
      )}
      <div className="search-input-container">
        <AiOutlineSearch className="search-icon"/>
        <input
          className="search-input"
          placeholder="Find a topic..."
          onChange={searchCoffees}
          ref={searchInput}
        />
      </div>
      <div className="coffee-cards-container">
        {errorText() ? (
          <div
            style={{
              margin: "auto",
              width: 400,
              padding: 10,
              textAlign: "center",
            }}
          >
            {errorText()}
          </div>
        ) : (
          renderCardList()
        )}
      </div>
    </div>
  );
};

export default Home;
