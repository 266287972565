import * as actions from "../redux/actions";
import { connect } from "react-redux";
import SignInScreen from "../components/auth";

export function mapStateToProps({ userState, coffeeState }) {
  return {
    user: userState,
    fakeCards: coffeeState.fakeCards,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: (payload) => dispatch(actions.setUserInfo(payload)),
    setEmptyUserInfo: (payload) => dispatch(actions.setEmptyUserInfo(payload)),
    setSignInPageStatus: (payload) =>
      dispatch(actions.setSignInPageStatus(payload)),
    setSignInPageText: (payload) =>
      dispatch(actions.setSignInPageText(payload)),
    setPopUpNotificationText: (payload) =>
      dispatch(actions.setPopUpNotificationText(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);
