import React from 'react';
import './Input.scss';

const Input = React.forwardRef((props, ref) => (
  <input
    ref={ref}
    className={`${props.status} styled-input`}
    placeholder={props.placeholder}
  />
));

export default Input;
