import * as actions from "../redux/actions";
import { connect } from "react-redux";
import Topics from "../components/topics";

export function mapStateToProps({ topicsState, mapState }) {
  return {
    topics: topicsState,
    currentLocation: mapState.currentLocation,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setTopics: (payload) => dispatch(actions.setTopics(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
