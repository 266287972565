import React, { useRef } from "react";

import useOutsideClick from "../../../shared/OutsideClick";

import "./DesktopContextMenu.scss";

const DesktopContextMenu = ({
  contextMenu = 0,
  handleOutsideClickSettingsMenu = () => {},
  initContextMenu = () => {},
  secondStepContextMenu = () => {},
}) => {
  const settingMenuRef = useRef(null);

  const settingsHandlerWrapper = (e) => {
    handleOutsideClickSettingsMenu(e);
  };

  useOutsideClick(settingMenuRef, settingsHandlerWrapper);

  return (
    <div className="desktop-context-menu" ref={settingMenuRef}>
      {contextMenu === 0 ? initContextMenu() : secondStepContextMenu()}
    </div>
  );
};

export default DesktopContextMenu;
