import * as _ from "lodash";

export class MapHelper {
  static async getPlaces(mapProps, map, requestParams) {
    const { google } = mapProps;

    const service = new google.maps.places.PlacesService(map);

    const getPlacesRequest = (event) =>
      new Promise((resolve) => {
        //const places = [];
        service.nearbySearch(event, (results, pageToken) => {
          /* if (pageToken.hasNextPage) {
            pageToken.nextPage();
            places.push(...results);
          } else {
            places.push(...results);
            resolve(places);
          }*/
          resolve(results);
        });
      });
    const places = await getPlacesRequest(requestParams);
    return places;
  }

  static async getLocationNameByLatLng(lat, lng) {
    if (_.isUndefined(lat) || _.isUndefined(lng)) {
      return;
    }
    const requestUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key= +
      ${process.env.REACT_APP_GOOGLE_API_KEY}`;
    const response = await fetch(requestUrl).then((res) => res.json());
    const placeName = MapHelper.getLocationNameFromGoogleCode(response);
    return placeName;
  }

  static async getRegionByLatLng(lat, lng) {
    if (_.isUndefined(lat) || _.isUndefined(lng)) {
      return;
    }
    const requestUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key= +
      ${process.env.REACT_APP_GOOGLE_API_KEY}`;
    const response = await fetch(requestUrl).then((res) => res.json());
    const placeName = MapHelper.getRegion(response);
    return placeName;
  }

  static getLocationNameFromGoogleCode(geocoding) {
    try {
      const compoundCode = _.get(geocoding, "plus_code.compound_code");
      const splittedCompoundCode = compoundCode.split(" ");
      const placeName = `${splittedCompoundCode[1]} ${splittedCompoundCode[2]}`;
      return placeName;
    } catch (err) {
      return "";
    }
  }

  static getRegion(geocoding) {
    try {
      const politicalCountryName = _.slice(geocoding.results, -1);
      const addressComponents = _.get(
        politicalCountryName[0],
        "address_components"
      );
      const lastElement = addressComponents.pop();
      const regionName = lastElement.short_name;
      return regionName;
    } catch (err) {
      return "us";
    }
  }

  static async getCurrentLocation() {
    const getData = function(options) {
      return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
    };

    if (navigator.geolocation) {
      return getData()
        .then((data) => {
          return {
            lat: data.coords.latitude,
            lng: data.coords.longitude,
          };
        })
        .catch((err) => {
          return {};
        });
    }

    return {};
  }

  static getDistanceBetween(p1, p2) {
    const rad = function(x) {
      return (x * Math.PI) / 180;
    };

    const earth_radius = 6378137; // Earth’s mean radius in meter
    const dLat = rad(p2.lat - p1.lat);
    const dLong = rad(p2.lng - p1.lng);
    const square_of_half_chord_length_between_points =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const angular_distance_radians =
      2 *
      Math.atan2(
        Math.sqrt(square_of_half_chord_length_between_points),
        Math.sqrt(1 - square_of_half_chord_length_between_points)
      );
    const distance = earth_radius * angular_distance_radians;

    return distance;
  }
}
