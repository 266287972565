import React, { useState /*useRef*/ } from "react";
import "date-fns";
// import DatePicker from "react-mobile-datepicker";
// import Popover from "@material-ui/core/Popover";
import "./TimePage.scss";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import Input from "@material-ui/core/Input";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CloseButton from "../../shared/icons/edit-pop-up-close-button.svg";
// import Watches from "../../shared/icons/watches.svg";

import * as _ from "lodash";
import * as moment from "moment";
import { OrangeCheckbox } from "../orange-checkbox";
// import useOutsideClick from "../../shared/OutsideClick";

const TimePageComponent = ({ setUserInfo, user, setAppPagesStatus }) => {
  // const [availabilityFrom, setAvailabilityTimeIntervalFrom] = useState(
  //   sessionStorage?.availabilityFrom * 1000 || user.availabilityFrom * 1000
  // );
  // const [availabilityTo, setAvailabilityTimeIntervalTo] = useState(
  //   sessionStorage?.availabilityTo * 1000 || user.availabilityTo * 1000
  // );
  // const [invalidTime, setInvalidTime] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    sessionStorage.getItem('availabilityFrom') * 1000 || user.availabilityFrom * 1000
  );

  // const [pickerFrom, setPickerFrom] = useState(false);
  // // eslint-disable-next-line
  // const [pickerTo, setPickerTo] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(false);
  const [filter, setFilter] = React.useState({
    shops: user?.filter?.shops !== undefined ? user.filter.shops : true,
    zoom: user?.filter?.zoom !== undefined ? user.filter.zoom : true,
  });

  // const timeFromRef = useRef(null);
  // const timeToRef = useRef(null);

  // useOutsideClick(timeFromRef, () => setPickerFrom(false));
  // useOutsideClick(timeToRef, () => setPickerTo(false));

  const onComplete = () => {
    setAppPagesStatus({ availabilityPageStatus: false });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const setAvailability = () => {
    // let formatFromToMS = moment(availabilityFrom).unix();
    // let formatToToMS = moment(availabilityTo).unix();

    // const isTimeIntervalValid = moment(availabilityFrom).isSameOrAfter(
    //   availabilityTo,
    //   "milliseconds"
    // );

    // if (isTimeIntervalValid) {
    //   setInvalidTime(true);
    //   setTimeout(() => setInvalidTime(false), 1000);
    //   return;
    // }

    // const isOldDate = moment(selectedDate).diff(moment(), "days") < 0;

    // if (isOldDate < 0) {
    //   return;
    // }

    // const dayDiff = moment(selectedDate).diff(availabilityFrom, "days");

    // const availabilityFromPlusDay = moment(availabilityFrom)
    //   .add(dayDiff, "day")
    //   .format();
    // const availabilityToPlusDay = moment(availabilityTo)
    //   .add(dayDiff, "day")
    //   .format();

    // formatFromToMS = moment(availabilityFromPlusDay).unix();
    // formatToToMS = moment(availabilityToPlusDay).unix();

    const from = moment(selectedDate)
      .startOf("day")
      .unix();
    const to = moment()
      .add(15, "days")
      .startOf("day")
      .unix();

    const updatedUser = _.assign(user, {
      availabilityFrom: +from,
      availabilityTo: +to,
      filter: filter,
    });

    sessionStorage.setItem("availabilityFrom", +from);
    sessionStorage.setItem("availabilityTo", +to);

    setUserInfo(updatedUser);
    onComplete();
  };

  // const getTimeIn12hFormat = (date) => {
  //   return moment(date).format("h:mm A");
  // };

  const daysRange = (date) => {
    const diff = moment(date)
      .startOf("day")
      .diff(moment(), "days");
    return diff > 13 || diff < 0;
  };

  // const formatAMPM = (date) => {
  //   let hours = date.getHours();
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'
  //   return hours;
  // };

  // const dateConfig = {
  //   hour: {
  //     format: (value) => formatAMPM(value),
  //     // format: "hh",
  //     caption: "Hour",
  //     step: 1,
  //   },
  //   minute: {
  //     format: "mm",
  //     caption: "Min",
  //     step: 15,
  //   },
  // };

  // const handleClick = (event, timeType) => {
  //   if (timeType === "from") {
  //     setPickerTo(false);
  //     setPickerFrom(true);
  //   }
  //   if (timeType === "to") {
  //     setPickerTo(true);
  //     setPickerFrom(false);
  //   }

  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const onSelectTime = (time) => {
  //   const newTime = moment(time).unix();

  //   if (pickerFrom) {
  //     const hours = moment(newTime * 1000).get("hours");
  //     const minutes = moment(newTime * 1000).get("minutes");

  //     const timeFrom = moment(selectedDate)
  //       .set("hours", hours)
  //       .set("minutes", minutes)
  //       .set("seconds", 0)
  //       .unix();

  //     const isTimeToBeforeOrSame = moment(availabilityTo).isSameOrBefore(
  //       timeFrom * 1000,
  //       "minutes"
  //     );

  //     if (isTimeToBeforeOrSame) {
  //       const timeTo = moment(timeFrom * 1000)
  //         .add(15, "minutes")
  //         .unix();
  //       setAvailabilityTimeIntervalTo(timeTo * 1000);
  //     }

  //     setAvailabilityTimeIntervalFrom(timeFrom * 1000);
  //     return;
  //   }

  //   const isTimeIntervalValid = moment(newTime * 1000).isAfter(
  //     availabilityFrom,
  //     "milliseconds"
  //   );

  //   const isSameDay =
  //     moment(moment(newTime * 1000)).isSame(availabilityFrom, "day") ||
  //     (moment(moment(newTime * 1000)).isSame(
  //       moment(availabilityFrom).add(1, "day"),
  //       "day"
  //     ) &&
  //       moment(newTime * 1000).get("hours") === 0 &&
  //       moment(newTime * 1000).get("minutes") === 0);

  //   if (isTimeIntervalValid && isSameDay) {
  //     setAvailabilityTimeIntervalTo(newTime * 1000);
  //     return;
  //   }

  //   setInvalidTime(true);
  //   setTimeout(() => setInvalidTime(false), 1000);
  // };

  // const getPickerValue = () => {
  //   if (pickerFrom) {
  //     return moment(availabilityFrom).toDate();
  //   }
  //   return moment(availabilityTo).toDate();
  // };

  const handleChange = (event) => {
    const filterValues = Object.values(filter);
    const isTrueValueUniq =
      filterValues.indexOf(true) === filterValues.lastIndexOf(true);

    if (!event.target.checked && isTrueValueUniq) {
      return;
    }

    setFilter({ ...filter, [event.target.name]: event.target.checked });
  };

  // const pickerOpen = Boolean(anchorEl);

  return (
    <div className="time-page">
      <div className="time-page-content">
        <div className="time-page-text"></div>

        <button className="close" onClick={onComplete}>
          <img src={CloseButton} alt="close" />
        </button>

        <div className="availability-container">
          <div className="availability-item">
            <span className="name">Date:</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                shouldDisableDate={daysRange}
                disableToolbar
                variant="inline"
                format="MMMM dd, yyyy"
                margin="normal"
                id="date-picker-inline"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {/* 
          <div className="availability-item">
            <span className="name">From:</span>
            <div id={invalidTime ? "anim-error" : "error"}>
              <Input
                onClick={(e) => handleClick(e, "from")}
                type={"text"}
                value={getTimeIn12hFormat(availabilityFrom)}
                onChange={() => {}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <img src={Watches} alt="close" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>

          <div className="availability-item">
            <span className="name">To:</span>
            <div id={invalidTime ? "anim-error" : "error"}>
              <Input
                onClick={(e) => handleClick(e, "to")}
                type={"text"}
                value={getTimeIn12hFormat(availabilityTo)}
                onChange={() => {}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <img src={Watches} alt="close" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
          </div>

          <Popover
            open={pickerOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { width: "250px", height: "200px", marginTop: "-9px" },
            }}
          >
            <DatePicker
              value={getPickerValue()}
              isOpen={true}
              theme="ios"
              isPopup={false}
              showHeader={true}
              showFooter={true}
              dateConfig={dateConfig}
              onChange={onSelectTime}
            />
          </Popover> */}
        </div>

        <div className="checkbox-container">
          <FormControlLabel
            control={
              <OrangeCheckbox
                checked={filter.shops}
                onChange={handleChange}
                name="shops"
              />
            }
            label="Coffee shops"
          />
          <FormControlLabel
            control={
              <OrangeCheckbox
                checked={filter.zoom}
                onChange={handleChange}
                name="zoom"
              />
            }
            label="Zoom"
          />
        </div>

        <button className="enter" onClick={setAvailability}>
          Search
        </button>
      </div>
    </div>
  );
};

export default TimePageComponent;
