import React from "react";
import { FiUser } from "react-icons/fi";
import EditIcon from "../../shared/icons/edit.svg";

import * as _ from "lodash";

const UserSmallAvatar = ({
  userAvatarLink,
  avatarClassName,
  edit = false,
  onClick,
}) => {
  const link = _.isArray(userAvatarLink) ? userAvatarLink[0] : userAvatarLink;

  let userImage = (
    <>
      <img
        className={avatarClassName}
        alt="User"
        src={link}
        referrerPolicy="no-referrer"
      />
    </>
  );

  if (edit) {
    userImage = (
      <div onClick={onClick}>
        <img
          className={avatarClassName}
          alt="User"
          src={link}
          referrerPolicy="no-referrer"
        />
        <div className="load-avatar-button">
          <label className="custom-file-upload">
            <img src={EditIcon} alt="edit" />
          </label>
        </div>
      </div>
    );
  }

  return (
    <>
      {_.isEmpty(link) ? (
        <FiUser onClick={onClick} className={avatarClassName} />
      ) : (
        userImage
      )}
    </>
  );
};

export default UserSmallAvatar;
