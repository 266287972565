import * as moment from "moment";
// import { FormatHelper } from "../helpers/Formater";
import { TimePickerService } from "../services/TimePicker";
import { MeetingTypes } from "../shared/MeetingTypes";
import { PaymentTypes } from "../shared/PaymentTypes";

function randomStartDate(userAvFrom, userAvTo) {
  const cardHours = moment(userAvFrom * 1000).format("HH");
  const cardMinutes = moment(userAvFrom * 1000).format("mm");
  const diff = moment(userAvTo * 1000).diff(userAvFrom * 1000, "minutes");

  let startSteps = +diff / 15;

  if (startSteps >= 2) {
    startSteps = startSteps - 2;
  }

  const steps = Math.floor(Math.random() * startSteps);

  const minutes = steps * 15;
  const date = moment(userAvFrom * 1000)
    .set("hours", cardHours)
    .set("minutes", cardMinutes)
    .set("seconds", 0);

  const resultDate = moment(date)
    .add(minutes, "minutes")
    .unix();

  return resultDate;
}

function randomEndTime(cardTimeFrom, userAvTo) {
  const cardHours = moment(cardTimeFrom * 1000).format("HH");
  const cardMinutes = moment(cardTimeFrom * 1000).format("mm");
  const diff = moment(userAvTo * 1000).diff(cardTimeFrom * 1000, "minutes");

  const startSteps = +diff / 15;

  let steps = 0;
  if (startSteps === 1) {
    steps = 1;
  } else if (startSteps > 3) {
    steps = Math.floor(Math.random() * (startSteps - 4)) + 4;
  } else {
    steps = Math.floor(Math.random() * (startSteps - 2)) + 2;
  }

  const minutes = steps * 15;
  const date = moment(cardTimeFrom * 1000)
    .set("hours", cardHours)
    .set("minutes", cardMinutes)
    .set("seconds", 0);

  const resultDate = moment(date)
    .add(minutes, "minutes")
    .unix();

  return resultDate;
}

function filterFakeCardsByDurationTime(userAvFrom, userAvTo) {
  return mocksCards.map((card) => {
    card.time_from = randomStartDate(userAvFrom, userAvTo);
    card.time_to = randomEndTime(card.time_from, userAvTo);

    const meetingTimeWindow = (card.time_to - card.time_from) / 60;

    if (meetingTimeWindow < card.duration_time) {
      card.duration_time = 30;
    }

    if (meetingTimeWindow < 30) {
      card.duration_time = 15;
    }

    return card;
  });
}

export const getFakeCards = () => {
  // const user = FormatHelper.getEmptyUserInfoForRedux();

  // let userAvStart = user.availabilityFrom;
  // let userAvEnd = user.availabilityTo;

  let userAvStart = moment()
    .startOf("day")
    .unix();
  let userAvEnd = moment(TimePickerService.getRoundedTime())
    .add(15, "days")
    .startOf("day")
    .unix();

  // if (moment(userAvStart * 1000).isBefore(moment())) {
  //   userAvStart = moment(TimePickerService.getRoundedTime()).unix();
  //   userAvEnd = moment(TimePickerService.getRoundedTime())
  //     .add(2, "h")
  //     .unix();
  //
  //   if (
  //     userAvEnd >
  //     moment()
  //       .add(1, "d")
  //       .startOf("day")
  //       .unix()
  //   ) {
  //     userAvEnd = moment()
  //       .add(1, "d")
  //       .startOf("day")
  //       .add(-15, "m")
  //       .unix();
  //   }
  // }

  const cards = filterFakeCardsByDurationTime(userAvStart, userAvEnd);

  return cards;
};

const mocksCards = [
  {
    description: "The Last Dance on Netflix. Let's talk Pippen vs. MJ.",
    duration_time: 60,
    id: "1",
    short_description: "The Last Dance on Netflix",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 28,
      education: "Baruch College",
      name: "Tina Brown",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593418017957images.jpg?alt=media&token=f51f0716-899f-463b-9d1a-24607ef05ce6",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near NYU",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near NYU",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Near NYU",
      },
    ],
  },
  {
    description: "Bitcoin and Cryptos. Would you invest?",
    duration_time: 60,
    id: "2",
    short_description: "Bitcoin and Cryptos",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: null,
    type: MeetingTypes.ZOOM_ONE_BY_ONE,
    user: {
      age: 33,
      education: "Columbia University",
      name: "Lily Bennett",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593418144696Promobl6618.jpg?alt=media&token=587f194f-5652-478c-aed3-3adf766b0809",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description:
      "The Science of Happiness. Did you take this course at NYU? What do you think about it?",
    duration_time: 60,
    id: "3",
    short_description: "The Science of Happiness",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 29,
      name: "Joe Newman",
      education: "New York University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F15934184100851253603.jpg?alt=media&token=777825f7-67a0-4f43-bfb3-e64dbe1e6476",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Upper East Side",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
  },
  {
    description:
      "Sport Events. The role of the crowd in sporting events. Can we live without it?",
    duration_time: 60,
    id: "4",
    short_description: "Sport Events",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 31,
      name: "Hendrix Graham",
      education: "Baruch College",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419214298download.jpg?alt=media&token=c029f136-b5ee-44bc-bcc9-3d72c282018d",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: null,
    type: MeetingTypes.ZOOM_ONE_BY_ONE,
  },
  {
    description: "Investing in Stocks. Which broker should I use?",
    duration_time: 60,
    id: "5",
    short_description: "Investing in Stocks",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 31,
      name: "Hendrix Graham",
      education: "Baruch College",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419214298download.jpg?alt=media&token=c029f136-b5ee-44bc-bcc9-3d72c282018d",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Upper West Side",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
  },
  {
    description:
      "Brand Avatars. I want to promote my brand. Do you know anything about brand Avatars?",
    duration_time: 60,
    id: "6",
    short_description: "Brand Avatars",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 30,
      name: "Serena Hayes",
      education: "The Julliard School",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F159341925921236%20(1).jpg?alt=media&token=abfe1205-9dc5-48bb-a212-b6587b4c3f9b",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: null,
    type: MeetingTypes.ZOOM_ONE_BY_ONE,
  },
  {
    description: "Traveling at This Time. Would you?",
    duration_time: 60,
    id: "7",
    short_description: "Traveling at This Time",
    time_from: 1593442800,
    time_to: 1593450000,

    user: {
      age: 30,
      name: "Serena Hayes",
      education: "The Julliard School",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F159341925921236%20(1).jpg?alt=media&token=abfe1205-9dc5-48bb-a212-b6587b4c3f9b",
      authMethods: {
        google: false,
        facebook: false,
        apple: true,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Hunter College",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
  },
  {
    description: "Little Italy or Soho?. Where would you prefer to live?",
    duration_time: 60,
    id: "8",
    short_description: "Little Italy or Soho?",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 25,
      name: "Miriam Jenkins",
      education: "The City College of New York",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419309450cute-hair-styles-25-new-afro-hairstyles-2017-short-hairstyles-2017-2018-most.jpg?alt=media&token=f023d068-0540-45db-8ff4-06df27bf21a0",
      authMethods: {
        google: false,
        facebook: false,
        apple: true,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Acting Auditions. Any experience with SAG-AFTRA?",
    duration_time: 60,
    id: "9",
    short_description: "Acting Auditions",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 24,
      name: "Leighton Richards",
      education: "Columbia University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419436001ahn-hyoseop-profile-cover.jpg?alt=media&token=c53415db-c7dc-48e9-bf16-3e70548eb339",
      authMethods: {
        google: false,
        facebook: false,
        apple: true,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Lincoln Center",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
  },
  {
    description:
      "Finding gigs on Fivver. I'm a UX designer and cannot get enough gigs.  Any tips?",
    duration_time: 60,
    id: "10",
    short_description: "Finding gigs on Fivver",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 29,
      name: "Dominic Robinson",
      education: "New York University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419513223rob.jpg?alt=media&token=1424686f-f665-49ef-bd74-047725c426ef",
      authMethods: {
        google: false,
        facebook: false,
        apple: true,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Upper East Side",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
  },
  {
    description:
      "U.S. Open. Let’s discuss the best / worst outcomes of the U.S. Open.",
    duration_time: 60,
    id: "11",
    short_description: "U.S. Open",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Hunter College",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 28,
      name: "Tina Brown",
      education: "Baruch College",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593418017957images.jpg?alt=media&token=f51f0716-899f-463b-9d1a-24607ef05ce6",
      authMethods: {
        google: false,
        facebook: false,
        apple: true,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Product Management",
    duration_time: 60,
    id: "12",
    short_description: "Product Management",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Hunter College",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 33,
      name: "Lily Bennett",
      education: "Columbia University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593418144696Promobl6618.jpg?alt=media&token=587f194f-5652-478c-aed3-3adf766b0809",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "The Goldfinch",
    duration_time: 60,
    id: "13",
    short_description: "The Goldfinch",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Midtown East",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 29,
      name: "Joe Newman",
      education: "New York University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F15934184100851253603.jpg?alt=media&token=777825f7-67a0-4f43-bfb3-e64dbe1e6476",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Jared Leto",
    duration_time: 60,
    id: "14",
    short_description: "Jared Leto",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 31,
      name: "Hendrix Graham",
      education: "Baruch College",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419214298download.jpg?alt=media&token=c029f136-b5ee-44bc-bcc9-3d72c282018d",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: null,
    type: MeetingTypes.ZOOM_ONE_BY_ONE,
  },
  {
    description: "The Marvel Cinematic Universe",
    duration_time: 60,
    id: "15",
    short_description: "The Marvel Cinematic Universe",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: null,
    type: MeetingTypes.ZOOM_ONE_BY_ONE,
    user: {
      age: 31,
      name: "Hendrix Graham",
      education: "Baruch College",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419214298download.jpg?alt=media&token=c029f136-b5ee-44bc-bcc9-3d72c282018d",
      authMethods: {
        google: false,
        facebook: true,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Once Upon a Time in Hollywood",
    duration_time: 60,
    id: "16",
    short_description: "Once Upon a Time in Hollywood",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 30,
      name: "Serena Hayes",
      education: "The Julliard School",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F159341925921236%20(1).jpg?alt=media&token=abfe1205-9dc5-48bb-a212-b6587b4c3f9b",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Hamilton",
    duration_time: 60,
    id: "17",
    short_description: "Hamilton",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Midtown East",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 30,
      name: "Serena Hayes",
      education: "The Julliard School",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F159341925921236%20(1).jpg?alt=media&token=abfe1205-9dc5-48bb-a212-b6587b4c3f9b",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Baking",
    duration_time: 60,
    id: "18",
    short_description: "Baking",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],

    user: {
      age: 25,
      name: "Miriam Jenkins",
      education: "The City College of New York",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419309450cute-hair-styles-25-new-afro-hairstyles-2017-short-hairstyles-2017-2018-most.jpg?alt=media&token=f023d068-0540-45db-8ff4-06df27bf21a0",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "The best podcasts of 2023",
    duration_time: 60,
    id: "19",
    short_description: "The best podcasts of 2023",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Times Square",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
    user: {
      age: 24,
      name: "Leighton Richards",
      education: "Columbia University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419436001ahn-hyoseop-profile-cover.jpg?alt=media&token=c53415db-c7dc-48e9-bf16-3e70548eb339",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "Los Angeles Rams",
    duration_time: 60,
    id: "20",
    short_description: "Los Angeles Rams",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Midtown East",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],

    user: {
      age: 29,
      name: "Dominic Robinson",
      education: "New York University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419513223rob.jpg?alt=media&token=1424686f-f665-49ef-bd74-047725c426ef",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
  },
  {
    description: "New York Jets",
    duration_time: 60,
    id: "21",
    short_description: "New York Jets",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 29,
      name: "Dominic Robinson",
      education: "New York University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419513223rob.jpg?alt=media&token=1424686f-f665-49ef-bd74-047725c426ef",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: [
      {
        id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        lat: 51.52773579999999,
        lng: -0.07638289999999999,
        name: "Di Stefano Coffee",
        place_id: "ChIJ9V5Tm0IddkgRXnx8EX8HK_I",
        vicinity: "Near Times Square",
      },
      {
        id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        lat: 51.5469444,
        lng: -0.09833330000000001,
        name: "New London Cafe",
        place_id: "ChIJV5tZ2HwbdkgRAUmY9q0mfZA",
        vicinity: "Near Central Park",
      },
      {
        id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        lat: 51.5157108,
        lng: -0.1238709,
        name: "Cafe de Provence",
        place_id: "ChIJw_NYWjMbdkgRNLkYHpWvpuU",
        vicinity: "Upper East Side",
      },
    ],
  },
  {
    description: "A fundraiser for One Family Fund. Join Leighton Richards’ talk about how we can help victims of terror.",
    duration_time: 60,
    id: "22",
    short_description: "",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [],
    user: {
      age: 24,
      name: "Leighton Richards",
      education: "Columbia University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419436001ahn-hyoseop-profile-cover.jpg?alt=media&token=c53415db-c7dc-48e9-bf16-3e70548eb339",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
    payment_type: PaymentTypes.FUNDRAISER,
    payment_amount: 15,
    type: MeetingTypes.ZOOM_GROUP,
  },
  {
    description: "A fundraiser for Assuta Hospital. Join Dominic Robinson’s talk about how we can support Assuta Hospital.",
    duration_time: 60,
    id: "23",
    short_description: "Los Angeles Rams",
    time_from: 1593442800,
    time_to: 1593450000,
    coffees: [],
    user: {
      age: 29,
      name: "Dominic Robinson",
      education: "New York University",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593419513223rob.jpg?alt=media&token=1424686f-f665-49ef-bd74-047725c426ef",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
    payment_type: PaymentTypes.FUNDRAISER,
    payment_amount: 10,
    type: MeetingTypes.ZOOM_GROUP,
  },
  {
    description: "A fundraiser for United Hatzalah. Join Tina Brown’s talk about how we can help United Hatzalah.",
    duration_time: 60,
    id: "24",
    short_description: "New York Jets",
    time_from: 1593442800,
    time_to: 1593450000,
    user: {
      age: 28,
      education: "Baruch College",
      name: "Tina Brown",
      photoURL:
        "https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2F1593418017957images.jpg?alt=media&token=f51f0716-899f-463b-9d1a-24607ef05ce6",
      authMethods: {
        google: true,
        facebook: false,
        apple: false,
      },
    },
    status: null,
    fake: true,
    coffees: [],
    payment_type: PaymentTypes.FUNDRAISER,
    payment_amount: 15,
    type: MeetingTypes.ZOOM_GROUP,
  },
];
