import * as constants from '../constants';

export function setUserInfo(payload) {
  return {
    type: constants.SET_USER_INFO,
    payload,
  };
}

export function setEmptyUserInfo() {
  return {
    type: constants.SET_EMPTY_USER_INFO,
  };
}
