import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as _ from "lodash";
import { ZoomService } from "../../services/Zoom";
import { HistoryHelper } from "../../helpers/History";

import moment from "moment";
import { FormatHelper } from "../../helpers";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";
import { FutureMeetingsFirebaseService } from "../../services/FutureMeetings";
import { RelationControlInvitationsService } from "../../services/RelationControl";
import { EmailService } from "../../services/Email";
import { FollowersService } from "../../services/Followers";

import Spinner from "../spinner";

import "./Zoom.scss";
import { useLocalStorage } from "../../shared/LocalStorageHook";
import { MeetingTypes } from "../../shared/MeetingTypes";

const followersService = new FollowersService();

const Zoom = (props) => {
  const { user, setPopUpNotificationText, setClickedIcon, setTitle } = props;
  const futureMeetingsFirebaseService = new FutureMeetingsFirebaseService();
  const location = useLocation();

  setTitle("Start a Talk");

  const [localStorageCoffee, setLocalStorageCoffee] = useLocalStorage(
    "coffee",
    null
  );

  const createMeeting = async (code, startTime, durationTime, topic) => {
    const meetingStartTime = moment(startTime.seconds * 1000)
      .utc()
      .format();

    const meeting = await ZoomService.createMeeting(
      code,
      meetingStartTime,
      durationTime,
      topic
    );

    if (meeting !== null) {
      return meeting.join_url;
    }

    return null;
  };

  useEffect(() => {
    async function getTokens() {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");

      if (localStorageCoffee !== null) {
        if (localStorageCoffee.type === MeetingTypes.ZOOM_GROUP) {
          const timeTo = moment(localStorageCoffee.time_from * 1000)
            .add(localStorageCoffee.duration_time, "minutes")
            .unix();

          const isWebinarAlreadyExist = await RelationControlInvitationsService.getMyWebinars(
            localStorageCoffee.time_from,
            timeTo
          );

          if (isWebinarAlreadyExist) {
            setPopUpNotificationText({
              text: "You have already created webinar at this time",
            });
            return;
          }
        }

        const zoomLink = await createMeeting(
          code,
          FormatHelper.getTimestamp(localStorageCoffee.time_from),
          localStorageCoffee.duration_time,
          localStorageCoffee.description
        );

        if (zoomLink === null) {
          setPopUpNotificationText({
            text: "Zoom access wasn't verify. Try again.",
          });
          setLocalStorageCoffee(null);
          setClickedIcon("home-icon");
          HistoryHelper.redirectTo("/new-talk");
          return;
        }

        const newCoffee = _.assign({}, localStorageCoffee, {
          zoom_link: zoomLink,
          time_from: FormatHelper.getTimestamp(localStorageCoffee.time_from),
          time_to: FormatHelper.getTimestamp(localStorageCoffee.time_to),
        });

        await RelationControlCoffeeService.addCoffee(newCoffee);

        if (localStorageCoffee.type === MeetingTypes.ZOOM_GROUP) {
          const futureMeeting = {
            meetingStartTime: localStorageCoffee.time_from,
            coffeeShop: "Zoom Webinar",
            cardUserId: null,
            cardAdminId: user.id,
          };

          await futureMeetingsFirebaseService.addFutureMeeting(futureMeeting);
        }
      }

      const subsEmails = await followersService.getMySubscribersEmails();

      await EmailService.sendNotificationEmail(
        subsEmails,
        `${user.name} added new coffee talk!`,
        `${user.name} added new coffee talk "${localStorageCoffee?.description}"!`
      );

      onComplete();
    }

    try {
      if (user?.id) {
        getTokens();
      }
    } catch (err) {
      setPopUpNotificationText({
        text: "Zoom access wasn't verify. Try again.",
      });
      setLocalStorageCoffee(null);
      setClickedIcon("home-icon");
      HistoryHelper.redirectTo("/new-talk");
    }
    // eslint-disable-next-line
  }, [user]);

  const onComplete = () => {
    setLocalStorageCoffee(null);
    setPopUpNotificationText({
      text: "Zoom meeting successfully created.",
    });
    setClickedIcon("home-icon");
    HistoryHelper.redirectTo("/");
  };

  return (
    <form>
      <Spinner loaderContainerName="loader-container" blockName="modal" />
    </form>
  );
};

export default Zoom;
