import * as constants from "../constants";

const initialState = {
  text: null,
};

const actionHandlers = {
  [constants.SET_POP_UP_NOTIFICATION_TEXT](state, popUpNotification) {
    return { ...state, ...popUpNotification };
  },
};

export default function popUpNotification(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
