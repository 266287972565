import 'firebase/messaging';
import { FireBase } from './services/Firebase';

const firebaseApp = FireBase.getInstance().getFirebaseApp();
let messaging = null;

if (FireBase.isMessagingSupported()) {
  messaging = firebaseApp.messaging();
  messaging.usePublicVapidKey(
    `${process.env.REACT_APP_NOTIFICATIONS_PUBLIC_KEY}`
  );
}
export { messaging };
