import React from 'react';
import { withRouter } from 'react-router-dom';

import MobileHeader from './MobileHeader';

import useWindowDimensions from '../../shared/windowResize';
import { MOBILE_WIDTH } from '../../shared/constants';

const Header = props => {
  const { screenWidth } = useWindowDimensions();

  return screenWidth < MOBILE_WIDTH ? (
    <MobileHeader {...props} />
  ) : (
    <React.Fragment />
  );
};

export default withRouter(Header);
