import React, { useState, useEffect, useRef } from "react";
import { Router, Switch, Route, NavLink, Redirect } from "react-router-dom";
import * as _ from "lodash";
import { messaging } from "./init-fcm";
import { compose, lifecycle, withHandlers, withState } from "recompose";

import {FormatHelper, HistoryHelper} from "./helpers";
import history from "./history";
import "./App.scss";

import Footer from "./components/footer";
import UserPage from "./containers/UserPage";
import { connect } from "react-redux";
import InvitationPage from "./components/invitation-page";

import SignIn from "./containers/SignIn";
import UserSmallAvatar from "./components/user-small-avatar";
import * as actions from "./redux/actions";

import Home from "./containers/Home";
import Time from "./containers/TimePage";
import CoffeeForm from "./containers/Coffee";
import { MapHelper } from "./helpers/Map";
import { NavLinkHelper } from "./helpers/NavLink";
import { MOBILE_WIDTH } from "./shared/constants";

import Header from "./containers/Header";
import { useLocalStorage } from "./shared/LocalStorageHook";

import PopUpNotification from "./containers/PopUpNotification";

import { RelationControlInvitationsService } from "./services/RelationControl";
import InvitationDetails from "./containers/InvitationDetails";
import RateMeeting from "./components/rate-meeting";
import DesktopNavBar from "./components/desktop-nav-bar";
import GuestPage from "./containers/GuestPage";
import Topics from "./containers/Topics";
import { UserFirebaseService } from "./services/UserDB";
import { NotificationsService } from "./services/Notifications";

import HomeIcon from "./shared/icons/home-icon.svg";
import CoffeeIcon from "./shared/icons/coffee-icon.svg";
import NotificationsIcon from "./shared/icons/notifications-icon.svg";
import HomeClickedIcon from "./shared/icons/home-clicked-icon.svg";
import CoffeeClickedIcon from "./shared/icons/coffee-clicked-icon.svg";
import NotificationsClickedIcon from "./shared/icons/notifications-clicked-icon.svg";
import useWindowDimensions from "./shared/windowResize";
import { NavigatorService } from "./services/NavigatorService";
import Zoom from "./containers/Zoom";
import Support from "./pages/support";
import PrivacyPolicy from "./pages/privacy-policy";
import TermOfUse from "./pages/term-of-use";
import PaymentConfirm from "./pages/confirm";
import moment from "moment";
import StripeReturn from "./pages/return";
import StripeReauth from "./pages/reauth";
import StripeModal from "./components/stripe-modal";
import {FireBase} from "./services";

const userFirebaseService = new UserFirebaseService();
const firebaseApp = FireBase.getInstance().getFirebaseApp();

export function mapStateToProps({
  userState,
  mapState,
  appPagesState,
  popUpNotificationState,
}) {
  return {
    user: userState,
    currentLocation: mapState.currentLocation,
    availabilityPageStatus: appPagesState.availabilityPageStatus,
    stripeModalStatus: appPagesState.stripeModalStatus,
    signInPageStatus: appPagesState.signInPageStatus,
    signInPageText: appPagesState.signInPageText,
    splashStatus: appPagesState.splashStatus,
    popUpNotificationText: popUpNotificationState.text,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setCurrentLocation: (payload) =>
      dispatch(actions.setCurrentLocation(payload)),
    setUserInfo: (payload) => dispatch(actions.setUserInfo(payload)),
    setSignInPageStatus: (payload) =>
      dispatch(actions.setSignInPageStatus(payload)),
    setSplashStatus: (payload) => dispatch(actions.setSplashStatus(payload)),
    setSignInPageText: (payload) =>
      dispatch(actions.setSignInPageText(payload)),
    setPopUpNotificationText: (payload) =>
      dispatch(actions.setPopUpNotificationText(payload)),
    setAppPagesStatus: (payload) =>
      dispatch(actions.setAppPagesStatus(payload)),
    setStripeModalStatus: (payload) =>
      dispatch(actions.setStripeModalStatus(payload)),
  };
}

const registerPushListener = (pushNotification) => {
  navigator.serviceWorker.addEventListener("message", ({ data }) => {
    const message = data.data
      ? _.get(data, "data.message")
      : _.get(data, "firebase-messaging-msg-data.data.message");
    const redirectUrl = data.data
      ? _.get(data, "data.redirectUrl")
      : _.get(data, "firebase-messaging-msg-data.data.redirectUrl");
    const isReminder = data.data
      ? _.get(data, "data.reminder", false)
      : _.get(data, "firebase-messaging-msg-data.data.reminder", false);
    const remindMeetingStartTime = data.data
      ? _.get(data, "data.meetingStartTime", null)
      : _.get(data, "firebase-messaging-msg-data.data.meetingStartTime", null);
    const interlocutorName = data.data
      ? _.get(data, "data.interlocutorName", "")
      : _.get(data, "firebase-messaging-msg-data.data.interlocutorName", "");
    const coffeeShop = data.data
      ? _.get(data, "data.coffeeShop", "")
      : _.get(data, "firebase-messaging-msg-data.data.coffeeShop", "");

    pushNotification({
      message,
      redirectUrl,
      isReminder: Boolean(isReminder),
      remindMeetingStartTime,
      interlocutorName,
      coffeeShop,
    });
  });
};

const App = (props) => {
  const {
    user,
    availabilityPageStatus,
    stripeModalStatus,
    popUpNotificationText,
    currentLocation,
    signInPageText,
    signInPageStatus,
    splashStatus,
    setUserInfo,
    setCurrentLocation,
    setSplashStatus,
    setStripeModalStatus,
    token,
  } = props;
  const [currentHeaderTitle, setCurrentHeaderTitle] = useState(null);
  const [clickedIcon, setClickedIcon] = useState(
    NavLinkHelper.getCurrentNavIcon(history.location.pathname)
  );

  const [invitations, setInvitations] = useState([]);
  const [userFromStorage, setUserFromStorage] = useLocalStorage("user", null);
  const { screenWidth } = useWindowDimensions();
  const isMobileDevice = screenWidth < MOBILE_WIDTH;
  // eslint-disable-next-line
  const [localStorageCoffee, setLocalStorageCoffee] = useLocalStorage(
    "coffee",
    null
  );

  const mainRef = useRef();

  useEffect(() => {
    if (userFromStorage?.id) {
      return;
    }

    const unregisterAuthObserver = firebaseApp
        .auth()
        .onAuthStateChanged(async (user) => {
          let userFromDB = null;
          if (!_.isEmpty(user)) {
            userFromDB = await userFirebaseService.addUser(
                FormatHelper.getUserInfoFromResponse(user)
            );
          }

          const userId = _.get(userFromDB, "id");
          setUserInfo(FormatHelper.getUserInfoForRedux(user, userFromDB, userId));
          setUserFromStorage(FormatHelper.getUserInfoForRedux(user, userFromDB, userId));
        });
    return unregisterAuthObserver;
  }, [userFromStorage]);

  useEffect(() => {
    async function getLocation() {
      const location = await MapHelper.getCurrentLocation();
      setCurrentLocation(location);
    }

    getLocation();
    // eslint-disable-next-line
  }, []);

  const setTitle = (title) => {
    setCurrentHeaderTitle(title);
  };

  NavigatorService.setFontStyle(screenWidth);

  useEffect(() => {
    if (_.get(userFromStorage, "id")) {
      setUserInfo(userFromStorage);
    }
  }, [userFromStorage, setUserInfo]);

  useEffect(() => {
    async function updateUserData() {
      if (_.isEmpty(token)) {
        return;
      }

      const updatedUser = await userFirebaseService.updateUserData(user, token);
      setUserFromStorage(updatedUser);
    }

    if (_.get(user, "id")) {
      updateUserData();
    }
    // eslint-disable-next-line
  }, [token]);

  const getMainRef = () => {
    return mainRef;
  };

  const openSignInPage = (e) => {
    if (!_.isEmpty(user.id)) {
      return;
    }
    e.preventDefault();
    setClickedIcon(NavLinkHelper.getCurrentNavIcon(history.location.pathname));
    props.setSignInPageStatus(true);
  };

  const onNavBarClick = (iconName) => {
    setClickedIcon(iconName);
    setLocalStorageCoffee(null);
  };

  const navLinkIcon = (iconName, navName, navIcon, navClickedIcon) => {
    return (
      <div className="footer-icon" onClick={() => onNavBarClick(iconName)}>
        {invitations.length !== 0 && navName === "Notifications" && (
          <div className="circle"></div>
        )}
        {clickedIcon === iconName ? navClickedIcon : navIcon}
        <p>{navName}</p>
      </div>
    );
  };

  const onMenuItemClick = (iconName) => {
    setClickedIcon(iconName);
    if (iconName === "home-icon") {
      HistoryHelper.redirectTo("/");
    }
  };

  const getBottomMenuLinks = () => {
    return (
      <React.Fragment>
        <div onClick={() => onMenuItemClick("home-icon")}>
          {navLinkIcon(
            "home-icon",
            "Home",
            <img src={HomeIcon} alt="nav-bar-home"/>,
            <img src={HomeClickedIcon} alt="nav-bar-home"/>
          )}
        </div>

        <NavLink to="/new-talk">
          {navLinkIcon(
            "coffee-icon",
            "Start a Talk",
            <img src={CoffeeIcon} alt="nav-bar-coffee"/>,
            <img src={CoffeeClickedIcon} alt="nav-bar-coffee"/>
          )}
        </NavLink>

        <NavLink onClick={openSignInPage} to="/notifications">
          {navLinkIcon(
            "notifications-icon",
            "Notifications",
            <img src={NotificationsIcon} alt="nav-bar-notifications"/>,
            <img src={NotificationsClickedIcon} alt="nav-bar-notifications"/>
          )}
        </NavLink>
        <NavLink onClick={openSignInPage} to="/user">
          <div onClick={() => setClickedIcon(null)}>
            <UserSmallAvatar
              avatarClassName={`footer-user-icon ${!clickedIcon ? 'active' : ""}`}
              userAvatarLink={_.get(props, "user.photoURL")}
            />
          </div>
        </NavLink>
      </React.Fragment>
    );
  };

  useEffect(() => {
    async function getInvitations() {
      const invitaions = await RelationControlInvitationsService.getInvitations();
      setInvitations(invitaions);
    }

    if (user.id) {
      getInvitations();
    }
  }, [user]);

  useEffect(() => {
    if (_.isEmpty(token)) {
      return;
    }

    if (user.id) {
      NotificationsService.sendNotificationForRate();
    }
    // eslint-disable-next-line
  }, [token]);

  if (_.isEmpty(user.name) && !isMobileDevice) {
    return (
      <div className="app-screen">
        {!_.isEmpty(popUpNotificationText) && (
          <PopUpNotification text={popUpNotificationText}/>
        )}
        {( _.isEmpty(user) || props.signInPageStatus ) && (
          <SignIn
            setTitle={setTitle}
            signInPageText={signInPageText}
            setSignInPageText={props.setSignInPageText}
          />
        )}

        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <div className="main-guest">
                  <GuestPage
                    setUser={setUserFromStorage}
                    signInPageStatus={signInPageStatus}
                    setSignInPageStatus={props.setSignInPageStatus}
                    setSignInPageText={props.setSignInPageText}
                  />
                </div>
              )}
            />
            <Route exact path="/support" render={() => <Support/>}/>
            <Route
              exact
              path="/privacy-policy"
              render={() => <PrivacyPolicy/>}
            />
            <Route exact path="/term-of-use" render={() => <TermOfUse/>}/>
          </Switch>
        </Router>
      </div>
    );
  }

  return (
    <div className="app-screen">
      {!_.isEmpty(popUpNotificationText) && (
        <PopUpNotification text={popUpNotificationText}/>
      )}
      {( _.isEmpty(user) || props.signInPageStatus ) && (
        <SignIn
          setTitle={setTitle}
          signInPageText={signInPageText}
          setSignInPageText={props.setSignInPageText}
        />
      )}
      {/*{!splashStatus && isMobileDevice && _.isEmpty(user.id) && (*/}
      {/*  <Splash setSplashStatus={setSplashStatus} />*/}
      {/*)}*/}
      {stripeModalStatus !== "" && (
        <StripeModal
          setStripeModalStatus={setStripeModalStatus}
          state={stripeModalStatus}
        />
      )}
      {!_.isEmpty(user) && (
        <React.Fragment>
          <Router history={history}>
            <React.Fragment>
              <Header
                title={currentHeaderTitle}
                setClickedIcon={setClickedIcon}
                clickedIcon={clickedIcon}
                setSignInPageStatus={props.setSignInPageStatus}
              />
              {availabilityPageStatus && <Time/>}
              <main ref={mainRef}>
                <div className="content-container">
                  {!isMobileDevice && (
                    <DesktopNavBar
                      openSignInPage={openSignInPage}
                      clickedIcon={clickedIcon}
                      setClickedIcon={setClickedIcon}
                      userAvatarLink={_.get(props, "user.photoURL")}
                      setAppPagesStatus={props.setAppPagesStatus}
                      invitations={invitations}
                    />
                  )}
                  <Switch>
                    <Route exact path="/">
                      <Home
                        setTitle={setTitle}
                        signInPageStatus={signInPageStatus}
                      />
                    </Route>
                    <Route exact path="/new-talk">
                      <CoffeeForm
                        setTitle={setTitle}
                        setPopUpNotificationText={
                          props.setPopUpNotificationText
                        }
                        setClickedIcon={setClickedIcon}
                        signInPageStatus={signInPageStatus}
                        setUserInfo={props.setUserInfo}
                      />
                    </Route>

                    <Route exact path="/zoom">
                      <Zoom
                        setTitle={setTitle}
                        setPopUpNotificationText={
                          props.setPopUpNotificationText
                        }
                        setClickedIcon={setClickedIcon}
                        signInPageStatus={signInPageStatus}
                        userFromStorage={userFromStorage}
                        setUserFromStorage={setUserFromStorage}
                      />
                    </Route>

                    <Route
                      exact
                      path="/user"
                      render={() => {
                        return user.id ? (
                          <UserPage
                            getMainRef={getMainRef()}
                            setTitle={setTitle}
                            setUserInfo={props.setUserInfo}
                            screenWidth={screenWidth}
                            setClickedIcon={setClickedIcon}
                            setSplashStatus={setSplashStatus}
                          />
                        ) : null;
                      }}
                    />
                    <Route
                      exact
                      path="/notifications"
                      render={() => {
                        return user.id ? (
                          <InvitationPage
                            user={user}
                            setTitle={setTitle}
                            currentLocation={currentLocation}
                          />
                        ) : null;
                      }}
                    />
                    <Route
                      exact
                      path="/notification/:id"
                      render={() => {
                        return user.id ? (
                          <InvitationDetails
                            setTitle={setTitle}
                            setClickedIcon={setClickedIcon}
                            setInvitations={setInvitations}
                            invitations={invitations}
                          />
                        ) : null;
                      }}
                    />
                    <Route
                      exact
                      path="/meeting/rate/:id"
                      render={() => {
                        return user.id ? (
                          <RateMeeting
                            user={user}
                            setTitle={setTitle}
                            setPopUpNotificationText={
                              props.setPopUpNotificationText
                            }
                            setClickedIcon={setClickedIcon}
                          />
                        ) : null;
                      }}
                    />
                    <Route exact path="/support" render={() => <Support/>}/>
                    <Route
                      exact
                      path="/privacy-policy"
                      render={() => <PrivacyPolicy/>}
                    />
                    <Route
                      exact
                      path="/term-of-use"
                      render={() => <TermOfUse/>}
                    />
                    <Route
                      exact
                      path="/return"
                      render={() => (
                        <StripeReturn
                          user={user}
                          setPopUpNotificationText={
                            props.setPopUpNotificationText
                          }
                          setStripeModalStatus={setStripeModalStatus}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/reauth"
                      render={() => (
                        <StripeReauth
                          user={user}
                          setPopUpNotificationText={
                            props.setPopUpNotificationText
                          }
                          setStripeModalStatus={setStripeModalStatus}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/confirm"
                      render={() => <PaymentConfirm user={user}/>}
                    />
                    <Route render={() => <Redirect to="/"/>}/>
                  </Switch>
                  {!isMobileDevice && <Topics/>}
                </div>
              </main>

              {isMobileDevice && <Footer>{getBottomMenuLinks()}</Footer>}
            </React.Fragment>
          </Router>
        </React.Fragment>
      )}
    </div>
  );
};

export default compose(
  withState("token", "setToken", ""),
  withState("notifications", "setNotifications", []),
  withHandlers({
    pushNotification: ({ setNotifications, notifications }) => (
      newNotification
    ) => {
      setNotifications(notifications.concat(newNotification));
      if ("Notification" in window && document.visibilityState === "visible") {
        if (newNotification.isReminder) {
          const time = moment
            .unix(newNotification.remindMeetingStartTime)
            .format("h:mm A");

          const message =
            newNotification.coffeeShop === "Zoom Webinar"
              ? `CoffeeTalk Zoom Webinar at ${time}.`
              : `Reminder! CoffeeTalk meeting with ${newNotification.interlocutorName} at ${time} in ${newNotification.coffeeShop}.`;

          NotificationsService.showNotification(
            message,
            newNotification.redirectUrl
          );
        } else {
          NotificationsService.showNotification(
            newNotification.message,
            newNotification.redirectUrl
          );
        }
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { pushNotification, setToken } = this.props;

      if (!messaging) return;
      messaging
        .requestPermission()
        .then(async function () {
          const token = await messaging.getToken();
          setToken(token);
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });

      registerPushListener(pushNotification);
    },
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
