import firebase from 'firebase/app';
import 'firebase/firestore';

import * as _ from 'lodash';

export class FirestoreDatabaseService {
  constructor(table_name) {
    this.TABLE_NAME = table_name;
  }

  getTableRef(path = '') {
    return firebase.firestore().collection(`${this.TABLE_NAME}/${path}`);
  }

  async add(item) {
    const data = await this.getTableRef().add(item);

    return data.id;
  }

  async update(id, item) {
    const data = await this.getTableRef()
      .doc(id)
      .update(item);
    return data;
  }

  async delete(id) {
    const data = await this.getTableRef()
      .doc(id)
      .delete();

    return data;
  }

  async getAll() {
    const data = await this.getTableRef().get();
    const newData = data.docs.map(function(doc) {
      return _.assign(doc.data(), { id: doc.id });
    });
    return newData;
  }

  async getById(id) {
    const data = await this.getTableRef()
      .doc(id)
      .get();

    if (_.isEmpty(data.data())) {
      return {};
    }

    return _.assign(data.data(), { id: data.id });
  }

  async isExist(field, condition, value) {
    const data = await this.getTableRef()
      .where(field, condition, value)
      .get();

    if (data.empty) {
      return null;
    }

    return data.docs[0].id;
  }

  async filterByField(conditions) {
    let req = this.getTableRef();
    conditions.forEach(element => {
      req = req.where(element.field, element.condition, element.value);
    });

    const data = await req.get();
    if (data.empty) {
      return null;
    }

    return data.docs.map(function(doc) {
      return _.assign(doc.data(), { id: doc.id });
    });
  }
}
