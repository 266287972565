import React, { useEffect } from "react";

import { HistoryHelper } from "../../helpers";
import { StripeService } from "../../services/Stripe";
import "./Reauth.scss";
import Spinner from "../../components/spinner";

function StripeReauth({
  user,
  setPopUpNotificationText,
  setStripeModalStatus,
}) {
  useEffect(() => {
    if (!user.id) {
      return;
    }

    if (!user.stripeId) {
      HistoryHelper.redirectTo("/");
    }

    StripeService.getAccountData(user.stripeId)
      .then((res) => {
        if (res.charges_enabled) {
          setStripeModalStatus("done");
        } else {
          setStripeModalStatus("pending");
        }
      })
      .catch((err) => {
        const msg = err?.message || "Something went wrong. Try again later.";
        setPopUpNotificationText({
          text: msg,
        });
      })
      .finally(() => {
        HistoryHelper.redirectTo("/");
      });
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className="reauth">
      <Spinner loaderContainerName="loader-container" blockName="modal" />
    </div>
  );
}

export default StripeReauth;
