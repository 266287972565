import * as _ from "lodash";
import moment from "moment";
import { FirestoreDatabaseService } from "../shared/FirestoreDatabase";
import { RelationControlInvitationsService } from "./RelationControl";

import { store } from "./../redux/store/index";
import { FormatHelper } from "../helpers";

const TABLE_NAME = "/stripe-payments";

export class StripePaymentFirebaseService extends FirestoreDatabaseService {
  constructor() {
    super(TABLE_NAME);
  }

  async addPayment(coffeeId, clientSecret) {
    const state = store.getState();
    const userId = _.get(state, "userState.id");

    const payments = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "coffeeId",
        condition: "==",
        value: coffeeId,
      },
    ]);

    if (payments !== null) {
      return;
    }

    const data = {
      userId: userId,
      coffeeId: coffeeId,
      payload: null,
      paid: false,
      clientSecret: clientSecret,
      date: FormatHelper.getTimestamp(moment().unix()),
    };
    await this.add(data);
  }

  async confirmPayment(urlClientSecret, paymentIntent) {
    const state = store.getState();
    const userId = _.get(state, "userState.id");

    const payment = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "clientSecret",
        condition: "==",
        value: urlClientSecret,
      },
      {
        field: "paid",
        condition: "==",
        value: false,
      },
      {
        field: "coffeeId",
        condition: "==",
        value: paymentIntent.description,
      },
    ]);

    if (_.isEmpty(payment)) {
      return;
    }

    const updatedPayment = {
      ...payment[0],
      paid: true,
      payload: paymentIntent,
    };

    await this.update(payment[0].id, updatedPayment);

    await RelationControlInvitationsService.joinWebinarAfterPayment(
      payment[0].coffeeId
    );
  }
}
