import React, { useState, useEffect, useRef } from "react";
import { MdPlace } from "react-icons/md";
import { FaClock } from "react-icons/fa";

import UserSmallAvatar from "../user-small-avatar";
import UserAvatarEdit from "../user-avatar-edit";
import ZoomIcon from "../../shared/icons/zoom-icon.svg";
import VoiceIcon from "../../shared/icons/voice-icon.svg";
import "./DetailedCoffeeCard.scss";
import * as _ from "lodash";
import moment from "moment";
import { TimePickerService } from "../../services/TimePicker";
import { RelationControlInvitationsService } from "../../services/RelationControl";
import { FollowersService } from "../../services/Followers";
import { MeetingTypes } from "../../shared/MeetingTypes";

const followersService = new FollowersService();

const DetailedCoffeeCard = ({
  coffee,
  user,
  isAlmostOverTime = false,
  status = null,
  details = null,
  deleteInvitation = () => {},
  setIsLoading = () => {},
  setSignInPageStatus = () => {},
  filterCoffeesAfterCancel = () => {},
  userState,
  interlocutorData,
  invitation,
  defaultSelectedCoffeeId,
}) => {
  const [isCoffeeCardOpened, setIsCoffeeCardOpened] = useState(defaultSelectedCoffeeId === coffee.id);
  const [isUserAvatarOpened, setUserAvatarOpened] = useState(false);
  const [isFollowing, setFollowing] = useState(null);
  const coffeeCard = "coffee-card";
  const openedCoffeeCard = "opened-coffee-card";
  const cardUserId =
    (coffee.user && _.get(coffee, "user.id", null)) || _.get(user, "id", null);
  const isMyCard = userState.id === cardUserId;
  const elementRef = useRef();

  useEffect(() => {
    if(defaultSelectedCoffeeId === coffee.id){
      elementRef.current.scrollIntoView({behavior : "smooth", });
    }

    const checkFollow = async () => {
      const followingStatus = await followersService.checkIsFollowing(user?.id);
      setFollowing(followingStatus);
    };

    checkFollow();
    // eslint-disable-next-line
  }, []);

  const onClickCoffeeCard = () => {
    setIsCoffeeCardOpened(!isCoffeeCardOpened);
  };

  const onClickUserAvatar = () => {
    setUserAvatarOpened(true);
  };

  const userAvatar = _.get(user, "photoURL");
  const userName = _.get(user, "name");
  const vicinity = _.get(coffee, "coffees[0].vicinity", "Zoom");
  const coffeeType = _.get(coffee, "type", "");

  const cancelLiveMeeting = async () => {
    setIsLoading(true);
    await RelationControlInvitationsService.cancelLiveCoffee(coffee.id);
    filterCoffeesAfterCancel(coffee.id);
    setIsLoading(false);
  };

  const meetingVicinity = () => {
    if (vicinity === "Zoom" && coffeeType === MeetingTypes.ZOOM_GROUP) {
      return "Zoom Group Talk";
    }
    if (vicinity === "Zoom") {
      return `Zoom`;
    }
    return vicinity;
  };

  const getCardButton = () => {
    if (status) {
      return (
        <button
          className="coffee-card-button"
          onClick={(event) => {
            event.stopPropagation();
            deleteInvitation(_.get(details, "id"));
          }}
        >
          <span>I need an easy out of this talk</span>
        </button>
      );
    }

    return null;
  };

  const getCancelButton = () => {
    if (isCoffeeCardOpened && coffee?.live && isMyCard) {
      return (
        <button
          className="coffee-card-button"
          onClick={(event) => {
            event.stopPropagation();
            cancelLiveMeeting();
          }}
        >
          <span>Close live talk</span>
        </button>
      );
    }
    return null;
  };

  const follow = async () => {
    if (_.isEmpty(userState.id)) {
      setSignInPageStatus(true);
      return;
    }

    try {
      await followersService.follow(user);
    } catch (err) {}
    setFollowing(true);
  };

  const unfollow = async () => {
    try {
      await followersService.unfollow(user);
    } catch (err) {}
    setFollowing(false);
  };

  const getFollowButton = () => {
    if (isMyCard) {
      return null;
    }

    if (isFollowing)
      return (
        <button
          className="unfollow-button"
          onClick={(e) => {
            e.stopPropagation();
            unfollow();
          }}
        >
          Unfollow
        </button>
      );

    return (
      <button
        className="follow-button"
        onClick={(e) => {
          e.stopPropagation();
          follow();
        }}
      >
        Follow
      </button>
    );
  };

  const getSlicedDescriptionText = () => {
    const description = _.get(coffee, "description");

    if (description.length > 30) {
      const textForView = description.slice(0, 30) + "...";
      return textForView;
    }

    return description;
  };

  const getAvatarClassName = () => {
    const defaultClassName = "coffee-card-user-img";

    if (coffeeType === MeetingTypes.LIVE) {
      return `${defaultClassName} live`;
    }

    return defaultClassName;
  };

  const getVicinityIcon = () => {
    if (vicinity === "Zoom")
      return (
        <img src={ZoomIcon} className="coffee-card-icon" alt="zoom-icon" />
      );

    return <MdPlace className="coffee-card-icon" />;
  };

  const getTimeIcon = () => {
    if ([MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(coffeeType)) {
      return (
        <img src={VoiceIcon} className="coffee-card-icon" alt="voice-icon" />
      );
    }

    return <FaClock className="coffee-card-icon" />;
  };

  const getTime = () => {
    if (coffeeType === MeetingTypes.LIVE) {
      return <span className="live-time">Live with {user.name}</span>;
    }

    const timeFrom =
      !_.isNull(details) && coffeeType !== MeetingTypes.ZOOM_GROUP
        ? _.get(details, "meetingStartTime.seconds", false)
        : _.get(coffee, "time_from", false);
    const timeTo =
      !_.isNull(details) && coffeeType !== MeetingTypes.ZOOM_GROUP
        ? _.get(details, "meetingEndTime.seconds", false)
        : _.get(coffee, "time_to", false);

    return `${
      TimePickerService.isTodayDate(timeFrom || coffee.time_from)
        ? "Today"
        : `${moment(timeFrom * 1000).format("ll")}`
    } from ${moment
      .unix(timeFrom || coffee.time_from)
      .format("h:mm A")} to ${moment
      .unix(timeTo || coffee.time_to)
      .format("h:mm A")}`;
  };

  const getOfferToPayText = () => {
    if (details.offerToPay) {
      return _.get(invitation, "user.name") === _.get(interlocutorData, "name")
        ? `${_.get(invitation, "user.name")} is offering to pay for coffee`
        : `You are buying ${_.get(invitation, "admin.name")} a coffee`;
    }

    return "No";
  };

  const timeFrom =
    _.get(invitation, "details.meetingStartTime.seconds", undefined) ||
    _.get(invitation, "coffee.time_from");
  const zoomLink = _.get(invitation, "coffee.zoom_link");

  return (
    <div
      id={coffee.id}
      onClick={onClickCoffeeCard}
      className={isCoffeeCardOpened ? openedCoffeeCard : coffeeCard}
      ref={elementRef}
    >
      {isUserAvatarOpened && (
        <UserAvatarEdit
          setUserAvatarOpened={setUserAvatarOpened}
          userAvatarLink={userAvatar}
          user={user}
          isUserAvatar={false}
        />
      )}
      <div className="coffee-card-user-avatar">
        <div
          onClick={(event) => {
            event.stopPropagation();
            onClickUserAvatar();
          }}
        >
          <UserSmallAvatar
            avatarClassName={getAvatarClassName()}
            userAvatarLink={userAvatar}
          />
        </div>
        {isCoffeeCardOpened && (
          <div className="coffee-card-user-name">
            <div>{userName}</div>
            {getFollowButton()}
          </div>
        )}
      </div>
      <div className="coffee-card-data">
        <div className="data-container">
          {isCoffeeCardOpened ? (
            <p className="coffee-card-title">{_.get(coffee, "description")}</p>
          ) : (
            <p className="coffee-card-title">{getSlicedDescriptionText()}</p>
          )}
          {/* <p className="coffee-card-conversation-time">
            {_.get(coffee, "duration_time")} min conversation
          </p> */}
        </div>
        <div className="data-container time">
          <p
            className={`coffee-card-meet-info ${
              isAlmostOverTime ? "almost-over-time" : ""
            }`}
          >
            {getTimeIcon()}
            {getTime()}
          </p>
          <p className="coffee-card-meet-info">
            {getVicinityIcon()}
            {meetingVicinity()}
          </p>
        </div>

        {isCoffeeCardOpened && (
          <React.Fragment>
            <div className="custom-hr"></div>
            <div className="grab-coffee-meeting-details-titles">
              {coffeeType !== MeetingTypes.ZOOM_GROUP && interlocutorData.name && (
                <div className="coffee-details-text">
                  <span className="title">Meeting with</span>
                  <span className="bold-details-text">
                    {interlocutorData.name}
                  </span>
                </div>
              )}

              <div className="coffee-details-text">
                <span className="title">Time & Date</span>
                <div className="grab-coffee-meeting-details-time">
                  <div className="text-button-container">
                    <span className="bold-details-text">
                      {TimePickerService.isTodayDate(timeFrom)
                        ? "Today at"
                        : `${moment(timeFrom * 1000).format("ll")}`}{" "}
                      {TimePickerService.getTimeFromMS(timeFrom * 1000)}
                    </span>
                  </div>

                  {/* <div className="details-text">
                    <span className="details-text">
                      for a {coffee.duration_time} minute conversation
                    </span>
                  </div> */}
                </div>
              </div>

              {!_.isEmpty(details.selectedCafe) && (
                <>
                  <div className="coffee-details-text">
                    <span className="title">Coffee shop</span>
                    <div className="grab-coffee-meeting-details-coffee-shop">
                      <span className="bold-details-text">
                        {details.selectedCafe.name}
                      </span>
                      <span className="details-text">
                        {details.selectedCafe.vicinity}
                      </span>
                    </div>
                  </div>

                  <div className="coffee-details-text">
                    <span className="title">Offer to pay?</span>
                    <span className="offer-to-pay-text">
                      {getOfferToPayText()}
                    </span>
                  </div>
                </>
              )}

              {[
                MeetingTypes.LIVE,
                MeetingTypes.ZOOM_GROUP,
                MeetingTypes.ZOOM_ONE_BY_ONE,
              ].includes(coffee.type) && (
                <div className="coffee-details-text">
                  <span className="title">Zoom Link</span>
                  <a
                    href={zoomLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="zoom-link"
                  >
                    {zoomLink}
                  </a>
                </div>
              )}
            </div>
          </React.Fragment>
        )}

        {isCoffeeCardOpened && !isMyCard ? getCardButton() : getCancelButton()}
      </div>
    </div>
  );
};

export default DetailedCoffeeCard;
