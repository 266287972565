import React, { useState, useEffect, useRef } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import CoffeeCard from "../../containers/CoffeeCard";

import moment from "moment";
import { FireBase, UserFirebaseService } from "../../services";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";
import { TimePickerService } from "../../services/TimePicker";

import { getFakeCards } from "../../helpers/FakeCards";
import { FormatHelper } from "../../helpers";
import Spinner from "../spinner";
import * as _ from "lodash";

import CoffeeMeIcon from "../../shared/icons/coffee-me-icon.png";
import "./GuestPage.scss";
import { APP_EMAIL_DOMAIN } from "../../shared/constants";

const firebaseApp = FireBase.getInstance().getFirebaseApp();
const userService = new UserFirebaseService();

const SignInScreen = (props) => {
  const [isLoading, setIsLoading] = useState(!!props.user);
  const [filteredCoffees, setFilteredCoffees] = useState([]);
  const [coffees, setCoffees] = useState([]);
  const [searchString, setSearchString] = useState("");

  const searchInput = useRef(null);

  useEffect(() => {
    const getAllCoffees = async () => {
      setIsLoading(true);
      const availabilityFrom = moment(
        TimePickerService.getRoundedTime()
      ).unix();
      const availabilityTo = moment(TimePickerService.getRoundedTime())
        .add(3, "h")
        .unix();

      const allCoffees = await RelationControlCoffeeService.getAllCoffee(
        availabilityFrom,
        availabilityTo
      );
      setCoffees(allCoffees);

      if (_.isEmpty(props.fakeCards)) {
        const fakeCards = getFakeCards();
        setCoffees([...allCoffees, ...fakeCards]);
        props.setFakeCoffees({ fakeCards: fakeCards });
        setIsLoading(false);
        return;
      }

      setCoffees([...allCoffees, ...props.fakeCards]);
      setIsLoading(false);
    };
    getAllCoffees();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (_.isEmpty(props)) {
      return;
    }

    const unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(async (user) => {
        let userFromDB = null;
        if (!_.isEmpty(user)) {
          userFromDB = await userService.addUser(
            FormatHelper.getUserInfoFromResponse(user)
          );
        }

        if (_.isEmpty(user)) return;

        const userId = _.get(userFromDB, "id");
        props.setUser(
          FormatHelper.getUserInfoForRedux(user, userFromDB, userId)
        );
      });
    return () => {
      unregisterAuthObserver();
    };
  }, [props]);

  const searchCoffees = (event) => {
    const resultCoffees = _.filter(coffees, (coffee) => {
      return coffee.description
        .toLocaleLowerCase()
        .includes(event.target.value.toLocaleLowerCase());
    });
    setSearchString(event.target.value);
    setFilteredCoffees(resultCoffees);
  };

  const renderCardList = () => {
    let coffeesForRender = coffees;
    if (!_.isEmpty(filteredCoffees) || !_.isEmpty(searchString)) {
      coffeesForRender = filteredCoffees;
    }

    return coffeesForRender.map((coffee) => (
      <CoffeeCard
        key={coffee.id}
        coffee={coffee}
        user={coffee.user}
        status={null}
        grabToCoffee={grabToCoffee}
        setSignInPageStatus={props.setSignInPageStatus}
      />
    ));
  };

  const openModal = (text) => {
    props.setSignInPageStatus(true);
    props.setSignInPageText(text);
  };

  const grabToCoffee = (coffee) => {
    props.setSignInPageStatus(true);
  };

  return (
    <div className="guest-page-container">
      <div className="guest-page-content">
        <div className="app-title-container">
          <a className="app-title" href="/">
            CoffeeTalk
          </a>
        </div>

        <div className="home-page-container">
          {isLoading && (
            <Spinner
              loaderContainerName="loader-container"
              blockName="home-page-container"
            />
          )}

          <div className="guest-page-header">
            <div className="search-input-container">
              <AiOutlineSearch className="search-icon"/>
              <input
                className="search-input"
                placeholder="Find a Topic..."
                onChange={searchCoffees}
                ref={searchInput}
              />
            </div>
          </div>
          <div className="coffee-cards-container">{renderCardList()}</div>
        </div>

        <div className="desktop-auth-container">
          <div className="desktop-auth">
            <img
              className="coffee-me-logo"
              src={CoffeeMeIcon}
              alt="coffee-me"
            />
            <div className="text-container">
              <p className="main-text">
                Join live talks with influencers
              </p>
            </div>
            <button
              className="login-button"
              type="button"
              onClick={(event) => {
                openModal("Log In");
              }}
            >
              Log In
            </button>
            <p className="second-text">or</p>
            <button
              className="signup-button"
              type="button"
              onClick={(event) => {
                openModal("Sign Up");
              }}
            >
              Sign Up
            </button>

            <div className="bottom-links">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
              <div className="dot">•</div>
              <a href={`mailto:help@${APP_EMAIL_DOMAIN}`}>Contact us</a>
              <div className="dot">•</div>
              <a target="_blank" rel="noopener noreferrer" href="/term-of-use">
                Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInScreen;
