import React from 'react';
import './CoffeeList.scss';

const CoffeeList = ({ coffees, addCoffees, isAlreadySelected }) => {
  return coffees.map((coffee, index) => {
    return (
      <div key={index} className="coffee-to-select">
        <div className="coffee-text-container">
          <div className="coffee-name-container">
            <p className="coffee-index">{index + 1}.</p>
            <p className="coffee-name">{coffee.name}</p>
          </div>
          <p className="coffee-address">
            {coffee.vicinity || coffee.formatted_address}
          </p>
        </div>
        <div className="button-container" onClick={() => addCoffees(coffee)}>
          <button
            className={`coffee-select-button ${
              isAlreadySelected(coffee) ? 'active' : ''
            }`}
          >
            {isAlreadySelected(coffee) ? 'Selected' : 'Select'}
          </button>
        </div>
      </div>
    );
  });
};

export default CoffeeList;
