import React, { useState } from "react";
import "./Spinner.scss";

import useWindowDimensions from "../../shared/windowResize";

import { getBlockWidthByTagName } from "../../shared/blockWidth";
import { useLayoutEffect } from "react";

const Spinner = ({ loaderContainerName, blockName }) => {
  const { screenWidth } = useWindowDimensions();

  const [blockWidth, setBlockWidth] = useState(null);

  useLayoutEffect(() => {
    const width = getBlockWidthByTagName(blockName);
    setBlockWidth(width);
    // eslint-disable-next-line
  }, [screenWidth]);

  return (
    <div style={{ width: blockWidth }} className={loaderContainerName}>
      <div className="loader" />
    </div>
  );
};

export default Spinner;
