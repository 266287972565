import React from "react";
import "./PrivacyPolicy.scss";
import {APP_EMAIL_DOMAIN} from "../../shared/constants";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="app-title-container"><a className="app-title" href="/"> CoffeeTalk </a>
      </div>
      <h1>PRIVACY POLICY</h1>
      <h1>Last Updated: March 23, 2023</h1>
      <p> CoffeeTalk values its users' privacy and has accordingly formulated the present document. It describes which information we collect, the ways we may use it, the parties we might share it with, and how you may control its use and disclosure. Using CoffeeTalk means you accept the terms and conditions of this policy, so you should read it carefully.
      </p>

      <section>
        <p> That said, you are always welcome to contact CoffeeTalk if you have any questions or require clarifications (see below for our contact details). </p>
      </section>

      <section>
        <p> The only entity handling your information (the ‘data controller’ for EU-based users) is: </p>
      </section>

      <section>
        <p>CoffeeMe LLC</p>
        <p>108 West 13th Street, Wilmington, Delaware 19801.</p>
      </section>

      <h4>Scope of this Policy</h4>

      <section>
        <p> The present policy refers and applies to the CoffeeTalk website and web app and will also apply to the Android and iOS app once available.
        </p>
      </section>

      <h4>What do we collect?</h4>

      <section>
        <p><b>Overview.</b> In general terms, we may require certain items of personal data (e.g. name and email address) and other items of anonymized data. Using CoffeeTalk means you permit our gathering and processing of the data we require to operate in a satisfactory and sustainable manner. Using CoffeeTalk further means that you understand that any data we collect may be stored on, routed through, or processed on hardware and/or software infrastructures which may or may not be physically located in your country or territory (depending on CoffeeTalk's operational needs). </p>
      </section>

      <section>
        <p><b>What you provide.</b> Creating a CoffeeTalk account requires your: Social Media, Apple, or Gmail credentials. Doing so also means you allow us to use some of the information within these accounts (subject to the relevant service provider’s privacy settings). This might include such items as the information in your public profile, your email address, your birthday and the names and accounts of your social network connections. You might also be prompted to allow CoffeeTalk to gather information on your present location. Furthermore, you should be aware that CoffeeTalk stores the content of interactions between users, as well as the content of any communications between you and our customer service representatives. </p>
      </section>

      <section>
        <p><b>What we may gather from other sources.</b> CoffeeTalk may gather personal and/or non-personal information from other members' websites, as well as CoffeeTalk business partners and third parties. </p>
      </section>

      <section>
        <p><b>The means used for data collection</b> Your information is almost entirely collected, processed, and analyzed by fully automated technologies. </p>
      </section>

      <section>
        <p><b>Data captured automatically upon visiting CoffeeTalk</b> Whenever you visit the CoffeeTalk website or use the CoffeeTalk app, certain items of a largely technical nature are captured automatically. These include, but are not limited to, your Internet Protocol (IP) address (your device's unique internet identifier), the type and unique identification ID of the access device, the browser used to access CoffeeTalk (if applicable), the operating system running on the device used for said access, and the Internet address of the website which referred you to CoffeeTalk (e.g. Google). </p>
      </section>

      <section>
        <p><b>Cookies.</b> CoffeeTalk might use cookies (time-limited text files which store session data to improve your CoffeeTalk experience. However, cookies can also be used for collecting data about your visits to CoffeeTalk and about the activities you carry out during each session. They might also be used by CoffeeTalk or by third parties (e.g. Google) to work out which advertisements would suit you best based on your activities while on CoffeeTalk. </p>
      </section>

      <section>
        <p><b>Pixel Tags.</b> CoffeeTalk might use pixel tags (single-pixel, practically invisible graphics) which are also known as web beacons for accessing cookies, measuring user activities, and measuring how many times ads were viewed.
        </p>
      </section>
      <section>
        <p><b>Mobile Device Identifiers. </b> CoffeeTalk will gather the unique (manufacturer-assigned) IDs of your devices, and the advertising IDs assigned by, iOS for example, for storing your CoffeeTalk preferences and monitoring your use of CoffeeTalk. A device ID is hardcoded and thus cannot be removed. However, an advertising ID can be reset through the 'Settings' menu on your device. Apart from CoffeeTalk, ad companies and analytics companies use the same mobile device data to personalize targeted ads, measure the success of ads campaigns and analyze app usage information.
        </p>
      </section>
      <section>
        <p><b>Data collected by advertisers.</b> CoffeeTalk may permit the presentation of advertisements within CoffeeTalk. The advertisers may use such technologies as cookies and web beacons to measure the efficacy of their advertising campaigns. CoffeeTalk will only provide aggregate and/or anonymized user data to these advertisers. Some advertisers may be members of the Network Advertising Initiative, which allow you to opt out of targeting by all members at a single location ( <a
          href="http://www.networkadvertising.org"> www.networkadvertising.org </a> ). However, opting out will only prevent the targeting and personalization of advertisements. It will not prevent advertisements from being displayed, nor will it reduce their number.</p>
      </section>

      <section>
        <p><b>Location Data.</b> If you permit it, CoffeeTalk will collect information that allows us to determine your present location by either employing the device’s GPS hardware to determine your longitude and latitude (to within a few meters), or by gathering location-relevant data from your device’s Bluetooth or WiFi connection.
        </p>
      </section>

      <h4>How does CoffeeTalk use the data it gathers?</h4>

      <section>
        <p><b>Overview.</b> CoffeeTalk may use the data it gathers to optimize its products and services, for the day-to-day management of CoffeeTalk, to manage user accounts, to provide customer support, to gain research insights, to communicate offers of possible interest to users, to target and personalize advertising, to verify eligibility and deliver rewards during promotional campaigns, to carry out actions further to a provision in the CoffeeTalk Terms of Use document, and to carry out other functions not mentioned above as necessary as long as you are provided with a description of the data used and the grounds for their use. Lest it be unclear, we will always ask for your consent before sharing any of your data and clearly state the reason why said sharing is necessary. Furthermore, you may also withdraw your consent at any time by contacting us according to the contact details provided at the end of the present document. </p>
      </section>

      <section>
        <p><b>Job Applications.</b> Personal data provided to CoffeeTalk as part of a job application will only be used to that end. However, CoffeeTalk may pass it along to a third party which specializes in processing, analyzing and evaluating such data if CoffeeTalk believes such a measure is necessary.
        </p>
      </section>

      <section>
        <p><b>Intermediaries.</b> CoffeeTalk may process user data internally, or may contract a third party to perform user data research and analysis. Furthermore, we may share some of your data with an authorized third party in strict accordance with its privacy settings and terms and conditions of service and in a transparent fashion.
        </p>
      </section>

      <h4>How does CoffeeTalk use the data it gathers?</h4>

      <section>
        <p><b>Other CoffeeTalk Users.</b> Opening a CoffeeTalk account means other CoffeeTalk users can see your profile and any data therein. This will be the case if you inputted the profile data into CoffeeTalk yourself, or if the profile data was inputted into CoffeeTalk algorithmically by importing data from another platform (such as a social media account).
        </p>
      </section>

      <section>
        <p><b>Third Parties – Overview.</b> CoffeeTalk only provides personalized user data to third parties in accordance with the provisions of the present document. In other cases where this becomes necessary, CoffeeTalk will inform the relevant users and offer them the possibility of refusing to have their personalized user data shared with a third party.
        </p>
      </section>

      <section>
        <p><b>Third Parties – Service Providers</b> CoffeeTalk may disclose personalized user data to third parties acting on its behalf for such purposes as fulfilling orders, providing marketing assistance or screening members. However, these service providers may only use this data for the service they were contracted to perform, and may not use it for other purposes.
        </p>
      </section>

      <section>
        <p><b>Third Parties – Other – Legal Challenges.</b> CoffeeTalk may disclose personalized user data if it is served with a valid subpoena or similar document, a court order, or a duly certified request from a law enforcement or government agency. It may also do so if its legal rights are in question, or if it is required to do so in the interests of its legal defense or for other legal purposes. In any such cases, CoffeeTalk will, however, invariably consult, consider and act upon the recommendations of its legal advisors as it sees fit – which may or may not involve the disclosure of personalized user data.
        </p>
      </section>

      <section>
        <p><b>Third Parties – Other – Other Legal Cases.</b> CoffeeTalk may disclose personalized user data in the course of handling illegal activities (e.g. fraud) on CoffeeTalk, if it believes it necessary for protecting CoffeeTalk's corporate interests, if our legal advisors require us to do so for valid legal reasons, or as part of enforcing the CoffeeTalk Terms of Use document or other agreements incorporated therein.
        </p>
      </section>

      <section>
        <p><b>Third Parties – Other – Special and Extreme Legal Cases.</b>CoffeeTalk may disclose personalized user data in special and/or extreme legal circumstances. These might include, but are not limited, to CoffeeTalk's merger with another company, the sale of corporate assets, and bankruptcy. As with other legal cases, the exact nature and extent of such disclosures shall depend on the recommendations of CoffeeTalk's legal advisors. </p>
      </section>

      <section>
        <p><b>Aggregate and Personalized Information.</b> CoffeeTalk may disclose aggregate user data in some of the aforementioned circumstances. Such data may also be disclosed to third parties for the purposes of advertisement campaign analysis, research, and development. In some occasions, aggregate and personalized user data may be combined. In other cases, aggregate information may be disclosed in a hashed (a hash being the unique result of one or more one-way functions performed on a set of data) form for business development purposes. If you wish to opt out of the disclosure of your data (even if anonymized) to advertisers, please consult the information available at: <a
            href="http://www.aboutads.info/choices"> http://www.aboutads.info/choices/. </a></p>
      </section>

      <h4>User Data Gathering for Do Not Track (DNT) Purposes</h4>

      <section>
        <p> In the course of using CoffeeTalk, you might be presented with links to external resources. The present document does not apply to these external resources, and you are advised to consult their respective privacy policies before using them. </p>
      </section>

      <h4>Means of Viewing and Editing Personalized User Data</h4>

      <section>
        <p> You can edit your CoffeeTalk personalized user data by accessing the relevant options under the 'Profile' menu. You may also delete your CoffeeTalk account. However, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints. Furthermore, if any of your personalized user data has been shared with third parties, you will have to contact these parties if you would like to have it removed.
        </p>
      </section>

      <h4> Ramifications of Gathering or Non-Gathering of Personalized User Data
      </h4>

      <section>
        <p> The extent of personalized user data you share with CoffeeTalk is up to you. However, the non-provision of certain items of personalized user data may have an adverse effect on your CoffeeTalk experience and might prevent you from activating or using certain features of CoffeeTalk.
        </p>
      </section>

      <h4> CoffeeTalk contains push notification functionality
      </h4>

      <section>
        <p>This means that it has the ability to issue app and email notifications that will be displayed on the home screen or your email account. If you would like to prevent these notifications from appearing, you can disable push notifications.
        </p>
      </section>

      <section>
        <p> As mentioned above, CoffeeTalk employs cookies (short text files containing session and user data) for various purposes. However, it is possible to prevent their use or delete them by configuring your device accordingly. The exact procedure involved is web-browser specific, so you are advised to refer to your respective device's documentation for further information.
        </p>
      </section>

      <h4>How CoffeeTalk Safeguards your Personalized User Data</h4>

      <section>
        <p> CoffeeTalk endeavors to safeguard your personalized user data by both technical and physical means to
          the best of its ability. However, since no security system is perfect, we advise you to ensure the security of
          your personal information. By using CoffeeTalk, you understand that CoffeeTalk cannot make any assurances
          concerning the security of your personalized user data. With this in mind, CoffeeTalk advises you to consult
          the
          following website – {" "} <a
            href="http://www.ftc.gov/bcp/menus/consumer/data.shtm"> http://www.ftc.gov/bcp/menus/consumer/data.shtm </a>{" "} for
          information on good data security practices. </p>
      </section>

      <h4> Safeguarding Personalized User Data from the Actions of Coffee Users or Visitors
      </h4>

      <section>
        <p> By using CoffeeTalk, you understand that you alone are responsible for safeguarding your personalized user data, and that any data you make available on a public part of CoffeeTalk is viewable and useable by both registered CoffeeTalk users as well as visitors regardless of the latter's intentions. CoffeeTalk therefore recommends that you exercise due consideration in selecting those items of personalized user data you wish to make public.</p>
      </section>

      <h4>Data Retention</h4>

      <section>
        <p> We retain your personal data insofar as is required by law or by legitimate business concerns such as reducing the level of risk exhibited by users who had to be banned from CoffeeTalk for exhibiting undesirable, dangerous, or criminal behavior. </p>
      </section>

      <h4>Child Safety</h4>

      <section>
        <p> CoffeeTalk is meant for a general audience. However, for legal reasons, and in the interests of child safety, CoffeeTalk cannot be used by minors under the age of 18. CoffeeTalk will also never collect personalized user data from such individuals. If this is done unintentionally, CoffeeTalk will delete said data promptly upon the receipt of valid proof of age (e.g. birth certificate) from the minor's parent or guardian. </p>
      </section>

      <h4>Jurisdictional Issues</h4>

      <section>
        <p> Using CoffeeTalk means you understand and agree to have your personalized user data stored and processed in the USA, since that is the location of CoffeeTalk's physical infrastructure.
        </p>
      </section>

      <h4>Third Party Rights</h4>

      <section>
        <p> It is to be understood that nothing in the present document may be construed as imbuing third parties with rights they may use against CoffeeTalk, nor should anything in the present document be construed as forcing the disclosure of personalized user data from CoffeeTalk unless specifically provided for above.
        </p>
      </section>

      <h4>Modifications</h4>

      <section>
        <p> CoffeeTalk may occasionally change, amend and/or update the present document. If it does so, the date at the top of the document shall change accordingly. CoffeeTalk may – at its sole discretion – notify CoffeeTalk users about such modifications, but is in no way, shape or form obliged to do so. In any case, the most up-to-date version of the present document shall always be available on the CoffeeTalk website, where it may be freely consulted by both registered users of CoffeeTalk as well as nonregistered visitors.
        </p>
      </section>

      <h4>Uninstallation and Accountability</h4>

      <section>
        <p> You can delete and/or uninstall CoffeeTalk, but you should be aware of the fact that your unique device identifier will remain on your device if you do so and will allow us to create an association between you and the data CoffeeTalk has stored about you should you decide to reinstall CoffeeTalk on the same device. </p>
      </section>

      <h4>How to contact CoffeeTalk</h4>

      <section>
        <p> Questions concerning the present document should be sent to CoffeeTalk at <a
          href={`mailto:help@${APP_EMAIL_DOMAIN}`}>help@{APP_EMAIL_DOMAIN}.</a></p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
