import React from "react";

import UserSmallAvatar from "../user-small-avatar";

import "./FollowersList.scss";

const CardList = ({ followers = [], unfollow, unfollowLocked = true }) => {
  return (
    <div className="followers">
      {followers.map((f) => (
        <div key={f.id} className="follower-card">
          <div>
            <UserSmallAvatar
              avatarClassName="sub-img"
              userAvatarLink={
                Array.isArray(f.follower.photoURL)
                  ? f.follower.photoURL[0]
                  : f.follower.photoURL
              }
            />
            <span>{f.follower.name}</span>
          </div>
          {!unfollowLocked && (
            <button className="unfollow-button" onClick={() => unfollow(f)}>
              Unfollow
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(CardList);
