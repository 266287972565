import { connect } from "react-redux";
import CoffeeCard from "./../components/coffee-card";

export function mapStateToProps({ userState }) {
  return {
    userState: userState,
  };
}

export default connect(mapStateToProps)(CoffeeCard);
