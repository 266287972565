import React from 'react';

import MobileInvitationDetails from './MobileInvitationDetails';
import DesktopInvitationDetails from './DesktopInvitationDetails';

import useWindowDimensions from '../../shared/windowResize';
import { MOBILE_WIDTH } from '../../shared/constants';

const InvitationDetails = props => {
  const { screenWidth } = useWindowDimensions();

  return screenWidth < MOBILE_WIDTH ? (
    <MobileInvitationDetails {...props} />
  ) : (
    <DesktopInvitationDetails {...props} />
  );
};

export default InvitationDetails;
