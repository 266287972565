import React from "react";

import "./PopUpNotification.scss";
import CloseButton from "../../shared/icons/edit-pop-up-close-button.svg";

const PopUpNotification = (props) => {
  const setTextToNull = () => {
    props.setPopUpNotificationText({ text: null });
  };

  return (
    <div className="pop-up-notification-container">
      <div className="notification">
        <p>{props.text}</p>
        <button className="close-notification" onClick={setTextToNull}>
          <img src={CloseButton} alt="close" />
        </button>
      </div>
    </div>
  );
};

export default PopUpNotification;
