import React, { useEffect, useState } from "react";

import NewsCard from "../news-card";
import Spinner from "../spinner";

import { NewsService } from "../../services/News";

import * as _ from "lodash";

import "./Topics.scss";
import { MapHelper } from "../../helpers";

const Topics = ({ topics, setTopics, currentLocation }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getTopFiveNews() {
      setIsLoading(true);
      if (!_.isNull(topics.news)) {
        setIsLoading(false);
        return;
      }

      if (_.isNull(currentLocation)) {
        setIsLoading(false);
        return;
      }

      const userRegion = await MapHelper.getRegionByLatLng(
        currentLocation.lat,
        currentLocation.lng
      );

      const response = await NewsService.getTopFiveHeadlinesNewsByRegion(
        userRegion || "none"
      );

      setTopics({ news: response });
      setIsLoading(false);
      return response;
    }

    getTopFiveNews();
  }, [topics, currentLocation, setTopics]);

  return (
    <React.Fragment>
      <div className="topics-container">
        {isLoading && (
          <Spinner
            loaderContainerName="topics-loader-container"
            blockName="topics-container"
          />
        )}
        {!_.isNull(topics.news) && (
          <>
            <p className="topics-for-you">{topics.title}</p>
            <div className="in-the-news-container">
              <p className="in-the-news">In the news</p>
              <hr />
            </div>
            <div className="news-list-container">
              {!_.isEmpty(topics.news) ? (
                topics.news.map((news, index) => (
                  <NewsCard key={news.title} news={news} index={index} />
                ))
              ) : (
                <div className="empty-news">
                  No news for such short description
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Topics;
