import * as constants from '../constants';

export function setNewCoffee(payload) {
  return {
    type: constants.SET_USER_INFO,
    payload,
  };
}

export function setCoffees(payload) {
  return {
    type: constants.SET_COFFEES,
    payload,
  };
}

export function setFakeCards(payload) {
  return {
    type: constants.SET_FAKE_CARDS,
    payload,
  };
}
