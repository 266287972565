import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import CoffeeCard from "../../containers/CoffeeCard";
import UserShortInfo from "../user-short-info";
import Spinner from "../spinner";
import Verifications from "../verifications";
import InvitationDetailsButtons from "./InvitationDetailsButton";
import CoffeeDetailsData from "./CoffeeDetailsData";

import { HistoryHelper } from "../../helpers";
import { RelationControlInvitationsService } from "../../services/RelationControl";

import "./InvitationDetails.scss";
import * as _ from "lodash";

const MobileInvitationDetails = ({ setTitle, setPopUpNotificationText }) => {
  setTitle("Invitations");
  const { id } = useParams();
  const [invitation, setInvitation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const coffeeCardData = _.assign(
    {},
    { user: _.get(invitation, "admin") },
    _.get(invitation, "coffee")
  );

  useEffect(() => {
    async function getInvitationById() {
      const response = await RelationControlInvitationsService.getInvitationById(
        id
      );

      if (_.isEmpty(response)) {
        setPopUpNotificationText({
          text: "Sorry, invitation is not exists",
        });
        HistoryHelper.redirectTo("/notifications");
        return;
      }
      setInvitation(response);
      setIsLoading(false);
      return response;
    }
    getInvitationById();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="invitation-details-container">
      {isLoading && (
        <Spinner
          loaderContainerName="loader-container"
          blockName="invitation-details-container"
        />
      )}
      {!_.isNull(invitation) && (
        <>
          <span className="invitation-details">
            Coffee Meeting Confirmation
          </span>
          <CoffeeCard
            coffee={coffeeCardData}
            user={coffeeCardData.user}
            status={false}
          />
          <UserShortInfo user={_.get(invitation, "user")} />
          <div className="invitation-user-verifications">
            <span className="verification-text">Verifications</span>
            <Verifications
              authMethods={_.get(invitation, "user.authMethods")}
            />
          </div>

          <div className="invitation">
            <span className="perspective-text">
              Why {_.get(invitation, "user.name")} wants to meet?
            </span>
            <p className="invitation-text">{_.get(invitation, "interests")}</p>
          </div>
          <div className="invitation-meeting-details">
            <CoffeeDetailsData invitation={invitation} />
          </div>
          <InvitationDetailsButtons
            invitation={invitation}
            setPopUpNotificationText={setPopUpNotificationText}
            setIsLoading={setIsLoading}
          />
        </>
      )}
    </div>
  );
};

export default MobileInvitationDetails;
