import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import Header from '../components/header';

export function mapStateToProps({ appPagesState }) {
  return {
    appPagesState: appPagesState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setAppPagesStatus: payload => dispatch(actions.setAppPagesStatus(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
