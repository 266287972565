import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as _ from "lodash";

import ZoomIcon from "../../shared/icons/zoom-icon.svg";
import CoffeeIcon from "../../shared/icons/coffee-fill-white-icon.png";

import "./Coffee.scss";
import SearchCafes from "./SelectCaffees";

import moment from "moment";
import { Grid } from "@material-ui/core";
import { FormatHelper } from "../../helpers";
import { HistoryHelper } from "../../helpers";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";
import { PermissionsService } from "../../services/Permissions";
import { EmailService } from "../../services/Email";
import { TimePickerService } from "../../services/TimePicker";
import { FollowersService } from "../../services/Followers";
import { useLocalStorage } from "../../shared/LocalStorageHook";
import Spinner from "../spinner";

import ZoomTab from "./Tabs/ZoomTab";
import ShopTab from "./Tabs/ShopTab";
import { StripeService } from "../../services/Stripe";
import { MeetingTypes } from "../../shared/MeetingTypes";
import { APP_DOMAIN } from "../../shared/constants";
import { PaymentTypes } from "../../shared/PaymentTypes";

const MAX_LENGTH = {
  description: 100,
};

const followersService = new FollowersService();

const CoffeeForm = (props) => {
  const {
    setTitle,
    user,
    setPopUpNotificationText,
    setClickedIcon,
    setSignInPageStatus,
    signInPageStatus,
    setUserInfo,
  } = props;

  useEffect(() => {
    if (signInPageStatus) {
      return;
    }

    setTitle("Start a Talk");
    // eslint-disable-next-line
  }, []);

  const [openSearch, setOpenSearch] = useState(false);

  const [charactersLeft, setCharactersLeft] = useState({
    description: MAX_LENGTH.description,
  });
  const [coffeeTimeFrom, setCoffeeTimeFrom] = useState(user.availabilityFrom);
  const [coffeeTimeTo, setCoffeeTimeTo] = useState(() => {
    return moment(user.availabilityFrom * 1000)
      .add(1, "h")
      .unix();
  });

  const [formValue, setFormValue] = useState(() => {
    let timeFrom = user.availabilityFrom;
    let timeTo = moment(timeFrom * 1000)
      .add(1, "hour")
      .unix();

    return {
      description: "",
      duration_time: 60,
      time_from: timeFrom,
      time_to: timeTo,
      payment_type: PaymentTypes.PAID,
      payment_amount: 0,
      type: MeetingTypes.LIVE,
    };
  });

  const [coffees, setCoffee] = useState([...props.coffees]);
  const [invalidTime, setInvalidTime] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pickerFrom, setPickerFrom] = useState(false);
  // eslint-disable-next-line
  const [pickerTo, setPickerTo] = useState(false);

  const [anchorEl, setAnchorEl] = useState(false);
  const [errors, setErrors] = useState({});

  const [localStorageCoffee, setLocalStorageCoffee] = useLocalStorage(
    "coffee",
    null
  );
  const [chosenTab, setChosenTab] = useState("zoom");
  const [locationAccess, setLocationAccess] = useState(true);
  const [chargesEnalbled, setChargesEnabled] = useState(false);

  const zoomHref = `https://zoom.us/oauth/authorize?client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&response_type=code&redirect_uri=${APP_DOMAIN}/zoom`;

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (localStorageCoffee !== null) {
      setIsLoading(true);

      setFormValue(localStorageCoffee);
      setCoffeeTimeFrom(localStorageCoffee.time_from);
      setCoffeeTimeTo(localStorageCoffee.time_to);

      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getAccessToUserLocation() {
      await PermissionsService.getLocationPermission().then((access) => {
        if (!access) {
          setLocationAccess(false);
        }
      });
    }

    getAccessToUserLocation();
  }, []);

  useEffect(() => {
    if (!user.stripeId) {
      return;
    }

    StripeService.getAccountData(user.stripeId)
      .then((res) => {
        if (res.charges_enabled) {
          setChargesEnabled(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [user]); // eslint-disable-line

  const setFormField = (event, name) => {
    const formValueFromEvent = event.target.value;
    if (name === "description") {
      const validCharactersIsLeft =
        MAX_LENGTH[name] - formValueFromEvent.length;

      if (validCharactersIsLeft < 0) {
        return;
      }

      setCharactersLeft({
        ...charactersLeft,
        [name]: validCharactersIsLeft,
      });
    }

    setFormValue({ ...formValue, [name]: formValueFromEvent });
  };

  const setAvailability = () => {
    const isTimeIntervalInvalid = moment(coffeeTimeFrom * 1000).isSameOrAfter(
      coffeeTimeTo * 1000,
      "milliseconds"
    );

    if (isTimeIntervalInvalid) {
      setInvalidTime(true);
      setTimeout(() => setInvalidTime(false), 1000);
      return;
    }

    const isTimeFromInvalid = coffeeTimeFrom < moment().unix();

    if (isTimeFromInvalid) {
      setInvalidTime(true);
      setTimeout(() => setInvalidTime(false), 1000);
      return;
    }

    setFormValue({
      ...formValue,
      time_from: coffeeTimeFrom,
      time_to: coffeeTimeTo,
    });

    return true;
  };

  const setCoffeeForForm = (coffees) => {
    if (_.isEmpty(coffees)) {
      setErrors((err) => {
        return {
          ...err,
          coffees: `Select ${
            formValue.type === MeetingTypes.COFFEE_GROUP ? 1 : 3
          } coffee shops please.`,
        };
      });
    } else {
      const updatedErrors = errors;
      delete updatedErrors["coffees"];
      setErrors(updatedErrors);
    }

    setCoffee(coffees);
    setOpenSearch(false);
  };

  const onComplete = () => {
    setLocalStorageCoffee(null);
    setClickedIcon("home-icon");
    HistoryHelper.redirectTo("/");
  };

  const validateOnCreate = () => {
    const foundErrors = {};

    if (!formValue["description"]) {
      foundErrors["description"] = "Field is required.";
    }

    if (chosenTab === "shop" && _.isEmpty(coffees)) {
      foundErrors["coffees"] = `Select ${
        formValue.type === MeetingTypes.COFFEE_GROUP ? 1 : 3
      } coffee shops please.`;
    }

    if (coffeeTimeFrom < moment().unix()) {
      foundErrors["time"] = "Update you availability time";
    }

    setErrors((err) => {
      return { ...err, ...foundErrors };
    });
  };

  const createCoffee = async () => {
    try {
      setIsLoading(true);

      if (!user.id) {
        setSignInPageStatus(true);
        return;
      }

      if (chosenTab === "shop") {
        const isValid = setAvailability();

        const coffeeReqObj = FormatHelper.getCoffeeFormValue(
          formValue,
          coffees
        );

        validateOnCreate();

        if (!isValid || !coffeeReqObj) {
          return;
        }

        await RelationControlCoffeeService.addCoffee(coffeeReqObj);
        const subsEmails = await followersService.getMySubscribersEmails();

        await EmailService.sendNotificationEmail(
          subsEmails,
          `${user.name} added new coffee talk!`,
          `${user.name} added new coffee talk "${coffeeReqObj.description}"!`
        );
        onComplete();
        return;
      }

      if (formValue.payment_amount !== 0 && !chargesEnalbled) {
        setPopUpNotificationText({
          text: "Connect with stripe to continue.",
        });
        return;
      }

      if (formValue.type === MeetingTypes.LIVE) {
        goLive();
        return;
      }

      validateOnCreate();
      const coffeeReqObj = FormatHelper.getZoomFormValue(
        formValue,
        chargesEnalbled
      );

      if (!coffeeReqObj) {
        return;
      }

      const timeTo = moment(coffeeTimeFrom * 1000)
        .add(formValue.duration_time, "minutes")
        .unix();

      const formData = {
        ...formValue,
        time_from: coffeeTimeFrom,
        time_to: timeTo,
      };

      setLocalStorageCoffee(formData);
      window.location.assign(zoomHref);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (date) => {
    const isOldDate = moment(date).diff(moment(), "days") < 0;

    if (isOldDate < 0) {
      return;
    }

    const hoursDiff = moment(date).diff(coffeeTimeTo * 1000, "hours");

    const dayDiff = hoursDiff / 24;
    const roundedDaysDiff = Math.ceil(dayDiff);

    const updatedTimeFrom = moment(coffeeTimeFrom * 1000)
      .add(roundedDaysDiff, "days")
      .unix();

    const updatedTimeTo = moment(coffeeTimeTo * 1000)
      .add(roundedDaysDiff, "days")
      .unix();

    setCoffeeTimeFrom(updatedTimeFrom);
    setCoffeeTimeTo(updatedTimeTo);

    setFormValue({
      ...formValue,
      time_from: updatedTimeFrom,
      time_to: updatedTimeTo,
    });
  };

  const getTimeIn12hFormat = (date) => {
    return moment(date * 1000).format("h:mm A");
  };

  const daysRange = (date) => {
    const diff = moment(date).diff(moment(), "days");
    return diff > 13 || diff < 0;
  };

  const formatAMPM = (date) => {
    let hours = date.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return hours;
  };

  const dateConfig = {
    hour: {
      format: (value) => formatAMPM(value),
      // format: "hh",
      caption: "Hour",
      step: 1,
    },
    minute: {
      format: "mm",
      caption: "Min",
      step: 15,
    },
  };

  const handleClick = (event, timeType) => {
    if (timeType === "from") {
      setPickerTo(false);
      setPickerFrom(true);
    }
    if (timeType === "to") {
      setPickerTo(true);
      setPickerFrom(false);
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectTime = (time) => {
    const newTime = moment(time).unix();

    if (newTime < moment().unix()) {
      setInvalidTime(true);
      setTimeout(() => setInvalidTime(false), 1000);
      return;
    }

    const isNotCurrentDate =
      moment(newTime * 1000).unix() >
      moment(coffeeTimeFrom * 1000)
        .set("hours", 23)
        .set("minutes", 0)
        .set("seconds", 0)
        .unix();

    if (isNotCurrentDate) {
      setInvalidTime(true);
      setTimeout(() => setInvalidTime(false), 1000);
      return;
    }

    const timeTo = moment(newTime * 1000)
      .add(1, "hour")
      .unix();

    setFormValue({ ...formValue, time_from: newTime, time_to: timeTo });
    setCoffeeTimeFrom(newTime);
    setCoffeeTimeTo(timeTo);
    return;
  };

  const getPickerValue = () => {
    if (pickerFrom) {
      return moment(coffeeTimeFrom * 1000).toDate();
    }
    return moment(coffeeTimeTo * 1000).toDate();
  };

  const getZoomSubmitContent = () => {
    if (formValue.type === MeetingTypes.LIVE) {
      return "Go Live";
    }

    return "Schedule";
  };

  const pickerOpen = Boolean(anchorEl);

  const showTabs = () => {
    switch (chosenTab) {
      case "shop":
        return (
          <ShopTab
            setOpenSearch={setOpenSearch}
            coffees={coffees}
            formValue={formValue}
            setFormValue={setFormValue}
            daysRange={daysRange}
            coffeeTimeFrom={coffeeTimeFrom}
            handleDateChange={handleDateChange}
            invalidTime={invalidTime}
            handleClick={handleClick}
            getTimeIn12hFormat={getTimeIn12hFormat}
            pickerOpen={pickerOpen}
            anchorEl={anchorEl}
            handleClose={handleClose}
            onSelectTime={onSelectTime}
            dateConfig={dateConfig}
            getPickerValue={getPickerValue}
            errors={errors}
            setCoffee={setCoffee}
          />
        );
      case "zoom":
        return (
          <ZoomTab
            formValue={formValue}
            daysRange={daysRange}
            setFormValue={setFormValue}
            coffeeTimeTo={coffeeTimeTo}
            setCoffeeTimeTo={setCoffeeTimeTo}
            coffeeTimeFrom={coffeeTimeFrom}
            setCoffeeTimeFrom={setCoffeeTimeFrom}
            invalidTime={invalidTime}
            handleClick={handleClick}
            getTimeIn12hFormat={getTimeIn12hFormat}
            pickerOpen={pickerOpen}
            anchorEl={anchorEl}
            handleClose={handleClose}
            dateConfig={dateConfig}
            setInvalidTime={setInvalidTime}
            errors={errors}
            setErrors={setErrors}
            chargesEnalbled={chargesEnalbled}
            setUserInfo={setUserInfo}
            user={user}
            setSignInPageStatus={setSignInPageStatus}
          />
        );
      default:
        return <></>;
    }
  };

  const showPostButton = () => {
    switch (chosenTab) {
      case "shop":
        return (
          <div className="create-button-container">
            <button
              type="submit"
              className={`${
                FormatHelper.getCoffeeFormValue(formValue, coffees)
                  ? "active"
                  : ""
              }`}
            >
              Schedule
            </button>
          </div>
        );
      case "zoom":
        return (
          <div className="create-button-container">
            <button
              type="submit"
              className={`${
                FormatHelper.getZoomFormValue(formValue, chargesEnalbled) ||
                (formValue.type === MeetingTypes.LIVE && formValue.description)
                  ? "active"
                  : ""
              }`}
            >
              {getZoomSubmitContent()}
            </button>
          </div>
        );
      default:
        return <></>;
    }
  };

  const onTextAreaChange = (e, field) => {
    setFormField(e, field);
    validateText(e, field);
  };

  const validateText = (e, field) => {
    const value = e.target.value;
    if (!value) {
      setErrors((err) => {
        return { ...err, [field]: "Field is required." };
      });
      return;
    }

    const updatedErrors = errors;
    delete updatedErrors[field];
    setErrors(updatedErrors);
  };

  const openShopTab = () => {
    if (locationAccess) {
      const timeDiff = moment(coffeeTimeTo * 1000).diff(
        coffeeTimeFrom * 1000,
        "minutes"
      );

      if (timeDiff < 60) {
        const timeTo = moment(coffeeTimeFrom * 1000)
          .add(60, "minutes")
          .unix();

        setCoffeeTimeTo(timeTo);
      }

      setFormValue({ ...formValue, payment_amount: 0, payment_type: PaymentTypes.PAID });
      setChosenTab("shop");
      return;
    }

    setPopUpNotificationText({ text: "Please turn on location" });
  };

  const goLive = () => {
    if (!formValue["description"]) {
      setErrors((err) => {
        return { ...err, description: "Field is required." };
      });
      return;
    }

    const timeFrom = moment(TimePickerService.getRoundedTime()).unix();
    const timeTo = moment(timeFrom * 1000)
      .add(1, "h")
      .unix();

    const data = {
      description: formValue.description,
      duration_time: 60,
      time_from: timeFrom,
      time_to: timeTo,
      payment_amount: formValue.payment_amount,
      payment_type: formValue.payment_type,
      type: MeetingTypes.LIVE
    };

    setFormValue(data);

    setLocalStorageCoffee(data);
    window.location.assign(zoomHref);
  };

  return (
    <React.Fragment>
      {isLoading && (
        <Spinner loaderContainerName="loader-container" blockName="modal" />
      )}
      {!openSearch && (
        <form onSubmit={handleSubmit(createCoffee)}>
          <div className="description">
            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              direction="column"
              className="first-question"
            >
              <h2>New talk</h2>
              <p>What do you want to talk about?</p>
            </Grid>

            <textarea
              autoFocus
              ref={register}
              value={formValue.description}
              onChange={(e) => onTextAreaChange(e, "description")}
              name="description"
              placeholder="Propose anything that interests you"
              className={errors["description"] && "error"}
            />
            {errors["description"] && (
              <div className="validation">{errors["description"]}</div>
            )}

            <div className="characters-left-container">
              <p className="characters-left">
                {charactersLeft.description} characters left
              </p>
            </div>
          </div>

          <div className="map-selector">
            <p className="where-to-meet-text">Where do you want to meet?</p>

            <div className="meeting-place-container">
              <div
                onClick={openShopTab}
                className={`coffee-tab ${chosenTab === "zoom" && "unchosen"}`}
              >
                <div className="coffee-place-icon">
                  <img src={CoffeeIcon} alt="place" />
                </div>
                Coffee Shop
              </div>

              <div
                onClick={() => {
                  setChosenTab("zoom");
                }}
                className={`zoom-tab ${chosenTab === "shop" && "unchosen"}`}
              >
                <img
                  src={ZoomIcon}
                  className="zoom-place-icon"
                  alt="nav-bar-coffee"
                />
                <span className="coffee-talk-text">Zoom</span>
              </div>
            </div>
          </div>

          {showTabs()}

          {showPostButton()}
        </form>
      )}

      {openSearch && (
        <SearchCafes
          coffees={coffees}
          cancel={() => setOpenSearch(false)}
          setCoffeeForForm={setCoffeeForForm}
          type={formValue.type}
        />
      )}
    </React.Fragment>
  );
};

export default CoffeeForm;
