export class NavLinkHelper {
  static getCurrentNavIcon(url) {
    if (url === "/") {
      return "home-icon";
    }

    if (url === "/new-talk") {
      return "coffee-icon";
    }

    if (url === "/notifications") {
      return "notifications-icon";
    }

    if (url === "/user") {
      return null;
    }
  }

  static getTitleByPathname(url) {
    if (url === "/new-talk") {
      return "Start a Talk";
    }
    return null;
  }
}
