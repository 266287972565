import * as _ from 'lodash';
import { FirestoreDatabaseService } from '../shared/FirestoreDatabase';

const TABLE_NAME = '/future-meetings';

export class FutureMeetingsFirebaseService extends FirestoreDatabaseService {
  constructor() {
    super(TABLE_NAME);
  }

  async addFutureMeeting(futureMeeting) {
    return this.add(futureMeeting);
  }

  async deleteFutureMeeting(futureMeetingId) {
    await this.delete(futureMeetingId);
  }

  async updateFutureMeeting(futureMeeting) {
    const futureMeetingInDB = await this.getById(futureMeeting.id);

    const meetingForUpdate = _.assign({}, futureMeetingInDB, futureMeeting);

    const updatedFutureMeeting = await this.update(futureMeeting.id, meetingForUpdate);
    return updatedFutureMeeting;
  }
}
