import { connect } from 'react-redux';
import UserPage from '../components/user-page';
import * as actions from '../redux/actions';

export function mapStateToProps({ userState, mapState }) {
  return {
    user: userState,
    currentLocation: mapState.currentLocation,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setEmptyUserInfo: payload => dispatch(actions.setEmptyUserInfo(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
