import React, { useState, useEffect } from "react";
import "./SelectCaffes.scss";
import MapContainer from "../../containers/MapSearch";
import * as _ from "lodash";
import Spinner from "../spinner";
import { FormatHelper } from "./../../helpers/Formater";
import CoffeeList from "../coffee-list";
import { MeetingTypes } from "../../shared/MeetingTypes";

const SearchCafes = ({ coffees = [], setCoffeeForForm, cancel, type }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [currentCoffees, setCurrentCoffees] = useState([]);
  const [selectedCoffees, setSelectedCoffees] = useState([...coffees]);
  const [maxAvailableCafes, setMaxAvailableCafes] = useState(3);

  useEffect(() => {
    if (type === MeetingTypes.COFFEE_GROUP) {
      setMaxAvailableCafes(1);
    }
  }, [type]);

  const addCoffees = (coffee) => {
    if (isAlreadySelected(coffee)) {
      _.remove(selectedCoffees, { place_id: coffee.place_id });
    } else {
      if (selectedCoffees.length >= maxAvailableCafes) {
        return;
      }
      selectedCoffees.push(FormatHelper.getCoffeeInfo(coffee));
    }
    setSelectedCoffees(() => [...selectedCoffees]);
  };

  const setCoffee = (coffees) => {
    setCurrentCoffees(coffees);
    setIsLoading(true);
  };

  const addCoffeesToRedux = () => {
    if (
      selectedCoffees.length !== maxAvailableCafes &&
      selectedCoffees.length !== 0
    ) {
      return;
    }
    setCoffeeForForm(selectedCoffees);
  };

  const isAlreadySelected = (coffee) => {
    return !!_.find(selectedCoffees, { place_id: coffee.place_id });
  };

  return (
    <div className="modal">
      <div className="intro-text">
        <p className="where-to-meet-text">Where do you want to meet?</p>
        <p className="select-shops-text">
          Select {maxAvailableCafes} coffee shop{" "}
          {maxAvailableCafes === 1 ? "option" : "options"}&nbsp;to meet at.
        </p>
      </div>

      <MapContainer isSearchable={true} setCoffee={setCoffee} />
      <div className="cafes-list">
        {currentCoffees.length > 0 && (
          <CoffeeList
            coffees={currentCoffees}
            addCoffees={addCoffees}
            isAlreadySelected={isAlreadySelected}
          />
        )}
        {!isLoading && (
          <Spinner loaderContainerName="loader-container" blockName="modal" />
        )}
      </div>
      <div className="finish-button-container">
        <button className="cancel-button" onClick={cancel}>
          Cancel
        </button>
        <button
          className={`done-button ${
            selectedCoffees.length === maxAvailableCafes ||
            selectedCoffees.length === 0
              ? "active"
              : ""
          }`}
          onClick={addCoffeesToRedux}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default SearchCafes;
