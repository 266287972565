import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import PopUpNotification from '../components/pop-up-notification';

export function mapStateToProps({ popUpNotificationState }) {
  return {
    popUpNotification: popUpNotificationState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setPopUpNotificationText: payload =>
      dispatch(actions.setPopUpNotificationText(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpNotification);
