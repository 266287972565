import { MOBILE_WIDTH } from '../shared/constants';

export class NavigatorService {
  static setFontStyle(screenWidth) {
    const body = document.getElementsByTagName('body')[0];
    const currentFontFamily = body.style.fontFamily;

    if (screenWidth < MOBILE_WIDTH) {
      this.fontCorrection(currentFontFamily, body, 'GT-Walsheim-Regular');
      return;
    }
    this.fontCorrection(currentFontFamily, body, 'Poppins');
  }

  static fontCorrection(currentFontFamily, body, fontFamily) {
    if (currentFontFamily !== fontFamily) {
      body.style.fontFamily = fontFamily;
    }
  }

  static getScreenWidth() {
    const screenWidth = window.screen.availWidth;
    return screenWidth;
  }
}
