import * as constants from "../constants";

export function setAppPagesStatus(payload) {
  return {
    type: constants.SET_AVAILABILITY_PAGE_STATUS,
    payload,
  };
}

export function setSignInPageStatus(payload) {
  return {
    type: constants.SET_SIGN_IN_PAGE,
    payload,
  };
}

export function setSignInPageText(payload) {
  return {
    type: constants.SET_SIGN_IN_PAGE_TEXT,
    payload,
  };
}

export function setSplashStatus(payload) {
  return {
    type: constants.SET_SPLASH_STATUS,
    payload,
  };
}

export function setStripeModalStatus(payload) {
  return {
    type: constants.SET_STRIPE_MODAL_STATUS,
    payload,
  };
}
