import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import MapContainer from './../components/map/GoogleMap';
import { GoogleApiWrapper } from 'google-maps-react';

export function mapStateToProps({ mapState }) {
  return {
    currentLocation: mapState.currentLocation,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setCurrentLocation: payload =>
      dispatch(actions.setCurrentLocation(payload)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  })(MapContainer)
);
