import React, { useRef } from 'react';

import useOutsideClick from '../../../shared/OutsideClick';

import EditPencil from '../../../shared/icons/edit-pencil.svg';
import Delete from '../../../shared/icons/delete.svg';

import './SettingModal.scss';

const SettingModal = ({
  handleOutsideClickSettingsMenu = () => {},
  onEdit,
  onDelete,
}) => {
  const settingMenuRef = useRef(null);

  const settingsHandlerWrapper = e => {
    handleOutsideClickSettingsMenu(e);
  };

  useOutsideClick(settingMenuRef, settingsHandlerWrapper);

  return (
    <div className="setting-menu" ref={settingMenuRef}>
      <button className="setting-button" onClick={onEdit}>
        <img src={EditPencil} alt="edit-setting" />
        <p>Edit</p>
      </button>
      <button className="setting-button" onClick={onDelete}>
        <img src={Delete} alt="delete" />
        <p>Delete</p>
      </button>
    </div>
  );
};

export default SettingModal;
