import React from 'react';
import './Footer.scss';

const Footer = props => (
  <React.Fragment>
    <footer className="basic-footer">{props.children}</footer>
    <footer className="hidden-footer">{props.children}</footer>
  </React.Fragment>
);

export default Footer;
