import * as _ from "lodash";
import { FirestoreDatabaseService } from "../shared/FirestoreDatabase";
import { UserFirebaseService } from "./UserDB";
import { store } from "../redux/store/index";

const userService = new UserFirebaseService();

const TABLE_NAME = "/followers";

export class FollowersService extends FirestoreDatabaseService {
  constructor() {
    super(TABLE_NAME);
  }

  async follow(user) {
    const state = store.getState();
    const followerId = _.get(state, "userState.id");
    const userId = user?.id;

    if (!userId || !userId) {
      return `Something went wrong. Try again later.`;
    }

    const checkForSub = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "followerId",
        condition: "==",
        value: followerId,
      },
    ]);

    if (checkForSub === null) {
      const subscription = {
        userId: userId,
        followerId: followerId,
      };

      await this.add(subscription);

      return `You start following ${user.name}`;
    }

    return `You already following ${user.name}`;
  }

  async unfollow(user) {
    const state = store.getState();
    const followerId = _.get(state, "userState.id");
    const userId = user?.id;

    if (!userId || !followerId) {
      return `Something went wrong. Try again later.`;
    }

    const checkForSub = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "followerId",
        condition: "==",
        value: followerId,
      },
    ]);

    if (checkForSub !== null) {
      const mySub = _.head(checkForSub);
      await this.delete(mySub?.id);

      return `You stopped following ${user.name}`;
    }

    return `You already not following ${user.name}`;
  }

  async getMySubscriptions() {
    const state = store.getState();
    const followerId = _.get(state, "userState.id");

    if (!followerId) {
      return `Something went wrong. Try again later.`;
    }

    const mySubscriptions = await this.filterByField([
      {
        field: "followerId",
        condition: "==",
        value: followerId,
      },
    ]);

    if (mySubscriptions === null) {
      return [];
    }

    const subsWithUsers = this.assignUserDataToSub(mySubscriptions);
    return subsWithUsers;
  }

  async getMySubscribers() {
    const state = store.getState();
    const userId = _.get(state, "userState.id");

    if (!userId) {
      return `Something went wrong. Try again later.`;
    }

    const mySubscribers = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
    ]);

    if (mySubscribers === null) {
      return [];
    }

    const subsWithFollower = this.assignFollowerDataToSub(mySubscribers);
    return subsWithFollower;
  }

  async getMySubscribersEmails() {
    const state = store.getState();
    const userId = _.get(state, "userState.id");

    if (!userId) {
      return `Something went wrong. Try again later.`;
    }

    const mySubscribers = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
    ]);

    if (mySubscribers === null) {
      return [];
    }

    const subsEmails = this.assignFollowerEmailSub(mySubscribers);
    return subsEmails;
  }

  async assignUserDataToSub(subs) {
    const subsWithUsers = [];
    for (const sub of subs) {
      const user = await userService.getById(sub.userId);

      if (_.isEmpty(user)) {
        continue;
      }

      const subWithUser = { follower: user, ...sub };

      subsWithUsers.push(subWithUser);
    }

    return subsWithUsers;
  }

  async assignFollowerDataToSub(subs) {
    const subsWithFollowers = [];
    for (const sub of subs) {
      const follower = await userService.getById(sub.followerId);

      if (_.isEmpty(follower)) {
        continue;
      }

      const subWithFollower = { follower: follower, ...sub };

      subsWithFollowers.push(subWithFollower);
    }

    return subsWithFollowers;
  }

  async assignFollowerEmailSub(subs) {
    const followersEmails = [];
    for (const sub of subs) {
      const follower = await userService.getById(sub.followerId);

      if (_.isEmpty(follower)) {
        continue;
      }

      followersEmails.push(follower?.email);
    }

    return followersEmails;
  }

  async checkIsFollowing(userId) {
    const state = store.getState();
    const followerId = _.get(state, "userState.id");

    if (!userId || !followerId) {
      return false;
    }

    const checkForSub = await this.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "followerId",
        condition: "==",
        value: followerId,
      },
    ]);

    if (checkForSub === null) {
      return false;
    }

    return true;
  }
}
