import React from "react";
import { NavLink } from "react-router-dom";

import { IoMdCreate } from "react-icons/io";

import UserSmallAvatar from "../user-small-avatar";
import { HistoryHelper } from "../../helpers";

import HomeIcon from "../../shared/icons/home-icon.svg";
import NotificationsIcon from "../../shared/icons/notifications-icon.svg";
import HomeClickedIcon from "../../shared/icons/home-clicked-icon.svg";
import CoffeeIcon from "../../shared/icons/coffee-fill-white-icon.png";
import NotificationsClickedIcon from "../../shared/icons/notifications-clicked-icon.svg";
import "./DesktopNavBar.scss";
import { useLocalStorage } from "../../shared/LocalStorageHook";

const DesktopNavBar = ({
  openSignInPage,
  clickedIcon,
  setClickedIcon,
  userAvatarLink,
  setAppPagesStatus,
  invitations,
}) => {
  // eslint-disable-next-line
  const [localStorageCoffee, setLocalStorageCoffee] = useLocalStorage(
    "coffee",
    null
  );

  const navLinkIcon = (iconName, navName, navIcon, navClickedIcon) => {
    return (
      <div
        className={`nav-bar-element ${
          clickedIcon === iconName ? "active" : ""
        }`}
        onClick={() => onMenuItemClick(iconName)}
      >
        <div className="icon-container">
          {invitations.length !== 0 && navName === "Notifications" && (
            <div className="circle"></div>
          )}
          {clickedIcon === iconName ? navClickedIcon : navIcon}
        </div>
        <span
          className={
            clickedIcon === iconName
              ? "nav-bar-selected-text"
              : "nav-bar-default-text"
          }
        >
          {navName}
        </span>
      </div>
    );
  };

  const openAvailabilityPage = () => {
    setAppPagesStatus({ availabilityPageStatus: true });
  };

  const onMenuItemClick = (iconName) => {
    setClickedIcon(iconName);
    setLocalStorageCoffee(null);
    if (iconName === "home-icon") {
      HistoryHelper.redirectTo("/");
    }
  };

  const onClickAppLogo = () => {
    setClickedIcon("home-icon");
    setLocalStorageCoffee(null);
    HistoryHelper.redirectTo("/");
  };

  return (
    <React.Fragment>
      <div className="desktop-nav-bar-container">
        <div className="app-title" onClick={onClickAppLogo} to="/">
          CoffeeTalk
        </div>

        <div className="nav-bar-container">
          <div className="nav-bar">
            <div>
              {navLinkIcon(
                "home-icon",
                "Home",
                <img className="home-icon" src={HomeIcon} alt="nav-bar-home" />,
                <img
                  className="home-icon"
                  src={HomeClickedIcon}
                  alt="nav-bar-home"
                />
              )}
            </div>

            <NavLink onClick={openSignInPage} to="/notifications">
              {navLinkIcon(
                "notifications-icon",
                "Notifications",
                <img
                  className="notifications-icon"
                  src={NotificationsIcon}
                  alt="nav-bar-notifications"
                />,
                <img
                  className="notifications-icon"
                  src={NotificationsClickedIcon}
                  alt="nav-bar-notifications"
                />
              )}
            </NavLink>

            <NavLink onClick={openSignInPage} to="/user">
              <div
                className={`nav-bar-element ${
                  clickedIcon === null ? "active" : ""
                }`}
                onClick={() => setClickedIcon(null)}
              >
                <div className="icon-container">
                  <UserSmallAvatar
                    avatarClassName="desktop-nav-bar-user-icon"
                    userAvatarLink={userAvatarLink}
                  />
                </div>
                <span
                  className={
                    clickedIcon === null
                      ? "nav-bar-selected-text"
                      : "nav-bar-default-text"
                  }
                >
                  Profile
                </span>
              </div>
            </NavLink>
          </div>

          <div className="top-line"></div>

          <NavLink to="/new-talk">
            <div
              className="new-coffee-talk-button"
              onClick={() => setClickedIcon("coffee-icon")}
            >
              <img src={CoffeeIcon} alt="nav-bar-coffee" />
              <span className="coffee-talk-text">Start a Talk</span>
            </div>
          </NavLink>

          <div className="lower-line"></div>

          <p onClick={openAvailabilityPage} className="edit-availability">
            <IoMdCreate /> Filter results
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesktopNavBar;
