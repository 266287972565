import * as constants from '../constants';
import { FormatHelper } from './../../helpers/Formater';

const initialState = {
  ...FormatHelper.getEmptyUserInfoForRedux(),
};

const actionHandlers = {
  [constants.SET_USER_INFO](state, user) {
    return { ...state, ...user };
  },
  [constants.SET_EMPTY_USER_INFO]() {
    return initialState;
  },
};

export default function userState(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
