import firebase from 'firebase/app';
import 'firebase/storage';

import Singleton from '../shared/singleton';
import { firebaseConfig } from '../config/firebase';

export class FireBase extends Singleton {
  constructor() {
    super();
    this.firebaseApp = firebase.initializeApp(firebaseConfig);
    this.firebaseStorage = firebase.storage();
  }

  getFirebaseApp() {
    return this.firebaseApp;
  }

  getFirebaseStorage() {
    return this.firebaseStorage;
  }

  static isMessagingSupported() {
    if (firebase.messaging.isSupported()) {
      return true;
    }
    return false;
  }
}
