import React, { useState } from 'react';

import { MapHelper } from '../../helpers';
import SearchBox from '../map/SearchBox';
import MapContainer from '../../containers/Map';
import Pointer from '../Marker';

const AVAILABLE_COFFEE_NUMBER = 5;

const MapSearch = props => {
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState({});
  const [mapApi, setMapApi] = useState({});
  const [placesFromNearby, setPlacesFromNearby] = useState([]);
  const [foundedPlaces, setFoundedPlaces] = useState(props.coffeeList || []);

  const onMarkerClick = (props, marker) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
    }

    setActiveMarker(marker);
    setSelectedPlace(props);
    setShowingInfoWindow(true);
  };

  const onInfoWindowClose = () => {
    setShowingInfoWindow(false);
  };

  const fetchPlaces = async (mapProps, map) => {
    if (!props.isSearchable) {
      return;
    }

    const params = {
      location: props.currentLocation,
      radius: '1000',
      types: ['cafe'],
    };
    const allCafes = await MapHelper.getPlaces(mapProps, map, params);
    props.setCoffee(allCafes.slice(0, AVAILABLE_COFFEE_NUMBER));
    setPlacesFromNearby(allCafes.slice(0, AVAILABLE_COFFEE_NUMBER));
  };

  const addPlace = places => {
    setFoundedPlaces(places);
    props.setCoffee(state => {
      const filteredPlaces = places.filter(
        place => !state.find(item => item.place_id === place.place_id)
      );
      return [...state, ...filteredPlaces];
    });
  };

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
    }
  };

  const apiHasLoaded = (mapProps, map) => {
    const { google } = mapProps;
    setMapInstance(map);
    setMapApi(google.maps);
    setMapApiLoaded(true);
    fetchPlaces(mapProps, map);
  };

  const renderPointers = pointers => {
    return (
      pointers.length &&
      pointers.map(place => (
        <Pointer
          key={place.place_id}
          text={place.name}
          onClick={onMarkerClick}
          position={{
            lat: place.lat || place.geometry.location.lat(),
            lng: place.lng || place.geometry.location.lng(),
          }}
        />
      ))
    );
  };

  return (
    <React.Fragment>
      {mapApiLoaded && props.isSearchable && (
        <SearchBox map={mapInstance} mapApi={mapApi} addPlace={addPlace} />
      )}
      <MapContainer
        apiHasLoaded={apiHasLoaded}
        onMapClicked={onMapClicked}
        activeMarker={activeMarker}
        onInfoWindowClose={onInfoWindowClose}
        showingInfoWindow={showingInfoWindow}
        selectedPlace={selectedPlace}
        renderPointers={renderPointers}
        placesFromNearby={placesFromNearby}
        foundedPlaces={foundedPlaces}
      />
    </React.Fragment>
  );
};

export default MapSearch;
