import * as constants from '../constants';
import { getFakeCards } from '../../helpers/FakeCards';

const initialState = {
  coffees: [],
  fakeCards: getFakeCards(),
};

const actionHandlers = {
  [constants.SET_COFFEES](state, coffees) {
    return { coffees: coffees };
  },
  [constants.SET_FAKE_CARDS](state, fakeCoffees) {
    return { ...state, fakeCoffees };
  },
};

export default function coffeeState(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
