import React, { useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import SettingMenu from "../setting-modal";
import SettingInput from "../setting-input";

import "./Setting.scss";
import * as _ from "lodash";

const Setting = ({
  title,
  buttonText,
  content = "",
  placeholder,
  type,
  updateUserInfo = () => {},
}) => {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isInputOpened, setInputOpened] = useState(false);
  const [inputValue, setInputValue] = useState(content);

  const handleOutsideClickSettingsMenu = (e) => {
    if (e.toElement.id === "dots") {
      return;
    }

    if (isMenuOpened) {
      setMenuOpened(false);
      return;
    }
    setMenuOpened(true);
  };

  const handleSettingsMenu = (e) => {
    if (isMenuOpened) {
      setMenuOpened(false);
      return;
    }

    setMenuOpened(true);
    setInputValue(content);
  };

  const onEdit = () => {
    setMenuOpened(false);
    setInputOpened(true);
  };

  const onDelete = async () => {
    await updateUserInfo(title.toLowerCase(), "");
    setMenuOpened(false);
    setInputValue("");
    setInputOpened(false);
  };

  const onInputCancel = () => {
    setInputOpened(false);
  };

  const onInputSave = () => {
    if (type === "number" && inputValue < 16) {
      return;
    }

    updateUserInfo(title.toLowerCase(), inputValue);
    setInputOpened(false);
  };

  const onChangeInput = (value) => {
    if (type === "number") {
      const roundedValue = parseInt(value);
      setInputValue(roundedValue);
      return;
    }
    setInputValue(value);
  };

  const addButton = () => {
    if (!_.isEmpty(`${content}`)) {
      return null;
    }

    return (
      <button
        className="add-button"
        onClick={() => setInputOpened(!isInputOpened)}
      >
        <AiOutlinePlusCircle className="plus-icon" />
        <span>{buttonText}</span>
      </button>
    );
  };

  return (
    <div className="setting-container">
      <span className="title">{title}</span>
      {addButton()}
      {!_.isEmpty(`${content}`) && (
        <React.Fragment>
          <div className="setting-data">
            <span>{content}</span>
            <button
              className="edit-setting-button"
              onClick={(e) => {
                handleSettingsMenu(e);
              }}
            >
              <HiOutlineDotsHorizontal id="dots" className="dots" />
            </button>
          </div>
          <div className="settings-menu-container">
            {isMenuOpened && (
              <SettingMenu
                handleOutsideClickSettingsMenu={handleOutsideClickSettingsMenu}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            )}
          </div>
        </React.Fragment>
      )}
      {isInputOpened && (
        <SettingInput
          placeholder={placeholder}
          inputValue={inputValue}
          setInputValue={onChangeInput}
          type={type}
          onInputCancel={onInputCancel}
          onInputSave={onInputSave}
        />
      )}
    </div>
  );
};

export default Setting;
