import React, { useRef } from "react";

import useOutsideClick from "../../shared/OutsideClick";
import CloseButton from "../../shared/icons/edit-pop-up-close-button.svg";

import { FollowersService } from "../../services/Followers";

import "./FollowersList.scss";
import CardList from "./CardList";

const followersService = new FollowersService();

const FollowersList = ({
  followers,
  following,
  openedList,
  setOpenedList,
  removeUserFromFollowingList,
}) => {
  const popUpRef = useRef(null);

  const closePopUp = () => {
    setOpenedList(null);
  };

  useOutsideClick(popUpRef, closePopUp);

  const unfollow = async (user) => {
    await followersService.unfollow(user.follower);
    removeUserFromFollowingList(user.follower);
  };

  return (
    <div className="user-avatar-edit-container">
      <div className="list-container" ref={popUpRef}>
        <div className="list-title">
          <h2>{openedList === "followers" ? "Followers" : "Following"}</h2>
          <button onClick={closePopUp}>
            <img src={CloseButton} alt="close" />
          </button>
        </div>
        <CardList
          unfollowLocked={openedList === "followers"}
          followers={openedList === "followers" ? followers : following}
          unfollow={unfollow}
          removeUserFromFollowingList={removeUserFromFollowingList}
        />
      </div>
    </div>
  );
};

export default FollowersList;
