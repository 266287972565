import React, { useEffect, useState } from "react";
import { assign } from "lodash";

import { StripeService } from "../../services/Stripe";
import { UserFirebaseService } from "../../services";
import { useLocalStorage } from "../../shared/LocalStorageHook";
import { FaStripe } from "react-icons/fa";

import "./UserPage.scss";

function StripeConnect({ setUserInfo, user, setSignInPageStatus = () => {} }) {
  const userFirebaseService = new UserFirebaseService();
  const [stripeStatus, setStripeStatus] = useState("connect");
  const [stripeAccountLink, setStripeAccountLink] = useState("");
  const [, setLocalStorageUser] = useLocalStorage("user", null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user.stripeId) {
      return;
    }

    setLoading(true);
    StripeService.getAccountData(user.stripeId)
      .then((res) => {
        if(res.statusCode === 400)
          throw new Error();

        if (!Boolean(res.charges_enabled)) {
          setStripeStatus("continue");
        } else {
          setStripeStatus("done");
        }
        return StripeService.getAccountLink(user.stripeId);
      })
      .then((res) => {
        setStripeAccountLink(res.url);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line

  const connectStripeAccount = async (e) => {
    e.preventDefault();

    if (!user.id) {
      setSignInPageStatus(true);
      return;
    }

    setLoading(true);
    const account = await StripeService.createStripeAccount();

    if (account) {
      const updatedUser = assign({}, user, {
        stripeId: account.accountId,
      });

      await userFirebaseService.updateUser(updatedUser);
      setUserInfo(updatedUser);
      setLocalStorageUser(updatedUser);

      setLoading(false);
      window.location.assign(account.url);
    }
  };

  const goToStripe = (e) => {
    e.preventDefault();

    if (!user.id) {
      setSignInPageStatus(true);
      return;
    }

    window.location.assign(stripeAccountLink);
  };

  const getContent = () => {
    switch (stripeStatus) {
      case "continue": {
        return (
          <button className="stripe-button" onClick={goToStripe}>
            Complete registration
            <FaStripe className="stripe-icon"/>
          </button>
        );
      }

      case "done": {
        return (
          <button className="stripe-button" onClick={goToStripe}>
            Go to dashboard
            <FaStripe className="stripe-icon"/>
          </button>
        );
      }

      default:
        return (
          <button className="stripe-button" onClick={connectStripeAccount}>
            Connect with
            <FaStripe className="stripe-icon"/>
          </button>
        );
    }
  };

  return (
    <div className="stripe-connect">
      {loading ? <div className="loader"></div> : getContent()}
    </div>
  );
}

export default StripeConnect;
