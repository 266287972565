import { connect } from "react-redux";
import DetailedCoffeeCard from "./../components/detailed-coffee-card";

export function mapStateToProps({ userState }) {
  return {
    userState: userState,
  };
}

export default connect(mapStateToProps)(DetailedCoffeeCard);
