import React, { useState, useEffect } from "react";
import { RelationControlInvitationsService } from "./../../services/RelationControl";

import InvitationsList from "../invitations-list";

import Spinner from "../spinner";

import "./InvitationPage.scss";

const InvitationPage = ({ setTitle }) => {
  setTitle("Notifications");

  const [invitations, setInvitations] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    RelationControlInvitationsService.getInvitations()
      .then((res) => {
        setInvitations(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const userInvitations = () => {
    return (
      <React.Fragment>
        <div className="invitations-list-title">
          {invitations?.length === 0 ? (
            <p>So far there's no notification</p>
          ) : (
            <InvitationsList
              className="invitations-list"
              invitations={invitations}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="invitations-page-container">
      <p className="notifications-title">Notifications</p>
      {isLoading ? (
        <Spinner
          loaderContainerName="tab-loader-container"
          blockName="invitations-page-container"
        />
      ) : (
        <div>{userInvitations()}</div>
      )}
    </div>
  );
};

export default InvitationPage;
