import React, { useState } from "react";
import UserSmallAvatar from "../user-small-avatar";
import UserAvatarEdit from "../user-avatar-edit";

import "./LiteCoffeeCard.scss";
import * as _ from "lodash";

const LiteCoffeeCard = ({ coffee, user }) => {
  const [isUserAvatarOpened, setUserAvatarOpened] = useState(false);

  const onClickUserAvatar = () => {
    setUserAvatarOpened(true);
  };

  return (
    <div className="lite-coffee-card">
      {isUserAvatarOpened && (
        <UserAvatarEdit
          setUserAvatarOpened={setUserAvatarOpened}
          userAvatarLink={_.get(user, "photoURL")}
          user={user}
        />
      )}
      <div className="coffee-card-user-avatar">
        <div
          onClick={(event) => {
            event.stopPropagation();
            onClickUserAvatar();
          }}
        >
          <UserSmallAvatar
            avatarClassName="coffee-card-user-img"
            userAvatarLink={_.get(user, "photoURL")}
          />
        </div>
        <div className="coffee-card-user-name">{_.get(user, "name")}</div>
      </div>
      <div className="coffee-card-data">
        <React.Fragment>
          <p className="coffee-card-title">{_.get(coffee, "description")}</p>
        </React.Fragment>

        {/* <p className="coffee-card-conversation-time">
          {_.get(coffee, "duration_time")} min conversation
        </p> */}
      </div>
    </div>
  );
};

export default LiteCoffeeCard;
