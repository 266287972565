import React from "react";
import "./TermOfUse.scss";
import {APP_EMAIL_DOMAIN} from "../../shared/constants";

const TermOfUse = () => {
  return (
    <div className="term-of-use">
      <div className="app-title-container">
        <a className="app-title" href="/">
          CoffeeTalk
        </a>
      </div>
      <h1>TERMS OF USE</h1>
      <h1>Last Updated: Nov 27, 2023</h1>

      <ol start="1.0">
        <li>
          <p><b>Consent.</b> If you access CoffeeTalk you indicate your consent to these Terms of Use even if you do not create an account. In addition, CoffeeTalk’s Privacy Policy is incorporated into the present document by way of reference. You should not use CoffeeTalk unless you accept these Terms of Use in their entirety -- including the CoffeeTalk Privacy Policy.
          </p>
        </li>

        <li>
          <p><b>Company.</b> These Terms of Use establish the legal relationship between you and CoffeeMe LLC (the Company which created CoffeeTalk).
          </p>
        </li>

        <li>
          <p><b>Acceptance.</b> Using or accessing CoffeeTalk means you accept these Terms of Use as well as any and all other terms and conditions they contain or refer to. You further agree that these Terms of Use only exist in an electronic form. Moreover, CoffeeTalk reserves the right to modify these Terms provided that a suitable notice is published on CoffeeTalk’s website or within the CoffeeTalk application.
          </p>
        </li>

        <li>
          <p><b>Eligibility.</b> You must not use CoffeeTalk if you are less than 18 years old. You must also refrain from using CoffeeTalk if the laws of your country and/or territory prohibit it. Moreover, your use of the service means you are legally entitled and/or permitted to do so. It further means that you possess no criminal record in general and no record of sex offenses in particular.
          </p>
        </li>

        <li>
          <p><b>Account Creation.</b> You must login to CoffeeTalk and allow us to access and use some of the information contained therein in line with our Privacy Policy.
          </p>
        </li>

        <li>
          <p><b>Term and Termination.</b> These Terms of Use will retain their validity until you delete your CoffeeTalk account. Alternately, Company may deactivate or freeze your account at its discretion. Any deactivation on Company’s part does not entitle you to any monetary compensations or refunds. Moreover, Company’s legal advisors may rule that some or all of these Terms of Use shall survive after you delete your CoffeeTalk account and/or after it is deactivated at Company’s discretion. By accepting these Terms of Use, you indicate your understanding that such rulings are binding and final.
          </p>
        </li>

        <li>
          <p><b>Non-commercial Use.</b> You may not use CoffeeTalk or any of its content (such as user-generated content, graphics, audio or video materials or source code) for commercial purposes unless you receive Company’s explicit written permission to do so. Company reserves the right to take whatever actions it deems necessary, legal or otherwise, against users who do so despite the above.
          </p>
        </li>

        <li>
          <p><b>Account Security.</b> You alone are responsible for the security of your login credentials (username and password). You must further notify Company of any breach of this security or of any unauthorized use of these credentials. If you are accessing CoffeeTalk from a public computer, you must logout at the end of each session.
          </p>
        </li>

        <li>
          <p><b>Inter-User Interactions. </b> NOBODY EXCEPT YOURSELF IS LIABLE AND/OR RESPONSIBLE FOR THE NATURE OF AND/OR THE RESULTS OF YOUR RELATIONS AND/OR INTERACTIONS WITH OTHER USERS. BY USING THE SERVICE, YOU UNDERSTAND THAT COMPANY WILL ONLY VET USERS AT ITS SOLE DISCRETION AND FOR ITS OWN PURPOSES. IT IS NOT REQUIRED TO NOTIFY YOU OF SUCH VETTING OR OF THE METHODS THEREOF
          </p>
        </li>

        <li>
          <p><b>Proprietary Rights.</b> You must not reproduce or otherwise change any of Company’s intellectual property included in or accessible through CoffeeTalk and belonging to Company's licensors unless you have Company's prior written consent to do so. This also applies to intellectual property not owned by Company and/or its licensors but included in or accessible through CoffeeTalk.
          </p>
        </li>

        <li>
          <p><b>User Postings.</b> You are the only person liable and/or responsible for any and all forms of the content you make available by whatever means on CoffeeTalk, whether private or public. You may not make any hateful, bigoted, offensive, inaccurate, incomplete, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive or illegal material, or any material which infringes another's rights (including, but not limited to, intellectual property rights, privacy and publicity) available on CoffeeTalk. You further understand that you must provide accurate and truthful personal information upon creation of your CoffeeTalk account, and – moreover - that you have obtained the necessary permissions, if any, required for your use of CoffeeTalk and that you are legally entitled and/or capable of granting Company the licenses listed below.
          </p>
          <p> Company may, at its sole discretion, monitor, review, modify, or delete any content you make available on CoffeeTalk without notice or explanation. Any such actions on Company's part are final and unappealable.
          </p>
          <p> Making content available on CoffeeTalk means granting Company, and any of its affiliates, licensees, and successors, an irrevocable, perpetual, non-exclusive, transferable, sub-licensable, fully paid-up, worldwide right and license to (i) modify the content in any way Company sees fit, (ii) prepare derivatives or integrate the content with other content, and (iii) sublicense the content in any media which has existed, presently exists or may exist in the future. Making the content available on CoffeeTalk further means that you assure CoffeeTalk that Company's use of the content will not adversely affect the statutory or other rights of any third party.

          </p>
          <p> Any content made available on CoffeeTalk may not be involved in the dissemination of prohibited transmissions (spam, phishing, trolling etc.). Moreover, it must not promote false or misleading information, or be associated with any conduct which may be construed as: fraud, defamatory, libelous or offensive.

          </p>
          <p> Content made available on CoffeeTalk may not contain or link to any hidden or restricted content of any shape or form.

          </p>
          <p> Content made available on CoffeeTalk may not be construed as displaying sexual or any other kind of exploitation, nor may it be construed as soliciting information from minors.

          </p>
          <p> Content made available on CoffeeTalk must not provide guidance towards illegal activities involving (illegal) drugs, weapons, malware etc. Furthermore, content may not constitute malware of any kind in and of itself.

          </p>
          <p> Content made available on CoffeeTalk must not fraudulently represent itself as being affiliated, connected or associated with another party, whether natural or legal. Furthermore, it must not include, allude to or link to information which the information provider has no legal right to make available

          </p>

          <p> Content made available on CoffeeTalk must not bring about an adverse effect on any user’s ability to use the site in any manner whatsoever.
          </p>

          <p> Content made available on CoffeeTalk may not solicit or disseminate the sensitive or personally identifying information – including passwords – of other users without their express written permission.

          </p>
          <p> Content made available on CoffeeTalk may not relate to or involve commercial and/or sales based activities such as advertisements or contests without Company’s prior written consent.

          </p>
          <p> Any content made available on CoffeeTalk is public by default, and may be viewed by both registered users of CoffeeTalk and non-registered visitors.

          </p>
          <p> Company may – at its sole discretion – investigate and/or take whatever measures it deems necessary – legal or otherwise – with regard to offending communications as described above. This includes, but is not limited, to the suspension and/or termination of the offending communicators’ CoffeeTalk accounts. Furthermore, Company may also access, preserve, or disclose account information and/or user content if required to do so by a competent legal or other authority or if - at its sole discretion - it believes such actions are necessary to protect Company’s core interests

          </p>
        </li>

        <li>
          <p><b>Prohibited Activities.</b> Company may – at its own discretion – suspend or terminate a user account if it believes that the user has engaged in what Company – at it sole discretion – considers to be inappropriate conduct, including, but not limited to, unlawful impersonation, solicitation, any conduct alluded or referred to in section 12.0 above, stalking, implying that statements have been endorsed by Company when this is not the case, accessing CoffeeTalk where it is unlawful to do so, using CoffeeTalk to conceal illegally gained funds or products, accessing CoffeeTalk using an unauthorized indexer, spider, bot etc., scraping, framing, illegal access to or interference with CoffeeTalk’s server infrastructure, using CoffeeTalk for illegal or unauthorized redirections, and in any way tampering with and/or modifying any part of CoffeeTalk’s organic source code and/or any code incorporated and/or referenced therein.

          </p>
        </li>

        <li>
          <p><b>Company Employees.</b> Company may – at its sole discretion and in a final and unappealable manner – terminate the accounts of any CoffeeTalk user who – in Company’s opinion – mistreats, intimidates or abuses said employees.

          </p>
        </li>

        <li>
          <p><b>In-App Purchases.</b> Company may – from time to time - offer additional premium products or services on the various application platforms once available (iOS App Store, Google Play, etc.), for which you will be charged by the application platform in question. If these premium products or services include a trial period and you wish to terminate your use of them, you must do so before the end of the trial, or you shall be charged until you follow the application platform’s procedure for cancelling recurring payments. In any case, you agree that any such purchases are subject to the application platform’s terms and conditions over and above the relevant provisions of the present document and/or any specific terms and conditions that may be presented to you upon purchase.

          </p>
        </li>

        <li>
          <p><b>Changes in CoffeeTalk.</b> Company may at any time, and at its sole discretion, modify, suspend and/or discontinue CoffeeTalk and shall endeavor to notify users of this fact. However, this notification shall be done as a courtesy, and should not been seen as a legal obligation on Company’s part. You further agree that Company shall in no way, shape or form be liable for any inconvenience such changes may cause you.

          </p>
        </li>

        <li>
          <p><b>Copyright Policy.</b> CoffeeTalk users may not post, distribute or otherwise disseminate or reproduce
            any item protected by intellectual property legislation without the express written permission of said
            item’s rights holder. Rights holders who wish to report a copyright infringement must provide Company with
            the following information: an electronic or physical signature of the rights holder’s authorized
            representative(s); a description of the infringing items; the location of the infringing item on CoffeeTalk
            (be as specific as possible); full contact details; proofs of identity and authority for the rights holder
            and its representatives; legally valid proof of infringement. Please send this information via email to{" "}
            <a href={`mailto:help@${APP_EMAIL_DOMAIN}`}> help@{APP_EMAIL_DOMAIN}. </a>{" "} The accounts of repeat
            infringers shall be terminated by Company in a final and unappealable manner.
          </p>
        </li>

        <li>
          <p><b>Disclaimers.</b>
            <ol type="a">
              <li> Neither Company nor its affiliates and/or third parties shall bear any direct or indirect liability for any losses or damages, including – but not limited to – death or personal injury – caused as a result of the use of CoffeeTalk or any of its content. This will apply regardless of the hardware or software used by Company or CoffeeTalk’s users, the time or timeliness or any actions on Company’s part, CoffeeTalk users’ conduct, the quality – or lack thereof – of any action on Company’s part, and any technical failure or malfunction on either Company or CoffeeTalk users’ part. INSOFAR AS PERMITTED BY ANY LAWS THAT MAY APPLY, COMPANY IS TO BE UNDERSTOOD AS PROVIDING COFFEETALK ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH NO WARRANTIES OF ANY KIND (EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) APPLYING TO EITHER THE SERVICE (AND ALL CONTENT THEREIN). THIS PROVISION SHOULD ALSO BE UNDERSTOOD AS INCORPORATING ANY IMPLIED WARRANTIES, SUCH AS THOSE OF SATISFACTORY QUALITY, MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE, AND/OR NON-INFRINGEMENT. COMPANY MAKES NO ASSURANCES WITH REGARD TO THE UNINTERRUPTIBILITY OF PROVIDING COFFEETALK, OR TO ITS ERROR-FREE NATURE. IT IS TO BE FURTHER UNDERSTOOD THAT COMPANY MAKES NO ASSURANCES THAT ANY DEFECTS AND/OR ERRORS IN COFFEETALK, WHETHER FOUND BY A THIRD PARTY OR DETECTED BY COMPANY SHALL BE CORRECTED IN WHOLE OR IN PART.

              </li>
              <li> YOU ASSUME RESPONSIBILITY FOR ANY RISKS YOU MAY ASSUME AS A RESULT OF ACCESSING OR DOWNLOADING ANY MATERIALS FROM COFFEETALK. BY USING COFFEETALK, YOU UNDERSTAND THAT YOU MAY NOT TAKE ANY ACTION AGAINST COMPANY ON ACCOUNT OF ANY DAMAGES YOU OR YOUR EQUIPMENT MAY SUFFER IN THE COURSE OF OR AS A RESULT OF USING COFFEETALK. YOU MAY NOT USE COFFEETALK IF YOU DO NOT ACCEPT THIS PROVISION.

              </li>
              <li> Company may occasionally make third party information available on CoffeeTalk. All such content is the responsibility of its respective authors and/or publishers. Company and/or its affiliates and partners should not be seen as making any assurances with regard to any such information, nor should it be seen as endorsing or accepting responsibility for any adverse effects that may result from the use of such information by CoffeeTalk registered users or visitors.

              </li>
              <li> By using CoffeeTalk, you understand and accept that Company shall bear no liability for any harms or damages you may suffer as a result of using CoffeeTalk.

              </li>
            </ol>
          </p>
        </li>

        <li>
          <p><b>Links.</b> CoffeeTalk may contain links to other resources. By using CoffeeTalk, you agree that Company shall bear no responsibility for the availability or content of these linked resources and shall furthermore bear no liability for any adverse events which may occur once these links are followed.

          </p>
        </li>

        <li>
          <p><b>Limitation of Liability.</b> COMPANY AND ITS AFFILIATES AND/OR PARTNERS SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY KIND OF DAMAGE OR HARM INCURRED BY THE USE OF COFFEETALK, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY – HOWEVER REMOTE – THAT SUCH DAMAGE OR HARM MAY OCCUR.

          </p>
        </li>

        <li>
          <p><b>Arbitration and Governing Law.</b>
          </p>
          <ol type="a">
            <li> Any dispute or claim arising from the present document shall be resolved by a binding arbitration procedure. The one exception is that either party has the right to bring an individual claim against the other in a small-claims court of competent jurisdiction. However, representative or class action procedures of any shape or form are explicitly prohibited.

            </li>
            <li> Using CoffeeTalk means you forego your right to apply for court proceedings (apart from those of a small-claims court) should you have any claims towards Company. Instead, you agree to a binding arbitration by a neutral arbitrator whose ruling is court-enforceable (except in very limited circumstances). In case this is unclear, it should be noted that an arbitrator has the power to grant almost any relief a court is able to grant under similar circumstances.

            </li>
            <li> Any proceedings necessary for enforcing an arbitration ruling may take place in any competent court. If – for whatever reason – the arbitration ruling is found to be unenforceable, any litigation against Company may only take place in court. By using CoffeeTalk, you agree to the jurisdiction of this court and further understand that this agreement on your part shall be seen as irrevocable.

            </li>
            <li> The present document, and any claims you raise against Company, shall be governed by the laws of Israel.

            </li>
            <li> For members residing in the EU or European Economic Area or elsewhere where the arbitration agreement is prohibited by law, the laws of Israel will apply to any disputes arising from this agreement or the service

            </li>
          </ol>
        </li>

        <li>
          <p><b>Indemnification.</b> Using CoffeeTalk means that you indemnify Company and any of its employees, affiliates, agents, or partners with regard to any claim (including attorney’s fees) related in any way to your access or use of the service or your breach of this agreement.

          </p>
        </li>

        <li>
          <p><b>Notices.</b> Company may at its sole discretion publish notices via mass emailings or via any other method it deems fit. You understand that you may not be able to receive these notices if you access CoffeeTalk in an unauthorized manner.

          </p>
        </li>

        <li>
          <p><b>Entire Agreement.</b> By using CoffeeTalk, you agree that the present document, as well as all associated documents (Privacy Policy etc.) shall be collectively perceived as a single agreement between you and Company. This means that if any provision of either the present or any associated documents is invalidated, the remaining provisions still remain in force. Furthermore, you agree that no provision of either the present or associated documents shall be waived even if Company does not enforce it. Moreover, you agree that your CoffeeTalk account is non-transferable, and that any contents thereof shall be considered terminated upon your demise. Finally, the aforementioned collective agreement does not establish any relationship between you and Company that would legally bind the latter or allow you to represent it. </p>
        </li>
        <li>
          <p><b>Buyers</b> are users who pay to join talks on CoffeeTalk.
          </p>
        </li>
        <li>
          <p><b>Sellers</b> are users who post talks (start a talk) on CoffeeTalk and request payment from other users in order to join their talk.

          </p>
        </li>
        <li>
          <p><b>Payment Terms.</b> By making or receiving payments through the CoffeeTalk site: wecoffeetalk.com or app (once applicable), you accept and agree, on behalf of yourself or on behalf of any other entity (if applicable), to be bound by these Payment Terms.

          </p>
        </li>
        <li>
          <p><b>Payment Services Provider(s)</b> are service providers (such as Stripe) that provide payment services to Buyers and Sellers in connection with the CoffeeTalk platform, including the collection of funds from Buyers, remittance and withdrawal of funds to Sellers and currency exchange services in connection with payments and withdrawals in local currencies (may entail conversion fees). In order to be a Seller, you must have an account with at least one of CoffeeTalk's Payment Service Providers.
          </p>
        </li>
        <li>
          <p><b>Receiving Payments.</b> Each time Sellers successfully complete a talk, they will be entitled to 85% of the amount that Buyers paid minus Payment Services Providers fees. CoffeeTalk accredits Sellers within 7 business days after the talk is completed.
          </p>
        </li>
        <li>
          <p><b>Taxes.</b> Sellers are responsible for paying any direct or indirect taxes, including income tax, VAT and GST. CoffeeTalk may be required by applicable laws to charge users with indirect taxes (such as Sales Tax, VAT or GST) or to withhold taxes. This amount is in addition to the payment made by the Buyer. Any amount CoffeeTalk will be required by applicable law to withhold will be deducted from the Seller’s revenue.
          </p>
        </li>
        <li>
          <p> Seller appoints CoffeeTalk as Seller’s limited authorized payment collection agent solely for the purpose of accepting payments (via its Payment Services Provider) from Buyer, and remitting those payments to Seller. If CoffeeTalk (via Payment Services Provider) does not remit any such amounts to Seller, the Seller will have recourse only against CoffeeTalk and not the Buyer directly.

          </p>
        </li>
        <li>
          <p> Sellers may not offer Buyers (and Buyer may not offer Sellers) to make payment using any method other than
            through the CoffeeTalk site or app (once applicable). In case you have been asked to use an alternative
            payment method, please report it immediately to:{" "} <a
              href={`mailto:help@${APP_EMAIL_DOMAIN}`}> help@{APP_EMAIL_DOMAIN}. </a>{" "}
          </p>
        </li>
        <li>
          <p> You agree to receive invoices and/or payment receipts from CoffeeTalk.
          </p>
        </li>

        <li>
          <p><b>Fundraiser.</b> Users who post talks (start a talk) on CoffeeTalk and are doing so to raise money for a cause of their interest, agree that all money raised, besides CoffeeTalk’s 15% fee and Payment Services Providers fees, will go towards the cause they chose. Users making donations also agree that that all money donated, besides CoffeeTalk’s 15% fee and Payment Services Providers fees, will go towards the cause. CoffeeTalk accredits the cause chosen within 7 business days after the talk is completed.
          </p>
        </li>

        <li>
          <p><b>Cancelations and refunds.</b> If the Seller cancels or does not show up (for any reason), the funds paid
            will be refunded to the Buyer within 7 business days. In any other situation, funds will not be refunded to
            the Buyer (for example, Buyer did not show up for the Seller's talk).
          </p>
        </li>

        <li>
          <p><b>Amendments.</b> The present document may be amended by Company at its sole discretion and at any time.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default TermOfUse;
