import React, { useEffect } from "react";
import DatePicker from "react-mobile-datepicker";
import Popover from "@material-ui/core/Popover";
import Place from "../../../shared/icons/place.png";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Input, InputAdornment, IconButton, Grid } from "@material-ui/core";
import Watches from "../../../shared/icons/watches.svg";
import { MeetingTypes } from "../../../shared/MeetingTypes";
import Switcher from "../../switcher";
import { PaymentTypes } from "../../../shared/PaymentTypes";

const ShopTab = ({
  setOpenSearch,
  coffees,
  formValue,
  setFormValue,
  daysRange,
  coffeeTimeFrom,
  handleDateChange,
  invalidTime,
  handleClick,
  getTimeIn12hFormat,
  pickerOpen,
  anchorEl,
  handleClose,
  onSelectTime,
  dateConfig,
  getPickerValue,
  errors,
  setCoffee,
}) => {
  useEffect(() => {
    if (
      ![MeetingTypes.COFFEE_GROUP, MeetingTypes.COFFEE_ONE_BY_ONE].includes(
        formValue.type
      )
    ) {
      setFormValue({ ...formValue, type: MeetingTypes.COFFEE_GROUP });
    }
  }, [formValue, setFormValue]);

  const onGroupSwitcherChange = (e) => {
    if (e.target.checked) {
      setFormValue({ ...formValue, type: MeetingTypes.COFFEE_GROUP, payment_type: PaymentTypes.PAID });
      return;
    }
    setFormValue({ ...formValue, type: MeetingTypes.COFFEE_ONE_BY_ONE, payment_type: PaymentTypes.PAID });
  };

  return (
    <React.Fragment>
      <div className="time-selector">
        <Grid
          container
          direction="column"
          justify="space-between"
          className="swith-container coffee"
          alignItems="center"
        >
          <Grid container direction="row" justify="space-between">
            <div>Group talk</div>
            <Switcher
              className="switcher"
              checked={MeetingTypes.COFFEE_GROUP === formValue.type}
              onChange={onGroupSwitcherChange}
            />
          </Grid>
        </Grid>

        {coffees.length > 0 &&
          coffees.map((coffee, index) => {
            return (
              <div key={index} className="coffee-to-select">
                <div className="coffee-text-container">
                  <div className="coffee-name-container">
                    <p className="coffee-index">{index + 1}.</p>
                    <p className="coffee-name">{coffee.name}</p>
                  </div>
                  <p className="coffee-address">{coffee.vicinity}</p>
                </div>
              </div>
            );
          })}
        <div
          className="find-and-select-text"
          onClick={() => setOpenSearch(true)}
        >
          <img className="place-icon" src={Place} alt="place" />
          Select{" "}
          {formValue.type === MeetingTypes.COFFEE_GROUP
            ? "1 option"
            : "3 options"}
          &nbsp;to meet at
        </div>
        {errors["coffees"] && (
          <div className="validation">{errors["coffees"]}</div>
        )}

        <p className="bold-text simple">When do you want to meet?</p>

        <div className="zoom-date-picker">
          <Grid className="zoom-date" container direction="column">
            <span className="name">Date:</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                shouldDisableDate={daysRange}
                disableToolbar
                variant="inline"
                format="MMMM dd, yyyy"
                margin="normal"
                id="date-picker-inline"
                value={coffeeTimeFrom * 1000}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid container direction="column">
            <span className="name">Start Time:</span>
            <div className="search-container">
              <div id={invalidTime ? "anim-error" : "error"}>
                <Input
                  onClick={(e) => handleClick(e, "from")}
                  type={"text"}
                  className={errors["time"] && "error"}
                  value={getTimeIn12hFormat(coffeeTimeFrom)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <img src={Watches} alt="close" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            {errors["time"] && (
              <div className="validation">{errors["time"]}</div>
            )}
          </Grid>
        </div>
      </div>

      <Popover
        open={pickerOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: { width: "274px", height: "200px", marginTop: "-9px" },
        }}
      >
        <DatePicker
          value={getPickerValue()}
          isOpen={true}
          theme="ios"
          isPopup={false}
          showHeader={true}
          showFooter={true}
          dateConfig={dateConfig}
          onChange={onSelectTime}
        />
      </Popover>
    </React.Fragment>
  );
};

export default ShopTab;
