import React from 'react';
import UserSmallAvatar from '../user-small-avatar';
import Verifications from '../verifications';

import './UserShortInfo.scss';
import * as _ from 'lodash';

const DesktopUserShortInfo = ({ user }) => {
  return (
    <React.Fragment>
      <div className="interlocutor-info">
        <UserSmallAvatar
          avatarClassName="user-img"
          userAvatarLink={_.get(user, 'photoURL')}
        />
        <div className="user-text-data">
          <p className="user-name">{_.get(user, 'name')}</p>
          <Verifications authMethods={_.get(user, 'authMethods')} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesktopUserShortInfo;
