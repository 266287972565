import React from "react";

import { HistoryHelper } from "../../helpers";
import { TimePickerService } from "../../services/TimePicker";
import { RelationControlInvitationsService } from "../../services/RelationControl";

import "./InvitationDetails.scss";
import * as _ from "lodash";
import { EmailService } from "../../services/Email";
import { NotificationsService } from "../../services/Notifications";
import { FutureMeetingsFirebaseService } from "../../services/FutureMeetings";
import { APP_DOMAIN } from "../../shared/constants";

const invitationDetailsButtons = ({
  invitation,
  setPopUpNotificationText,
  setIsLoading,
  invitations,
  setInvitations,
}) => {
  const futureMeetingsFirebaseService = new FutureMeetingsFirebaseService();

  const acceptInvitation = async () => {
    setIsLoading(true);
    const result = await RelationControlInvitationsService.acceptInvitation(
      invitation
    );

    const cardUserToken = _.get(invitation, "user.token");
    const cardAdminUsername = _.get(invitation, "admin.name");
    const coffeeShop = _.get(invitation, "selectedCafe.name", "Zoom");
    const meetingStartTime = _.get(invitation, "meetingStartTime.seconds");
    const cardUserId = _.get(invitation, "user.id");
    const cardAdminId = _.get(invitation, "admin.id");

    if (result) {
      setPopUpNotificationText({
        text: "Invitation was accepted",
      });

      await NotificationsService.sendNotification(
        cardUserToken,
        `Your invitation for ${cardAdminUsername} to ${coffeeShop} in  ${TimePickerService.getTimeFromMS(
          meetingStartTime * 1000
        )} was accepted`,
        "/user"
      );

      const futureMeeting = {
        meetingStartTime: meetingStartTime,
        coffeeShop: coffeeShop,
        cardUserId: cardUserId,
        cardAdminId: cardAdminId,
      };

      await futureMeetingsFirebaseService.addFutureMeeting(futureMeeting);

      const updatedInvitations = invitations.filter((inv) => {
        return inv.id !== invitation.id;
      });
      setInvitations(updatedInvitations);

      let emailText = `<p>Congratulations – invite is accepted.</p>
      <p>From ${_.get(invitation, "admin.name")} about "${_.get(
        invitation,
        "coffee.description"
      )}".</p>
     <p>To rate this meeting after the end visit: ${APP_DOMAIN}/meeting/rate/${_.get(
       invitation,
       "coffee.id"
     )}.</p>`;

      if (invitation.coffee.zoom_link) {
        emailText += ` <p><a href="${invitation.coffee.zoom_link}" target="_blank" rel="noopener noreferrer">Zoom link</a></p>`;
      }

      await EmailService.sendHtmlMessage(
        _.get(invitation, "user.email"),
        `Accepted invite with ${_.get(invitation, "user.name")}`,
        emailText
      );
    } else {
      setIsLoading(false);
      setPopUpNotificationText({
        text:
          "You have already sent invitation for this coffee or at this time",
      });
      return;
    }

    const updatedInvitations = invitations.filter((inv) => {
      return inv.id !== invitation.id;
    });
    setInvitations(updatedInvitations);

    setIsLoading(false);
    HistoryHelper.redirectTo("/notifications");
  };

  const declineInvitation = async () => {
    const result = await RelationControlInvitationsService.declineInvitation(
      _.get(invitation, "id")
    );

    if (result) {
      const cardUserToken = _.get(invitation, "user.token");
      const cardAdminUsername = _.get(invitation, "admin.name");
      const coffeeShop = _.get(invitation, "selectedCafe.name", "Zoom");
      const meetingStartTime = _.get(invitation, "meetingStartTime.seconds");

      await NotificationsService.sendNotification(
        cardUserToken,
        `Your invitation for ${cardAdminUsername} to ${coffeeShop} in ${TimePickerService.getTimeFromMS(
          meetingStartTime * 1000
        )} was declined`,
        "/"
      );

      setPopUpNotificationText({
        text: "Invitation was declined",
      });
    }

    const updatedInvitations = invitations.filter((inv) => {
      return inv.id !== invitation.id;
    });
    setInvitations(updatedInvitations);

    HistoryHelper.redirectTo("/notifications");
  };

  return (
    <React.Fragment>
      <div className="invitation-buttons">
        <button onClick={declineInvitation}>Politely Decline</button>
        <button className="active" onClick={acceptInvitation}>
          Accept
        </button>
      </div>
    </React.Fragment>
  );
};

export default invitationDetailsButtons;
