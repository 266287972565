import React from 'react';
import * as moment from 'moment';

import { BsFillQuestionSquareFill } from 'react-icons/bs';

import './NewsCard.scss';
import * as _ from 'lodash';

const NewsCard = ({ news, index }) => {
  let publishedDate = moment(_.get(news, 'publishedAt')).format('DD-MM-YYYY');

  const newsImage = _.isEmpty(news.urlToImage) ? (
    <BsFillQuestionSquareFill className="news-image" />
  ) : (
    <img className="news-image" src={news.urlToImage} alt="news" />
  );

  if (moment().isSame(_.get(news, 'publishedAt'), 'date')) {
    publishedDate = 'today';
  }

  if (moment().diff(_.get(news, 'publishedAt'), 'days') === 1) {
    publishedDate = 'a day ago';
  }

  return (
    <React.Fragment>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="news-content"
        href={news.url}
      >
        <div className="news-info">
          <p className="news-source">{news.source.name}</p>
          <p className="news-title">{news.title}</p>
          <p className="news-published">{publishedDate}</p>
        </div>
        {newsImage}
      </a>
    </React.Fragment>
  );
};

export default NewsCard;
