import React, { useRef } from "react";

import { Grid } from "@material-ui/core";

import "./GetPaid.scss";
import StripeConnect from "../../user-page/ConnectStripe";

const GetPaid = ({
  formValue,
  setFormValue,
  user,
  setUserInfo = () => {},
  chargesEnalbled,
  setSignInPageStatus,
}) => {
  const popUpRef = useRef(null);

  const onAmountChange = (e) => {
    const value = Math.round(e.target.value);
    setFormValue({ ...formValue, payment_amount: Number(value) });
  };

  const getValue = () => {
    if (formValue.payment_amount === 0) {
      return "";
    }
    return formValue.payment_amount;
  };

  return !chargesEnalbled ? (
    <StripeConnect
      user={user}
      setUserInfo={setUserInfo}
      setSignInPageStatus={setSignInPageStatus}
    />
  ) : (
    <Grid container direction="row" className="get-paid-input">
      <input
        style={{
          borderTopLeftRadius: 7,
          borderBottomLeftRadius: 7,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        autoFocus={!!formValue.payment_amount}
        placeholder="Set Minimum Price"
        value={getValue()}
        onChange={onAmountChange}
        type="number"
        min="1"
        step="1"
        ref={popUpRef}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
      />{" "}
      <span
        style={{
          margin: "auto",
          padding: "10px 15px",
          height: 28,
          lineHeight: 2,
          borderTopRightRadius: 7,
          borderBottomRightRadius: 7,
          backgroundColor: "#f5f8fa",
          border: "1px solid #d5d5d5",
        }}
      >
        USD
      </span>
    </Grid>
  );
};

export default GetPaid;
