import { FireBase } from "./Firebase";

export class Storage {
  static async loadPictureToStorage(picture) {
    const firebaseStorage = FireBase.getInstance().getFirebaseStorage();

    const uploadedImageURL = new Promise((resolve) => {
      const currentTime = new Date().getTime();
      const upload = firebaseStorage
        .ref(`images/${currentTime + picture.name}`)
        .put(picture);
      upload.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          firebaseStorage
            .ref("images")
            .child(currentTime + picture.name)
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            });
        }
      );
    });
    return uploadedImageURL;
  }
}
