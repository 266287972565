import * as _ from 'lodash';

export class NewsService {
  static async getTopFiveNewsByShortDescription(description) {
    const requestUrl = `https://us-central1-coffee-me-dev.cloudfunctions.net/app/news/region?q=${description}`;

    const response = await fetch(requestUrl).then(res => res.json());
    const topFiveNews = _.slice(response.articles, 0, 5);

    return topFiveNews;
  }

  static async getTopFiveHeadlinesNewsByRegion(region) {
    // const url = "http://localhost:4242
    const regionNewsRequestUrl = `https://us-central1-coffee-me-dev.cloudfunctions.net/app/news/region?q=${region}`;
    // const regionNewsRequestUrl = `http://localhost:4242/news/region?q=${region}`;

    const regionNews = await fetch(regionNewsRequestUrl).then(res =>
      res.json()
    );

    return regionNews;
  }
}
