export class ZoomService {
  static async getUserTokens(code) {
    // const zoomAuthUrl = `http://localhost:4242/zoom/auth?code=${code}`;
    const zoomAuthUrl = `https://us-central1-coffee-me-dev.cloudfunctions.net/app/zoom/auth?code=${code}`;

    try {
      const tokens = await fetch(zoomAuthUrl).then((res) => res.json());
      return tokens;
    } catch (err) {
      return null;
    }
  }

  static async createMeeting(code, startTime, duration, topic) {
    const zoomAuthUrl = `https://us-central1-coffee-me-dev.cloudfunctions.net/app/zoom/meeting`;
    // const zoomAuthUrl = `http://localhost:4242/zoom/meeting`;

    const body = {
      code: code,
      startTime: startTime,
      duration: duration,
      topic: topic,
    };

    try {
      const meeting = await fetch(zoomAuthUrl, {
        method: "POST",
        body: JSON.stringify(body),
      }).then((res) => res.json());
      return meeting;
    } catch (err) {
      return null;
    }
  }

  static async getMeetings(accessToken) {
    const zoomAuthUrl = `https://us-central1-coffee-me-dev.cloudfunctions.net/app/zoom/meeting`;
    // const zoomAuthUrl = `http://localhost:4242/zoom/meeting`;

    try {
      const meetings = await fetch(zoomAuthUrl, {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      }).then((res) => res.json());
      return meetings;
    } catch (err) {
      return null;
    }
  }
}
