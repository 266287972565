import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import GuestPage from '../components/guest-page';

export function mapStateToProps({ coffeeState, userState }) {
  return {
    fakeCards: coffeeState.fakeCards,
    user: userState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setFakeCards: payload => dispatch(actions.setFakeCards(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestPage);
