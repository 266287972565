import React from "react";
import { Switch, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 44,
    height: 24,
    padding: 0,
    display: "flex",
  },
  input: {
    width: "70px !important",
  },
  switchBase: {
    padding: 2,
    color: "#ffffff",
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#fa6400",
        borderColor: "#fa6400",
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
  },
  track: {
    border: `1px solid #cccccc`,
    borderRadius: 24,
    opacity: 1,
    backgroundColor: "#cccccc",
    height: "auto",
  },
  checked: {},
}));

const Switcher = ({ checked, onChange }) => {
  const classes = useStyles();

  return (
    <Switch
      classes={classes}
      checked={checked}
      value={checked}
      onChange={onChange}
    />
  );
};

export default Switcher;
