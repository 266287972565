import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import ZoomForm from './../components/zoom';

export function mapStateToProps({ coffeeState, userState }) {
  return {
    coffees: coffeeState.coffees,
    user: userState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setCoffees: payload => dispatch(actions.setCoffees(payload)),
    setSignInPageStatus: payload =>
      dispatch(actions.setSignInPageStatus(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ZoomForm);
