import React, { useRef, useEffect } from "react";
import Input from "./../input/index";
import * as _ from "lodash";

const SearchBox = (props) => {
  const searchInput = useRef(null);
  let searchBox = useRef(null);

  useEffect(({ map, mapApi } = props) => {
    let input = searchInput.current;
    searchBox.current = new mapApi.places.SearchBox(input);
    searchBox.current.addListener("places_changed", onPlacesChanged);
    searchBox.current.bindTo("bounds", map);
    return ({ mapApi } = props) => {
      mapApi.event.clearInstanceListeners(input);
    };
    // eslint-disable-next-line
  }, []);

  const onPlacesChanged = ({ map, addPlace } = props) => {
    const selected = searchBox.current.getPlaces();
    const { 0: place } = selected;
    if (!_.get(place, "geometry")) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addPlace(selected);
    searchInput.current.blur();
  };

  const clearSearchBox = () => {
    //inputEl.current.value = '';
  };

  return (
    <div className="search-box-container">
      <Input
        status="map-search-box"
        ref={searchInput}
        type="text"
        onFocus={clearSearchBox}
        placeholder="Search coffee shops"
      />
    </div>
  );
};

export default SearchBox;
