import React from 'react';
import { Map, InfoWindow } from 'google-maps-react';

import './GoogleMap.scss';

const MapContainer = props => {
  return (
    <React.Fragment>
      <Map
        google={props.google}
        zoom={13}
        className="google-map-container"
        onReady={props.apiHasLoaded}
        onClick={props.onMapClicked}
        initialCenter={props.currentLocation}
      >
        <InfoWindow
          marker={props.activeMarker}
          onClose={props.onInfoWindowClose}
          visible={props.showingInfoWindow}
        >
          <div>
            <h1>{props.selectedPlace.text}</h1>
          </div>
        </InfoWindow>

        {props.renderPointers(props.placesFromNearby)}

        {props.renderPointers(props.foundedPlaces)}
      </Map>
    </React.Fragment>
  );
};

export default MapContainer;
