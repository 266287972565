import { RelationControlCoffeeService } from './RelationControlCoffee';

import history from '../history';

import * as _ from 'lodash';

export class NotificationsService {
  static async sendNotification(userToken, message, redirectUrl) {
    if (_.isEmpty(userToken)) {
      return;
    }

    const notificationData = {
      data: {
        message: message,
        redirectUrl: redirectUrl,
        badge:
          'https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2Flogo192.png?alt=media&token=06ed9dbd-0f6b-46b2-a03a-7f9ebf8e394f',
      },
      to: userToken,
    };

    await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `key=${process.env.REACT_APP_NOTIFICATIONS_PRIVATE_KEY}`,
      },
      body: JSON.stringify(notificationData),
    });
  }

  static async sendNotificationForRate() {
    const currentPath = history.location.pathname.slice(0, 13);
    if (currentPath === '/meeting/rate') {
      return;
    }

    const pastCoffees = await RelationControlCoffeeService.getPastCoffeesByTime();

    const notRatedPastCoffees = _.filter(pastCoffees, coffee => {
      return !_.get(coffee, 'rate');
    });

    if (_.isEmpty(notRatedPastCoffees)) {
      return;
    }

    if ('Notification' in window) {
      for (const coffee of notRatedPastCoffees) {
        const cardAdminUsername = _.get(coffee, 'admin.name');
        const coffeeShop = _.get(coffee, 'details.selectedCafe.name', 'Zoom');

        navigator.serviceWorker.ready.then(function(registration) {
          registration.showNotification('CoffeeTalk', {
            body: `Rate your last coffee with ${cardAdminUsername} in ${coffeeShop} please`,
            data: {
              redirectUrl: `/meeting/rate/${coffee.id}`,
            },
            badge:
              'https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2Flogo192.png?alt=media&token=06ed9dbd-0f6b-46b2-a03a-7f9ebf8e394f',
          });
        });
      }
    }
  }

  static showNotification(text, redirectUrl) {
    navigator.serviceWorker.ready.then(function(registration) {
      registration.showNotification('CoffeeTalk', {
        body: text,
        data: {
          redirectUrl: redirectUrl,
        },
        badge:
          'https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/images%2Flogo192.png?alt=media&token=06ed9dbd-0f6b-46b2-a03a-7f9ebf8e394f',
      });
    });
  }
}
