import React, { useState, useEffect } from "react";

import "./Tabs.scss";

const Tabs = ({
  tabs,
  time_from = null,
  time_to = null,
  additionalField = null,
  currentLocation = null,
  tabByDefault = 0,
  setIsLoading = () => {},
}) => {
  const [currentTab, setCurrentTab] = useState(tabByDefault);

  useEffect(() => {
    let activeYet = true;

    async function getData() {
      await tabs[currentTab].getData().then((res) => {
        if (!activeYet) {
          setIsLoading(false);
          return;
        }
        tabs[currentTab].afterLoad(res);
      });
    }

    getData();

    return () => {
      activeYet = false;
    };
    // eslint-disable-next-line
  }, [currentTab, time_from, time_to, currentLocation, additionalField]);

  return (
    <React.Fragment>
      <div className="tabs-container">
        {tabs.map((item, index) => {
          return (
            <p
              className={`${index === currentTab ? "active-tab" : ""} tab`}
              key={index}
              onClick={() => setCurrentTab(index)}
            >
              {item.text}
            </p>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Tabs;
