import { FIREBASE_AUTH_DOMAIN } from "../shared/constants";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: 'https://coffee-me-dev.firebaseio.com',
  projectId: 'coffee-me-dev',
  storageBucket: 'coffee-me-dev.appspot.com',
  messagingSenderId: '138101802196',
  appId: '1:138101802196:web:21996d7c2451f5acf6e741',
  measurementId: 'G-H5R5QSMCEY',
};
