import React, {useState} from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./Stripe.scss";
import {StripePaymentFirebaseService} from "../../services/StripePaymentsDB";
import {APP_DOMAIN} from "../../shared/constants";

export default function CheckoutForm({
  coffeeId,
  clientSecret,
  paymentAmount,
  onBackButtonClick
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stripePaymentFB = new StripePaymentFirebaseService();


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripePaymentFB.addPayment(coffeeId, clientSecret);
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${APP_DOMAIN}/confirm`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form
      id="payment-form"
      className="stripe-payment-form"
      onSubmit={handleSubmit}
      onClick={(e) => e.stopPropagation()}
    >
      <PaymentElement/>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
      <div className={"stripe-payment-form-bottom"}>
        <button onClick={onBackButtonClick} className="button-secondary"> Back</button>
        <button disabled={isLoading || !stripe || !elements} id="submit">
          Pay now
          {!!paymentAmount && <span>{" $" + paymentAmount} USD</span>}
        </button>
      </div>
    </form>
  );
}
