import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

export const OrangeCheckbox = withStyles({
  root: {
    fontWeight: "normal",
    color: "#fa6400",
    "&$checked": {
      fontWeight: "normal",
      color: "#fa6400",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
