import React, { useState } from "react";
import { FaClock } from "react-icons/fa";

import { TimePickerService } from "../../services/TimePicker";
import UserSmallAvatar from "../user-small-avatar";
import UserAvatarEdit from "../user-avatar-edit";

import "./PastCoffeeCard.scss";
import * as _ from "lodash";
import moment from "moment";
import { MeetingTypes } from "../../shared/MeetingTypes";

const PastCoffeeCard = ({ userPhotoURL, coffee, user }) => {
  const [isAvatarOpened, setAvatarOpened] = useState(false);
  const [avatarLink, setAvatarLink] = useState(null);
  const pastCoffeeCard = "past-coffee-card";

  let interlocutorData = _.get(coffee, "admin");
  let userData = _.get(coffee, "user");
  const timeFrom = _.get(coffee, "time_from.seconds");
  const coffeeType = _.get(coffee, "type", "");

  const isWebinar = [MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(coffeeType);

  if (`${user.photoURL}` === `${userPhotoURL}`) {
    interlocutorData = _.get(coffee, "user");
    userData = _.get(coffee, "admin", user);
  }

  if (interlocutorData === undefined) {
    interlocutorData = user;
  }

  if (userData === undefined) {
    userData = user;
  }

  const onClickUserAvatar = (link) => {
    setAvatarLink(link);
    setAvatarOpened(true);
  };

  const period = TimePickerService.isTodayDate(timeFrom)
    ? `Today`
    : moment(timeFrom * 1000).format("ll");

  const meetingStartTime = _.get(coffee, "details.meetingStartTime.seconds");

  const timeValueText = () => {
    if (_.isUndefined(meetingStartTime)) {
      return (
        period +
        ` from ${moment.unix(timeFrom).format("h:mm A")} to ${moment
          .unix(_.get(coffee, "time_to.seconds"))
          .format("h:mm A")}`
      );
    }

    return (
      period +
      ` from ${moment
        .unix(_.get(coffee, "details.meetingStartTime.seconds"))
        .format("h:mm A")}`
    );
  };

  return (
    <div id={coffee.id} className={pastCoffeeCard}>
      {isAvatarOpened && (
        <UserAvatarEdit
          setUserAvatarOpened={setAvatarOpened}
          userAvatarLink={avatarLink.photoURL}
          user={avatarLink}
        />
      )}
      <div className="avatars-container">
        <div className="coffee-card-user-avatar">
          <div
            onClick={(event) => {
              event.stopPropagation();
              onClickUserAvatar(userData);
            }}
          >
            <UserSmallAvatar
              avatarClassName="coffee-card-user-img"
              userAvatarLink={userData.photoURL}
            />
          </div>
        </div>

        {!_.isUndefined(meetingStartTime) && !isWebinar && (
          <div className="coffee-card-interlocutor-avatar">
            <div
              onClick={(event) => {
                event.stopPropagation();
                onClickUserAvatar(interlocutorData);
              }}
            >
              <UserSmallAvatar
                avatarClassName="coffee-card-user-img"
                userAvatarLink={interlocutorData.photoURL}
              />
            </div>
          </div>
        )}
      </div>
      <div className="coffee-card-data">
        <p className="coffee-card-title">{_.get(coffee, "description")}</p>
        {/* <p className="coffee-card-conversation-time">
          {_.get(coffee, "duration_time")} min conversation
        </p> */}
        <p className="coffee-card-meet-info">
          <FaClock className="coffee-card-icon" />
          {timeValueText()}
        </p>
      </div>
    </div>
  );
};

export default PastCoffeeCard;
