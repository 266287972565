import * as constants from '../constants';

const initialState = {
  news: null,
  title: 'Topics for You',
};

const actionHandlers = {
  [constants.SET_TOPICS](state, topics) {
    return { ...state, ...topics };
  },
};

export default function topics(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
