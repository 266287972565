import firebase from "firebase/app";
import "firebase/firestore";
import * as _ from "lodash";

import moment from "moment";
import { parseJwt } from "./parseJwt";
import { TimePickerService } from "../services/TimePicker";
import { UserFirebaseService } from "../services/UserDB";
import { MeetingTypes } from "../shared/MeetingTypes";

const userService = new UserFirebaseService();

export class FormatHelper {
  static authMethods(user) {
    const decodedInfo = parseJwt(_.get(user, "_lat"));
    const authMethod = _.get(decodedInfo, "firebase.sign_in_provider");

    return {
      google: authMethod.includes("google"),
      facebook: authMethod.includes("facebook"),
      twitter: authMethod.includes("twitter"),
      apple: authMethod.includes("apple"),
    };
  }

  static getUserInfoFromResponse(user) {
    if (!user) {
      return null;
    }

    return {
      name: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      authMethods: this.authMethods(user),
      age: "",
      education: "",
      workplace: "",
    };
  }

  static getUserInfoForRedux(user, userFromDB, key) {
    if (!user) {
      return null;
    }

    return {
      name: user.displayName,
      email: user.email,
      photoURL: userFromDB.photoURL,
      id: key,
      authMethods: this.authMethods(user),
      age: userFromDB.age || "",
      education: userFromDB.education || "",
      workplace: userFromDB.workplace || "",
      stripeId: userFromDB.stripeId,
    };
  }

  static getEmptyUserInfoForRedux() {
    // let availabilityFrom = moment(TimePickerService.getRoundedTime()).unix();
    let availabilityFrom = moment(TimePickerService.getRoundedTime()).unix();
    let availabilityTo = moment(TimePickerService.getRoundedTime())
      .add(15, "days")
      .startOf("day")
      .unix();

    // .add(3, "h")

    // if (
    //   availabilityTo >
    //   moment()
    //     .add(1, "d")
    //     .startOf("day")
    //     .unix()
    // ) {
    //   availabilityTo = moment()
    //     .startOf("day")
    //     .add(1, "d")
    //     .add(-15, "m")
    //     .unix();
    // }

    if (sessionStorage.availabilityFrom && sessionStorage.availabilityTo) {
      availabilityFrom = +sessionStorage.availabilityFrom;
      availabilityTo = +sessionStorage.availabilityTo;
    } else {
      sessionStorage.setItem("availabilityFrom", +availabilityFrom);
      sessionStorage.setItem("availabilityTo", +availabilityTo);
    }

    return {
      availabilityFrom,
      availabilityTo,
    };
  }

  static async getTestUserForRedux() {
    let availabilityFrom = moment(TimePickerService.getRoundedTime()).unix();
    let availabilityTo = moment(TimePickerService.getRoundedTime())
      .add(3, "h")
      .unix();

    if (
      availabilityTo >
      moment()
        .add(1, "d")
        .startOf("day")
        .unix()
    ) {
      availabilityTo = moment()
        .startOf("day")
        .add(-15, "m")
        .unix();
    }

    if (sessionStorage.availabilityFrom && sessionStorage.availabilityTo) {
      availabilityFrom = +sessionStorage.availabilityFrom;
      availabilityTo = +sessionStorage.availabilityTo;
    }

    const user = await userService.getById("IC39sYwdzbZ71rZiRTnc");

    const testUser = _.assign({}, user, {
      availabilityFrom,
      availabilityTo,
    });

    return testUser;
  }

  static getCoffeeInfo(coffee) {
    if (!coffee.geometry) {
      throw new Error("No geometry");
    }
    return {
      lat: coffee.geometry.location.lat(),
      lng: coffee.geometry.location.lng(),
      vicinity: coffee.vicinity || coffee.formatted_address,
      name: coffee.name,
      id: coffee.place_id,
      place_id: coffee.place_id,
    };
  }

  static getTimestamp(time) {
    let value = time;
    if (!_.isNumber(time)) {
      value = moment(time).unix();
    }
    return new firebase.firestore.Timestamp(value, 0);
  }

  static getCoffeeFormValue(form, coffees) {
    let isFormInvalid = false;
    for (const key in form) {
      if (form[key] === "") {
        // console.log(key + ' is blank. Deleting it');
        isFormInvalid = true;
      }
    }

    const isTimeFromInvalid = form.time_from < moment().unix();
    const MAX_AVAILABLE_COFFEE =
      form.type === MeetingTypes.COFFEE_GROUP ? 1 : 3;

    if (
      isFormInvalid ||
      coffees.length !== MAX_AVAILABLE_COFFEE ||
      isTimeFromInvalid
    )
      return false;

    return {
      description: form.description,
      duration_time: +form.duration_time,
      time_from: this.getTimestamp(form.time_from),
      time_to: this.getTimestamp(form.time_to),
      type: form.type,
      coffees,
      payment_amount: [
        MeetingTypes.ZOOM_GROUP,
        MeetingTypes.LIVE,
        MeetingTypes.COFFEE_GROUP,
      ].includes(form.type)
        ? form.payment_amount
        : 0,
    };
  }

  static getZoomFormValue(form, chargesEnabled) {
    let isFormInvalid = false;
    for (const key in form) {
      if (form[key] === "") {
        // console.log(key + " is blank. Deleting it");
        isFormInvalid = true;
      }
    }
    const isTimeFromInvalid = form.time_from < moment().unix();

    if (
      isFormInvalid ||
      isTimeFromInvalid ||
      (!chargesEnabled && form.payment_amount !== 0)
    )
      return false;

    return {
      description: form.description,
      duration_time: +form.duration_time,
      time_from: this.getTimestamp(form.time_from),
      time_to: this.getTimestamp(form.time_to),
      type: form.type,
      payment_amount: [
        MeetingTypes.ZOOM_GROUP,
        MeetingTypes.LIVE,
        MeetingTypes.COFFEE_GROUP,
      ].includes(form.type)
        ? form.payment_amount
        : 0,
    };
  }
}
