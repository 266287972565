import React  from "react";

import "./UserPage.scss";

import * as _ from "lodash";
import DetailedCoffeeCard from "../../containers/DetailedCoffeeCard";
import PastCoffeeCard from "../past-coffee-card";
import Spinner from "../spinner";

const RenderCardList = ({
  isLoading,
  scrollElementRef,
  coffees,
  user,
  deleteInvitation,
  currentTab,
  filterCoffeesAfterCancel,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const defaultSelectedCoffeeId = queryParams.get('coffeeId');

  return (
    <div className="coffee-cards-container" ref={scrollElementRef}>
      {isLoading && (
        <Spinner
          loaderContainerName={
            coffees.length >= 5
              ? "user-page-loader-container big"
              : "user-page-loader-container"
          }
          blockName="user-page"
        />
      )}
      {!_.isEmpty(coffees) ? (
        coffees
          .filter((coffee) => coffee)
          .map((coffeeInfo, index) => {
            const interlocutorData =
              _.get(user, "id") === _.get(coffeeInfo, "admin.id")
                ? _.get(coffeeInfo, "user")
                : _.get(coffeeInfo, "admin");

            return currentTab === "coffee-soon" ? (
              <DetailedCoffeeCard
                key={_.get(coffeeInfo, "coffee.description") + index.toString()}
                coffee={coffeeInfo.coffee}
                user={coffeeInfo.admin}
                invitation={coffeeInfo}
                interlocutorData={interlocutorData}
                status={_.get(coffeeInfo, "status", null)}
                details={_.get(coffeeInfo, "details", null)}
                deleteInvitation={deleteInvitation}
                filterCoffeesAfterCancel={filterCoffeesAfterCancel}
                defaultSelectedCoffeeId={defaultSelectedCoffeeId}
              />
            ) : (
              <PastCoffeeCard
                key={_.get(coffeeInfo, "description") + index.toString()}
                userPhotoURL={user.photoURL}
                coffee={coffeeInfo}
                user={user}
              />
            );
          })
      ) : (
        <div className="coffee-cards-container"></div>
      )}
    </div>
  );
};

export default React.memo(RenderCardList);
