import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutFrom";
import "./Stripe.scss";
import { StripeService } from "../../services/Stripe";
import useOutsideClick from "../../shared/OutsideClick";
import { IoMdClose } from 'react-icons/io';
import { APP_EMAIL_DOMAIN, STRIPE_API_KEY } from "../../shared/constants";
import { PaymentTypes } from "../../shared/PaymentTypes";
import moment from "moment";
import * as _ from "lodash";
import { TimePickerService } from "../../services/TimePicker";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";
import { RelationControlInvitationsService } from "../../services/RelationControl";
import { HistoryHelper } from "../../helpers";
import { EmailService } from "../../services/Email";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
const stripePromise = loadStripe(STRIPE_API_KEY);
const modalSteps = {
  paymentAmount: "paymentAmount",
  paymentDetails: "paymentDetails",
}

export default function StripeModal(props) {
  const { closeStripeModal, coffee, setPopUpNotificationText, user } = props;
  const coffeeId = coffee.id;
  const coffeeOwnerId = coffee.user.id;
  const [paymentAmount, setPaymentAmount] = useState(coffee.payment_amount)
  const [step, setStep] = useState(modalSteps.paymentAmount);
  const [clientSecret, setClientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const modalContentRef = useRef();
  useOutsideClick(modalContentRef, closeStripeModal);

  useEffect(() => {
    if (step === modalSteps.paymentAmount)
      setClientSecret(null)
    else if (step === modalSteps.paymentDetails)
      StripeService.createPayment({
        paymentAmount,
        coffeeId: coffeeId,
        coffeeOwnerId
      }).then((res) => {
        setClientSecret(res);
      });
    // eslint-disable-next-line
  }, [step]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const onClickPayNow = async () => {
    if (paymentAmount < coffee.payment_amount) {
      setErrorMessage(`Payment value should be more then ${coffee.payment_amount}`);
      return;
    }

    if (coffee.fake) {
      await EmailService.sendMessage(
        `help@${APP_EMAIL_DOMAIN}`,
        `CoffeeTalk user clicked on grab coffee`,
        `CoffeeTalk user ${user.name} clicked on grab coffee "${coffee.description}"`
      );
      const hoursDiff = moment(coffee.time_from * 1000).diff(moment(), 'hours');

      const dayDiff = hoursDiff / 24;
      const roundedDaysDiff = Math.ceil(dayDiff);

      const meetingStartTime = TimePickerService.getTimeFromMS(
        TimePickerService.getAvailabilitiesIntersection(user.availabilityFrom, coffee.time_from) *
        1000
      );

      const grabTime = TimePickerService.isTodayDate(coffee.time_from)
        ? moment(meetingStartTime, ['h:mm A'])
        : moment(meetingStartTime, ['h:mm A']).add(roundedDaysDiff, 'd');

      const newCoffee = await RelationControlCoffeeService.addFakeCoffee(coffee);

      const invitationRelationId = await RelationControlInvitationsService.sendInvitation(
        newCoffee,
        null,
        '',
        grabTime.unix(),
        grabTime.add(coffee.duration_time, 'm').unix(),
        false
      );

      if (_.isEmpty(invitationRelationId)) {
        setPopUpNotificationText({
          text: 'You have already sent invitation for this coffee or at this time'
        });
        return;
      }

      HistoryHelper.redirectTo('/user');
    } else {
      setStep(modalSteps.paymentDetails);
      setErrorMessage(null);
    }
  };

  return (
    <div className="stripe-modal-background" onClick={e => e.stopPropagation()}>
      <div className="stripe-modal" ref={modalContentRef}>
        <div className="stripe-modal-header">
          <div>Complete Payment Process</div>
          <IoMdClose onClick={() => closeStripeModal()}/>
        </div>
        <div className="stripe-modal-body">
          {step === modalSteps.paymentAmount && <>
            <div className="stripe-modal-body-input-wrapper">
              <div className="pre-input">$</div>
              <input
                autoFocus
                placeholder="Set Amount"
                value={paymentAmount}
                onChange={e => setPaymentAmount(+e.target.value)}
                type="number"
                min={coffee.payment_amount}
                step="1"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <div className="post-input">USD</div>
              {errorMessage && <div className="stripe-modal-body-input-error-message">{errorMessage}</div>}
            </div>

            <button onClick={onClickPayNow} className="continue-to-payment">
              {coffee.payment_type === PaymentTypes.FUNDRAISER ? 'Donate Now' : 'Continue to Payment'}
            </button>
          </>}
          {clientSecret && step === modalSteps.paymentDetails && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                coffeeId={coffeeId}
                clientSecret={clientSecret}
                paymentAmount={paymentAmount}
                onBackButtonClick={() => setStep(modalSteps.paymentAmount)}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
}