import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import CoffeeCard from "../../containers/CoffeeCard";
import Spinner from "../spinner";

import { RateFirebaseService } from "../../services/RateDB";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";

import { HistoryHelper } from "../../helpers";

import "./RateMeeting.scss";
import * as _ from "lodash";
import moment from "moment";

const rateService = new RateFirebaseService();

const RateMeeting = ({
  user,
  setTitle,
  setPopUpNotificationText = () => {},
  setClickedIcon = () => {},
}) => {
  setTitle("Rate Meeting");
  const { id } = useParams();

  const [meeting, setMeeting] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [rate, setRate] = useState({
    wantToChat: false,
    wantReturnToCoffeeShop: false,
    enjoyMeeting: false,
  });

  const vicinity = _.get(meeting, "coffees[0].vicinity", "Zoom");

  const rateMeeting = (ratedField) => {
    const newRate = _.assign({}, rate, { [ratedField]: !rate[ratedField] });
    setRate(newRate);
  };

  const cancelRatePage = (popUpText) => {
    setIsLoading(false);
    HistoryHelper.redirectTo("/");
    setClickedIcon("home-icon");
    setPopUpNotificationText({
      text: popUpText,
    });
  };

  useEffect(() => {
    async function getInvitationById() {
      setIsLoading(true);
      const response = await RelationControlCoffeeService.getPastCoffeeWithAdminAndUser(
        id
      );

      const cardAdminId = _.get(response, "admin.id");
      const currentUserId = _.get(user, "id");
      if (cardAdminId === currentUserId) {
        cancelRatePage("You can't rate this meeting cause you are an admin");
      }

      const meetingEndTime = moment(
        _.get(response, "details.meetingEndTime.seconds") * 1000
      ).unix();
      const currentTime = moment().unix();

      const isMeetingNotOver = moment(currentTime).isBefore(meetingEndTime);

      if (isMeetingNotOver) {
        cancelRatePage("Meeting is not over yet");
      }

      const isCoffeeRated = _.get(response, "rate");
      if (isCoffeeRated) {
        cancelRatePage("You already rate this meeting");
      }

      setIsLoading(false);
      setMeeting(response);
      return response;
    }
    getInvitationById();
    // eslint-disable-next-line
  }, []);

  const sendUserRate = async () => {
    setIsLoading(true);

    const coffeeId = _.get(meeting, "id");
    const cardAdminId = _.get(meeting, "admin.id");
    const cardUserId = _.get(meeting, "user.id");

    await rateService.addRate(rate, coffeeId, cardAdminId, cardUserId);
    setIsLoading(false);
    HistoryHelper.redirectTo("/");
    setClickedIcon("home-icon");
  };

  return (
    <div className="rate-meeting-container">
      {isLoading && (
        <Spinner
          loaderContainerName="loader-container"
          blockName="rate-meeting-container"
        />
      )}
      {!isLoading && !_.isNull(meeting) && (
        <React.Fragment>
          <CoffeeCard
            coffee={meeting}
            user={_.get(meeting, "admin")}
            details={_.get(meeting, "details")}
            status={false}
          />
          <div className="rate-meeting-title">
            <p>Please take a moment to rate your</p>
            <p>CoffeeTalk experience</p>
          </div>

          <div className="rate-meeting-question-container">
            <span className="rate-meeting-question">
              Would you want to chat with {_.get(meeting, "admin.name")} again?
            </span>
            <label className="toggle-button">
              <span
                className={rate.wantToChat ? "slider-on" : "slider-off"}
                onClick={() => rateMeeting("wantToChat")}
              >
                <span className="slider-button"></span>
              </span>
            </label>
          </div>

          {vicinity !== "Zoom" && (
            <div className="rate-meeting-question-container">
              <span className="rate-meeting-question">
                Would you return to the coffee shop?
              </span>
              <label className="toggle-button">
                <span
                  className={
                    rate.wantReturnToCoffeeShop ? "slider-on" : "slider-off"
                  }
                  onClick={() => rateMeeting("wantReturnToCoffeeShop")}
                >
                  <span className="slider-button"></span>
                </span>
              </label>
            </div>
          )}

          <div className="rate-meeting-question-container">
            <span className="rate-meeting-question">
              Did you enjoy your coffee meeting?
            </span>
            <label className="toggle-button">
              <span
                className={rate.enjoyMeeting ? "slider-on" : "slider-off"}
                onClick={() => rateMeeting("enjoyMeeting")}
              >
                <span className="slider-button"></span>
              </span>
            </label>
          </div>
          <button className="active" onClick={sendUserRate}>
            Submit Rating
          </button>
        </React.Fragment>
      )}
    </div>
  );
};

export default RateMeeting;
