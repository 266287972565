import React, { useRef, useEffect } from 'react';
import LiteCoffeeCard from '../lite-coffee-card';

import './CoffeeDetails.scss';
import * as _ from 'lodash';

const CoffeeDetails = ({
  coffee,
  title,
  coffeeDetailsData = () => {},
  buttons = () => {},
  perspectiveTextareaComponent = () => {},
  offerToPay = () => {},
}) => {
  const coffeeDetailsRef = useRef(null);

  useEffect(() => {
    coffeeDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <React.Fragment>
      <div ref={coffeeDetailsRef} className="coffee-details-container">
        <span className="coffee-details">{title}</span>
        <LiteCoffeeCard coffee={coffee} user={_.get(coffee, 'user')} />
        <div className="respective">
          <span className="detail-text">
            What about this conversation interests you?
          </span>
          {perspectiveTextareaComponent()}
        </div>
        <div className="coffee-meeting-details">{coffeeDetailsData()}</div>
        {offerToPay()}
        {buttons()}
      </div>
    </React.Fragment>
  );
};

export default CoffeeDetails;
