import React from 'react';

import Setting from './setting/setting';

import './ProfileSettings.scss';
import * as _ from 'lodash';

import { UserFirebaseService } from '../../services/UserDB';

const ProfileSettings = ({
  user,
  setUserInfo = () => {},
  setLocalStorageUser = () => {},
}) => {
  const userFirebaseService = new UserFirebaseService();

  const updateUserInfo = async (key, value) => {
    const updatedUser = _.assign({}, user, {
      [key]: value,
    });

    await userFirebaseService.updateUser(updatedUser);
    setUserInfo(updatedUser);
    setLocalStorageUser(updatedUser);
  };

  return (
    <div className="profile-settings-container">
      <Setting
        title="Age"
        buttonText="Add Age"
        placeholder="Age"
        type="number"
        updateUserInfo={updateUserInfo}
        content={user.age}
      />
      <Setting
        title="Education"
        buttonText="Add a School"
        placeholder="School or University"
        updateUserInfo={updateUserInfo}
        content={user.education}
      />
      <Setting
        title="Workplace"
        buttonText="Add a Company"
        placeholder="Company"
        updateUserInfo={updateUserInfo}
        content={user.workplace}
      />
    </div>
  );
};

export default ProfileSettings;
