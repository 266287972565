import React, { useEffect, useState } from 'react';
import { ClickAwayListener, Grid, Tooltip } from '@material-ui/core';
import DatePicker from 'react-mobile-datepicker';
import { Popover, IconButton, InputAdornment, Input } from '@material-ui/core';
import moment from 'moment';
import InfoIcon from '../../../shared/icons/info-icon.svg';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MdOutlineWatchLater } from 'react-icons/md';
import { MeetingTypes } from '../../../shared/MeetingTypes';
import Switcher from '../../switcher';
import GetPaid from '../GetPaid/index';

import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { BiDonateHeart } from 'react-icons/bi';

import { PaymentTypes } from '../../../shared/PaymentTypes';

import './ZoomTab.scss';

const ZoomTab = ({
  formValue,
  daysRange,
  setFormValue,
  coffeeTimeTo,
  setCoffeeTimeTo,
  coffeeTimeFrom,
  setCoffeeTimeFrom,
  invalidTime,
  handleClick,
  getTimeIn12hFormat,
  pickerOpen,
  anchorEl,
  handleClose,
  dateConfig,
  setInvalidTime,
  setErrors,
  errors,
  chargesEnalbled,
  setUserInfo,
  user,
  setSignInPageStatus
}) => {
  useEffect(() => {
    if (
      ![MeetingTypes.ZOOM_GROUP, MeetingTypes.ZOOM_ONE_BY_ONE, MeetingTypes.LIVE].includes(
        formValue.type
      )
    ) {
      setFormValue({ ...formValue, type: MeetingTypes.LIVE });
    }
  }, [formValue, setFormValue]);

  const liveNow = formValue.type === MeetingTypes.LIVE;
  const [getPaidToolTipOpen, setGetPaidToolTipOpen] = useState(false);
  const [fundraiserToolTipOpen, setFundraiserPaidToolTipOpen] = useState(false);

  const handleDateChange = date => {
    const isOldDate = moment(date).diff(moment(), 'days') < 0;

    if (isOldDate < 0) {
      return;
    }

    const hoursDiff = moment(date).diff(coffeeTimeFrom * 1000, 'hours');

    const dayDiff = hoursDiff / 24;
    const roundedDaysDiff = Math.ceil(dayDiff);

    const updatedTimeFrom = moment(coffeeTimeFrom * 1000)
      .add(roundedDaysDiff, 'days')
      .unix();

    setCoffeeTimeFrom(updatedTimeFrom);
  };

  const onSelectTime = time => {
    const newTime = moment(time).unix();

    const isNotCurrentDate =
      moment(newTime * 1000).get('hours') === 23 && moment(newTime * 1000).get('minutes') > 0;

    if (newTime < moment().unix() || isNotCurrentDate) {
      setInvalidTime(true);
      setTimeout(() => setInvalidTime(false), 1000);
      return;
    } else {
      const updatedErrors = errors;
      delete updatedErrors['time'];
      setErrors(updatedErrors);
    }

    const hours = moment(newTime * 1000).get('hours');
    const minutes = moment(newTime * 1000).get('minutes');

    const timeFrom = moment(coffeeTimeFrom * 1000)
      .set('hours', hours)
      .set('minutes', minutes)
      .set('seconds', 0)
      .unix();

    const isTimeToBeforeOrSame = moment(coffeeTimeTo * 1000).isSameOrBefore(
      timeFrom * 1000,
      'minutes'
    );

    if (isTimeToBeforeOrSame) {
      const timeTo = moment(timeFrom * 1000)
        .add(15, 'minutes')
        .unix();

      setFormValue({ ...formValue, time_to: timeTo });
      setCoffeeTimeTo(timeTo);
    }

    setFormValue({ ...formValue, time_from: timeFrom });
    setCoffeeTimeFrom(timeFrom);
    return;
  };

  const getPickerValue = () => {
    return moment(coffeeTimeFrom * 1000).toDate();
  };

  const onGroupSwitcherChange = e => {
    if (e.target.checked) {
      setFormValue({
        ...formValue,
        type: MeetingTypes.ZOOM_GROUP,
        payment_amount: 0
      });
      return;
    }
    setFormValue({
      ...formValue,
      type: MeetingTypes.ZOOM_ONE_BY_ONE,
      payment_amount: 0
    });
  };

  const onLiveSwitcherChange = e => {
    if (e.target.checked) {
      setFormValue({ ...formValue, type: MeetingTypes.LIVE });
      return;
    }
    setFormValue({ ...formValue, type: MeetingTypes.ZOOM_GROUP });
  };

  const handleChangeRadioButton = name => {
    setFormValue({ ...formValue, payment_type: name });
  };

  return (
    <React.Fragment>
      <div className="time-selector">
        <Grid container direction="column" justify="space-between" className="swith-container">
          <Grid container direction="row" justify="space-between" alignItems="center">
            <div>Group talk</div>
            <Switcher
              className="switcher"
              checked={[MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(formValue.type)}
              onChange={onGroupSwitcherChange}
            />
          </Grid>

          <p className="bold-text simple">When do you want to meet?</p>

          <Grid container direction="row" justify="space-between" alignItems="center">
            <div>Go Live</div>
            <Switcher
              className="switcher"
              checked={formValue.type === MeetingTypes.LIVE}
              onChange={onLiveSwitcherChange}
            />
          </Grid>
        </Grid>

        {!liveNow && (
          <>
            <div className="zoom-date-picker">
              <Grid className="zoom-date" container direction="column">
                <span className="name">Date:</span>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    shouldDisableDate={daysRange}
                    disableToolbar
                    variant="inline"
                    format="MMMM dd, yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={coffeeTimeFrom * 1000}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    disabled={liveNow}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container direction="column">
                <span className="name">Start time:</span>
                <div className="search-container">
                  <div id={invalidTime ? 'anim-error' : 'error'}>
                    <Input
                      onClick={e => {
                        if (!liveNow) {
                          handleClick(e);
                        }
                      }}
                      type={'text'}
                      value={getTimeIn12hFormat(coffeeTimeFrom)}
                      className={errors['time'] && 'error'}
                      endAdornment={
                        <InputAdornment position="end" disabled={liveNow}>
                          <IconButton edge="end" disabled={liveNow}>
                            <MdOutlineWatchLater />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>

                  {errors['time'] && <div className="validation">{errors['time']}</div>}
                </div>
              </Grid>
            </div>
          </>
        )}

        {[MeetingTypes.LIVE, MeetingTypes.ZOOM_GROUP].includes(formValue.type) && (
          <>
            <p className="choose-talk">Choose the talk you want to start</p>
            <Grid container direction="row" className="payment-type-container">
              <div>
                <button
                  type="button"
                  className={`payment-type-button ${formValue.payment_type === PaymentTypes.PAID &&
                    'active'}`}
                  onClick={() => handleChangeRadioButton(PaymentTypes.PAID)}>
                  <MdAttachMoney />
                  <div>Get Paid</div>
                </button>
                <Tooltip
                  classes={{ tooltip: 'get-paid-tooltip' }}
                  title={
                    <ClickAwayListener onClickAway={() => setGetPaidToolTipOpen(false)}>
                      <div>
                        <p>Think you can get paid?</p>
                        <p>Anyone joining your talk will pay the amount you request.</p>
                      </div>
                    </ClickAwayListener>
                  }
                  open={getPaidToolTipOpen}
                  placement="top-start"
                  enterTouchDelay={10}>
                  <img
                    className="tooltip-badge"
                    src={InfoIcon}
                    alt="tooltip"
                    onClick={() => setGetPaidToolTipOpen(true)}
                  />
                </Tooltip>
              </div>
              <div>
                <button
                  type="button"
                  className={`payment-type-button ${formValue.payment_type ===
                    PaymentTypes.FUNDRAISER && 'active'}`}
                  onClick={() => handleChangeRadioButton(PaymentTypes.FUNDRAISER)}>
                  <BiDonateHeart />
                  <div>Fundraiser</div>
                </button>
                <Tooltip
                  classes={{ tooltip: 'get-paid-tooltip' }}
                  title={
                    <ClickAwayListener onClickAway={() => setFundraiserPaidToolTipOpen(false)}>
                      <div>
                        <p>Raise money for a cause of your choice.</p>
                      </div>
                    </ClickAwayListener>
                  }
                  open={fundraiserToolTipOpen}
                  placement="top-start"
                  enterTouchDelay={10}>
                  <img
                    className="tooltip-badge"
                    src={InfoIcon}
                    alt="tooltip"
                    onClick={() => setFundraiserPaidToolTipOpen(true)}
                  />
                </Tooltip>
              </div>
            </Grid>
            <GetPaid
              setFormValue={setFormValue}
              formValue={formValue}
              user={user}
              setUserInfo={setUserInfo}
              chargesEnalbled={chargesEnalbled}
              setSignInPageStatus={setSignInPageStatus}
            />
          </>
        )}

        <Popover
          open={pickerOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          PaperProps={{
            style: { width: '274px', height: '200px', marginTop: '-9px' }
          }}>
          <DatePicker
            value={getPickerValue()}
            isOpen={true}
            theme="ios"
            isPopup={false}
            showHeader={true}
            showFooter={true}
            dateConfig={dateConfig}
            onChange={onSelectTime}
          />
        </Popover>
      </div>
    </React.Fragment>
  );
};

export default ZoomTab;
