import { UserFirebaseService } from "./UserDB";
import { CoffeeFirebaseService } from "./CoffeeDB";
import { RelationsFirebaseService } from "./RelationsDB";
import { store } from "./../redux/store/index";
import * as _ from "lodash";
import { FormatHelper, MapHelper } from "../helpers";
import moment from "moment";
import { MeetingTypes } from "../shared/MeetingTypes";

const userService = new UserFirebaseService();
const coffeeService = new CoffeeFirebaseService();
const relationService = new RelationsFirebaseService();

export const ADMIN_ROLE = "ADMIN";
export const USER_ROLE = "USER";

export const ACTIVE_STATUS = true;
export const PAST_STATUS = false;

export class RelationControlCoffeeService {
  static async addCoffee(coffee) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");
    const coffeeId = await coffeeService.add(coffee);
    const relation = {
      userId,
      coffeeId,
      role: ADMIN_ROLE,
      status: ACTIVE_STATUS,
      time: coffee.time_to,
    };
    await relationService.add(relation);
  }

  static async addFakeCoffee(coffee) {
    const updatedCoffee = _.assign({}, coffee, {
      time_from: { seconds: coffee.time_from },
      time_to: { seconds: coffee.time_to },
    });
    const coffeeId = await coffeeService.add(updatedCoffee);
    const relation = {
      userId: 1,
      admin: coffee.user,
      coffeeId,
      role: ADMIN_ROLE,
      status: ACTIVE_STATUS,
      time: coffee.time_to,
      fake: true,
    };

    await relationService.add(relation);
    return coffeeId;
  }

  static async getPastCoffees(coffeeCount) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");
    const allMyRelations = await relationService.filterByField([
      // {
      //   field: 'status',
      //   condition: '==',
      //   value: PAST_STATUS,
      // },
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      // {
      //   field: 'role',
      //   condition: '==',
      //   value: ADMIN_ROLE,
      // },
    ]);

    if (_.isEmpty(allMyRelations)) {
      return;
    }

    const sortedRelations = allMyRelations
      .sort((a, b) => {
        let aTime = _.get(a, "meetingStartTime.seconds", undefined);

        if (aTime === undefined) {
          aTime = _.get(a, "time.seconds", 0);
        }

        let bTime = _.get(b, "meetingStartTime.seconds", undefined);

        if (bTime === undefined) {
          bTime = _.get(b, "time.seconds", 0);
        }

        return parseFloat(aTime) - parseFloat(bTime);
      })
      .reverse();

    const uniqEntries = _.uniqBy(sortedRelations, "coffeeId");

    for (const [index, relation] of uniqEntries.entries()) {
      if (relation.role === ADMIN_ROLE) {
        let userRelation = await relationService.filterByField([
          {
            field: "coffeeId",
            condition: "==",
            value: relation.coffeeId,
          },
          {
            field: "role",
            condition: "==",
            value: USER_ROLE,
          },
        ]);

        if (relation.time === undefined) {
          continue;
        }

        if (_.isEmpty(userRelation)) {
          userRelation = [
            {
              coffeeId: relation.coffeeId,
              id: relation.id,
              meetingEndTime: {
                seconds: relation.time.seconds,
                nanoseconds: 0,
              },
              meetingStartTime: {
                seconds: relation.time.seconds,
                nanoseconds: 0,
              },
              offerToPay: true,
              role: "USER",
              selectedCafe: {},
              status: false,
              userId: relation.userId,
            },
          ];
        }

        allMyRelations[index] = _.head(userRelation);
      }
    }

    const pastCoffees = await this.getPastCoffeesByArray(
      allMyRelations.slice(0, coffeeCount)
    );
    return {
      pastCoffees,
      relations: allMyRelations,
    };
  }

  static async getPastCoffeesByArray(relations) {
    const pastCoffees = [];

    if (_.isEmpty(relations)) {
      return;
    }

    const sortedRelations = relations.sort((b, a) => {
      const aTime = _.get(a, "meetingStartTime.seconds", 0);
      const bTime = _.get(b, "meetingStartTime.seconds", 0);

      return aTime > bTime ? 1 : -1;
    });

    for (const relation of sortedRelations) {
      const pastCoffee = await this.getPastCoffeeWithAdminAndUser(
        relation.coffeeId
      );

      if (_.isEmpty(pastCoffee)) {
        continue;
      }

      pastCoffees.push(pastCoffee);
    }

    return pastCoffees;
  }

  static async getPastCoffeeWithAdminAndUser(coffeeId) {
    const relationWithCoffee = await relationService.filterByField([
      {
        field: "coffeeId",
        condition: "==",
        value: coffeeId,
      },
      // {
      //   field: 'status',
      //   condition: '==',
      //   value: PAST_STATUS,
      // },
    ]);

    const filteredRelationWithCoffee = relationWithCoffee.filter((coffee) => {
      return !coffee.fake;
    });

    if (_.isEmpty(filteredRelationWithCoffee)) {
      return;
    }

    if (filteredRelationWithCoffee.length === 1) {
      let fakeUser = {};
      if (filteredRelationWithCoffee[0].role === USER_ROLE) {
        fakeUser = _.assign({}, filteredRelationWithCoffee[0], {
          role: ADMIN_ROLE,
        });
      } else if (filteredRelationWithCoffee[0].role === ADMIN_ROLE) {
        fakeUser = _.assign({}, filteredRelationWithCoffee[0], {
          role: USER_ROLE,
        });
      }
      filteredRelationWithCoffee.push(fakeUser);
    }

    const coffee = await coffeeService.getById(coffeeId);

    const admin = coffee.fake
      ? coffee.user
      : await userService.getById(
          _.find(filteredRelationWithCoffee, { role: ADMIN_ROLE }).userId
        );
    const user = await userService.getById(
      _.find(filteredRelationWithCoffee, { role: USER_ROLE }).userId
    );

    coffee.admin = admin;
    coffee.user = user;
    coffee.details = _.find(filteredRelationWithCoffee, { role: USER_ROLE });

    return coffee;
  }

  static async deleteCoffee(id) {
    const snapshot = await relationService.filterByField([
      { field: "coffeeId", condition: "==", value: id },
    ]);
    // eslint-disable-next-line
    snapshot.map((relation) => {
      relationService.delete(relation.id);
    });

    coffeeService.delete(id);
  }

  static async mergeCoffeeWithUsers(coffees, relations) {
    const allCoffeesWithUsers = [];
    for (let coffee of coffees) {
      const relation = _.find(relations, {
        coffeeId: coffee.id,
        role: ADMIN_ROLE,
      });

      if (!relation || !relation.userId) {
        continue;
      }

      const user = await userService.getById(relation.userId);

      if (_.isEmpty(user)) {
        continue;
      }

      const coffeeWithUser = _.assign(coffee, {
        time_from: coffee.time_from.seconds,
        time_to: coffee.time_to.seconds,
        user,
      });

      allCoffeesWithUsers.push(coffeeWithUser);
    }

    return allCoffeesWithUsers;
  }

  static async getAllCoffee(timeFrom, timeTo) {
    const state = store.getState();
    // eslint-disable-next-line
    const userId = _.get(state, "userState.id");
    // eslint-disable-next-line
    const myLocation = _.get(state, "mapState.currentLocation");
    let searchedTime = timeFrom;

    if (timeFrom < moment().unix()) {
      searchedTime = moment().unix();
    }

    const allRelations = await relationService.filterByField([
      {
        field: "status",
        condition: "==",
        value: ACTIVE_STATUS,
      },
      {
        field: "role",
        condition: "==",
        value: ADMIN_ROLE,
      },
      {
        field: "time",
        condition: ">",
        value: FormatHelper.getTimestamp(searchedTime),
      },
    ]);

    const allCoffees = await coffeeService.filterByField([
      {
        field: "time_to",
        condition: ">",
        value: FormatHelper.getTimestamp(searchedTime),
      },
    ]);

    if (_.isEmpty(allRelations) || _.isEmpty(allCoffees)) {
      return [];
    }

    const coffeeInMyTimeRange = allCoffees.filter((coffee) => {
      return (
        coffee.time_to.seconds >= timeFrom
        //  coffee.time_from.seconds < timeTo
        // && coffee.time_to.seconds > moment().unix() + coffee.duration_time * 60
      );
    });

    // const coffeeInMyLocationRadius = coffeeInMyTimeRange.filter((coffee) => {
    //   const cafes = _.get(coffee, "coffees", []);
    //   if (_.isEmpty(cafes)) {
    //     return true;
    //   }

    //   return coffee.coffees.some((cafe) => {
    //     return MapHelper.getDistanceBetween(myLocation, cafe) < 50000;
    //   });
    // });

    // const filteredCoffee = allRelations.filter(
    //   (relation) => relation.userId !== userId
    // );

    const filteredForMaxUsersInWebinarCoffees = [];

    for (const coffee of coffeeInMyTimeRange) {
      const isWebinar = coffee.type === MeetingTypes.ZOOM_GROUP;
      if (isWebinar) {
        const participantsCount = await this.getWebinarUsersCount(coffee.id);

        if (participantsCount < 100) {
          filteredForMaxUsersInWebinarCoffees.push(coffee);
        }
        continue;
      }
      filteredForMaxUsersInWebinarCoffees.push(coffee);
    }

    const allCoffeesWithUsers = await this.mergeCoffeeWithUsers(
      filteredForMaxUsersInWebinarCoffees,
      allRelations
    );

    return allCoffeesWithUsers;
  }

  static async getCoffeeSoon(timeFrom, timeTo) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const myLocation = _.get(state, "mapState.currentLocation");
    const allRelations = await relationService.filterByField([
      {
        field: "status",
        condition: "==",
        value: ACTIVE_STATUS,
      },
      {
        field: "role",
        condition: "==",
        value: ADMIN_ROLE,
      },
      {
        field: "time",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const coffeeBeforeMyFromTime = await coffeeService.filterByField([
      {
        field: "time_to",
        condition: ">",
        value: FormatHelper.getTimestamp(timeFrom),
      },
    ]);

    if (_.isEmpty(coffeeBeforeMyFromTime) || _.isEmpty(allRelations)) {
      return [];
    }

    const coffeeInMyTimeRange = coffeeBeforeMyFromTime.filter((coffee) => {
      return (
        coffee.time_from.seconds < timeTo &&
        coffee.time_to.seconds > moment().unix() + coffee.duration_time * 60
      );
    });

    if (_.isEmpty(coffeeInMyTimeRange)) {
      return [];
    }

    const coffeeInMyLocationRadius = coffeeInMyTimeRange.filter((coffee) => {
      const cafes = _.get(coffee, "coffees", []);
      if (_.isEmpty(cafes)) {
        return true;
      }

      return coffee.coffees.some((cafe) => {
        return MapHelper.getDistanceBetween(myLocation, cafe) < 4238;
      });
    });

    const filteredCoffee = allRelations.filter(
      (relation) => relation.userId !== userId
    );

    const allCoffeesWithUsers = await this.mergeCoffeeWithUsers(
      coffeeInMyLocationRadius,
      filteredCoffee
    );

    return allCoffeesWithUsers;
  }

  static async mergePastCoffeesWithUsers(coffees, relations) {
    const allCoffeesWithUsers = [];
    for (let coffee of coffees) {
      const relation = _.find(relations, {
        coffeeId: coffee.id,
        role: USER_ROLE,
      });

      if (!relation || !relation.userId) {
        continue;
      }

      const user = await userService.getById(relation.userId);

      if (_.isEmpty(user)) {
        continue;
      }

      const coffeeWithUser = _.assign(coffee, {
        time_from: { seconds: coffee.time_from.seconds },
        time_to: { seconds: coffee.time_to.seconds },
        user,
      });

      allCoffeesWithUsers.push(coffeeWithUser);
    }

    return allCoffeesWithUsers;
  }

  static async getPastCoffeesByTime() {
    const searchedTime = moment()
      .subtract("days", 1)
      .unix();

    const currentTime = moment().unix();

    const state = store.getState();
    const userId = _.get(state, "userState.id");

    const allRelations = await relationService.filterByField([
      {
        field: "status",
        condition: "==",
        value: PAST_STATUS,
      },
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
    ]);

    if (_.isEmpty(allRelations)) {
      return [];
    }

    const filteredRelationsByTime = allRelations.filter((item) => {
      return (
        item.meetingStartTime.seconds > searchedTime &&
        item.meetingEndTime.seconds < currentTime
      );
    });

    if (_.isEmpty(filteredRelationsByTime)) {
      return [];
    }
    const allCoffees = [];

    for (const relation of filteredRelationsByTime) {
      const coffee = await coffeeService.getById(relation.coffeeId);
      allCoffees.push(coffee);
    }

    if (_.isEmpty(allCoffees)) {
      return [];
    }

    const allCoffeesWithUsers = await this.mergePastCoffeesWithUsers(
      allCoffees,
      allRelations
    );

    const notRatedCoffees = [];
    for (const coffee of allCoffeesWithUsers) {
      const coffeeWithUsersAndAdmin = await this.getPastCoffeeWithAdminAndUser(
        coffee.id
      );
      notRatedCoffees.push(coffeeWithUsersAndAdmin);
    }

    return notRatedCoffees;
  }

  static async deleteAllUserRelations() {
    const state = store.getState();
    const userId = _.get(state, "userState.id");
    const allMyRelations = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
    ]);

    if (_.isEmpty(allMyRelations)) {
      return;
    }
    // eslint-disable-next-line
    allMyRelations.map((relation) => {
      relationService.delete(relation.id);
    });
  }

  static async getWebinarUsersCount(coffeeId) {
    const usersRelations = await relationService.filterByField([
      {
        field: "coffeeId",
        condition: "==",
        value: coffeeId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
    ]);

    if (usersRelations === null) {
      return 1;
    }
    return usersRelations.length + 1;
  }
}
