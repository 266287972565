import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import Home from '../components/home';

export function mapStateToProps({ userState, mapState, coffeeState }) {
  return {
    user: userState,
    currentLocation: mapState.currentLocation,
    fakeCards: coffeeState.fakeCards,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: payload => dispatch(actions.setUserInfo(payload)),
    setSignInPageStatus: payload =>
      dispatch(actions.setSignInPageStatus(payload)),
    setFakeCards: payload => dispatch(actions.setFakeCards(payload)),
    setPopUpNotificationText: payload =>
      dispatch(actions.setPopUpNotificationText(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
