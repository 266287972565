import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import MapSearch from '../components/map-search';

export function mapStateToProps({ mapState }) {
  return {
    currentLocation: mapState.currentLocation,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setCurrentLocation: payload =>
      dispatch(actions.setCurrentLocation(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MapSearch);
