import React from 'react';

import './ConfirmWindow.scss';

const ConfirmWidnow = ({ text, setOpenConfirmWindow, deleteAccount }) => {
  return (
    <div className="modal-window">
      <div className="modal-window-content">
        <p className="text">{text}</p>
        <button onClick={deleteAccount} className="accept">
          Delete Account
        </button>
        <button onClick={() => setOpenConfirmWindow(false)} className="decline">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ConfirmWidnow;
