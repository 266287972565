import React, { useState, useEffect, useRef, useMemo } from "react";
import { MdPlace } from "react-icons/md";
import { FaClock, FaRegCopy, FaMoneyBill } from "react-icons/fa";

import UserSmallAvatar from "../user-small-avatar";
import UserAvatarEdit from "../user-avatar-edit";
import ZoomIcon from "../../shared/icons/zoom-icon.svg";
import VoiceIcon from "../../shared/icons/voice-icon.svg";
import CoffeeClickedIcon from "../../shared/icons/coffee-clicked-icon.png";
import "./CoffeeCard.scss";
import * as _ from "lodash";
import moment from "moment";
import { TimePickerService } from "../../services/TimePicker";
import { RelationControlInvitationsService } from "../../services/RelationControl";
import { FutureMeetingsFirebaseService } from "../../services/FutureMeetings";
import { NotificationsService } from "../../services/Notifications";
import { UserFirebaseService } from "../../services/UserDB";
import { EmailService } from "../../services/Email";
import { FollowersService } from "../../services/Followers";
import Stripe from "../stripe";
import { MeetingTypes } from "../../shared/MeetingTypes";
import { StripeService } from "../../services/Stripe";
import { HistoryHelper } from "../../helpers";
import { TimeBasedEventFirebaseService } from "../../services/TimeBasedEventDB";
import { StripePaymentFirebaseService } from "../../services/StripePaymentsDB";
import { Tooltip } from "@material-ui/core";
import { APP_DOMAIN } from "../../shared/constants";
import useOutsideClick from "../../shared/OutsideClick";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { PaymentTypes } from "../../shared/PaymentTypes";

const followersService = new FollowersService();

const CoffeeCard = ({
  coffee,
  user,
  grabToCoffee,
  isAlmostOverTime = false,
  status = null,
  details = null,
  deleteInvitation = () => {},
  setPopUpNotificationText = () => {},
  setIsLoading = () => {},
  setSignInPageStatus = () => {},
  filterCoffeesAfterCancel = () => {},
  defaultSelectedCoffeeId,
  userState,
}) => {
  const futureMeetingsFirebaseService = new FutureMeetingsFirebaseService();
  const userService = new UserFirebaseService();
  const [isCoffeeCardOpened, setIsCoffeeCardOpened] = useState(
    defaultSelectedCoffeeId === coffee.id
  );
  const [isUserAvatarOpened, setUserAvatarOpened] = useState(false);
  const [copyLinkTitle, setCopyLinkTitle] = useState("Copy link");
  const [isFollowing, setFollowing] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const timeBasedEventFB = new TimeBasedEventFirebaseService();
  const stripePaymentFB = new StripePaymentFirebaseService();
  const [coffeeParticipants, setCoffeeParticipants] = useState([]);
  const [showParticipantModal, setShowParticipantModal] = useState(false);

  const coffeeCard = "coffee-card";
  const openedCoffeeCard = "opened-coffee-card";
  const cardUserId =
    (coffee.user && _.get(coffee, "user.id", null)) || _.get(user, "id", null);
  const isMyCard = userState.id === cardUserId;
  const modalContentRef = useRef();
  useOutsideClick(modalContentRef, () => setShowParticipantModal(false));
  const [sortOptions, setSortOptions] = useState({ paymentAmount: "desc" });

  const coffeeCardRef = useRef(null);

  useEffect(() => {
    if (isMyCard)
      stripePaymentFB
        .filterByField([
          {
            field: "paid",
            condition: "==",
            value: true,
          },
          {
            field: "coffeeId",
            condition: "==",
            value: coffee.id,
          },
        ])
        .then((coffeePayments) => {
          const paidPayments = _.filter(
            coffeePayments,
            (payment) => payment.paid
          );
          const userIds = _.map(paidPayments, "userId");

          return Promise.all(
            _.map(userIds, (userId) =>
              Promise.all([
                userService.getById(userId),
                Promise.resolve(_.find(paidPayments, { userId })),
              ])
            )
          );
        })
        .then((data) => {
          // setCoffeeParticipants(_([1, 2, 3, 4, 5, 6, 7, 8, 9, 0]).map(item => ({
          //   id: item,
          //   photoURL: "",
          //   name: Math.floor(Math.random() * 100) + "test",
          //   paymentAmount: Math.floor(data[0][1]?.payload?.amount * Math.random() * 100 * Math.random()),
          //   date: moment((data[0][1]?.date + Math.floor(Math.random() * 10000)) * 1000)
          // }))
          //   .orderBy(_.keys(sortOptions), _.values(sortOptions)).value())
          setCoffeeParticipants(
            _(data)
              .map((item) => ({
                // user: item[0],
                id: item[0]?.id,
                photoURL: item[0]?.photoURL,
                name: item[0]?.name,
                paymentAmount: item[1]?.payload?.amount,
                date: moment(item[1]?.date * 1000),
              }))
              .orderBy(_.keys(sortOptions), _.values(sortOptions))
              .value()
          );
        })
        .catch((e) => {
          console.log(e);
        });
  }, []);

  useEffect(() => {
    setCoffeeParticipants((prev) =>
      _.orderBy(prev, _.keys(sortOptions), _.values(sortOptions))
    );
  }, [sortOptions]);

  useEffect(() => {
    if (defaultSelectedCoffeeId === coffee.id)
      coffeeCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, []);

  useEffect(() => {
    const checkFollow = async () => {
      const followingStatus = await followersService.checkIsFollowing(user?.id);
      setFollowing(followingStatus);
    };

    checkFollow();
    // eslint-disable-next-line
  }, []);

  const onClickCoffeeCard = () => {
    setIsCoffeeCardOpened(!isCoffeeCardOpened);
  };

  const onClickUserAvatar = () => {
    setUserAvatarOpened(true);
  };

  const grabCoffeeWith = async () => {
    if (!userState.id) {
      setSignInPageStatus(true);
      return;
    }

    if (paymentAmount !== 0) {
      const canIJoin = await RelationControlInvitationsService.canIJoinWebinar(
        coffee.id,
        coffee.time_from,
        coffee.time_to
      );

      if (canIJoin) {
        setPaymentModal(true);
      } else {
        const meetings = await RelationControlInvitationsService.getMyAcceptedMeetings();
        if (!_.filter(meetings, (meeting) => meeting.coffee.id === coffee.id)) {
          setPopUpNotificationText({
            text: "You have already joined to talk at this time",
          });
        } else {
          HistoryHelper.redirectTo(`/user?coffeeId=${coffee.id}`);
        }
      }
      return;
    }
    if ([MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(coffeeType)) {
      await joinGroupTalk(true);
      return;
    }

    if (coffeeType === MeetingTypes.COFFEE_GROUP) {
      await joinGroupTalk(false);
      return;
    }
    grabToCoffee(coffee);
  };

  const joinGroupTalk = async (zoomMeeting) => {
    setIsLoading(true);
    let result = null;

    if (zoomMeeting) {
      result = await RelationControlInvitationsService.joinWebinar(
        coffee.id,
        coffee.time_from,
        coffee.time_to
      );

      if (result && coffee.type === MeetingTypes.LIVE) {
        HistoryHelper.redirectTo(`/user?coffeeId=${coffee.id}`);
      }
    } else {
      result = await RelationControlInvitationsService.joinGroupCafeMeeting(
        coffee.id,
        coffee.time_from,
        coffee.time_to,
        coffee.coffees[0]
      );
    }

    if (result === null) {
      const meetings = await RelationControlInvitationsService.getMyAcceptedMeetings();
      if (!_.filter(meetings, (meeting) => meeting.coffee.id === coffee.id)) {
        setPopUpNotificationText({
          text: "You have already joined to talk at this time",
        });
      } else {
        HistoryHelper.redirectTo(`/user?coffeeId=${coffee.id}`);
      }
      setIsLoading(false);

      return;
    }

    setIsLoading(false);

    const userId = _.get(coffee, "user.id", null);
    const futureMeeting = {
      meetingStartTime: coffee.time_from,
      coffeeShop: zoomMeeting ? "Zoom Webinar" : coffee.coffees[0]?.name,
      cardUserId: userId,
      cardAdminId: null,
    };

    await futureMeetingsFirebaseService.addFutureMeeting(futureMeeting);

    const user = await userService.getById(userId);
    const adminDeviceToken = _.get(user, "token", null);

    if (adminDeviceToken !== null) {
      await NotificationsService.sendNotification(
        adminDeviceToken,
        `${userState.name} has joined to your ${
          zoomMeeting ? "Zoom Webinar" : "Group Talk"
        } "${coffee.description}"`,
        "/"
      );
    }

    if (userState.email) {
      await EmailService.sendHtmlMessage(
        userState.email,
        `CoffeeTalk group talk`,
        `<p>Hi! You have successfully joined to ${
          zoomMeeting ? "webinar" : "group talk"
        } "${coffee.description}".</p>
        <p>
          <a href="${
            coffee.zoom_link
          }" target="_blank" rel="noopener noreferrer">Zoom link</a>
        </p>`
      );
    }

    setPopUpNotificationText({
      text: `You have joined "${coffee.description}" group Talk`,
    });
    setIsLoading(false);
  };

  const userAvatar = _.get(user, "photoURL");
  const userName = _.get(user, "name");
  const userShortName = _.split(userName, " ", 1);
  const vicinity = _.get(coffee, "coffees[0].vicinity", "Zoom");
  const paymentAmount = _.get(coffee, "payment_amount", 0);
  const coffeeType = _.get(coffee, "type", "");

  const cancelLiveMeeting = async () => {
    setIsLoading(true);
    await RelationControlInvitationsService.cancelLiveCoffee(coffee.id);
    filterCoffeesAfterCancel(coffee.id);
    setIsLoading(false);
  };

  const cardButtonText = () => {
    if (paymentAmount !== 0) {
      return "Join Talk";
    }
    if (coffeeType === MeetingTypes.LIVE) {
      return "Join Live Talk";
    }
    if (
      [MeetingTypes.ZOOM_GROUP, MeetingTypes.COFFEE_GROUP].includes(coffeeType)
    ) {
      return "Join Talk";
    }
    if (coffeeType === MeetingTypes.ZOOM_ONE_BY_ONE) {
      return `Join Talk`;
    }
    return `Grab a coffee with ${userShortName}`;
  };

  const meetingVicinity = () => {
    if (coffeeType === MeetingTypes.ZOOM_GROUP) {
      return "Zoom Group Talk";
    }
    if (coffeeType === MeetingTypes.ZOOM_ONE_BY_ONE) {
      return `Zoom`;
    }
    return vicinity;
  };

  const closeStripeModal = () => {
    setPaymentModal(false);
  };

  const getCardButton = () => {
    if (status) {
      return (
        <button
          className="coffee-card-button"
          onClick={(event) => {
            event.stopPropagation();
            deleteInvitation(_.get(details, "id"));
          }}
        >
          <span>I need an easy out of this talk</span>
        </button>
      );
    }

    if (_.isNull(status)) {
      return (
        <button
          className={
            vicinity === "Zoom" ? `zoom-card-button` : `coffee-card-button`
          }
          onClick={(event) => {
            event.stopPropagation();
            grabCoffeeWith();
          }}
        >
          {vicinity === "Zoom" ? (
            <img
              src={ZoomIcon}
              className="zoom-card-icon"
              alt="nav-bar-coffee"
            />
          ) : (
            <img
              src={CoffeeClickedIcon}
              className="coffee-card-cup"
              alt="coffee-cup"
            />
          )}
          <span>{cardButtonText()}</span>
        </button>
      );
    }

    return null;
  };

  const getCancelButton = () => {
    if (isCoffeeCardOpened && coffeeType === MeetingTypes.LIVE && isMyCard) {
      return (
        <button
          className="coffee-card-button"
          onClick={(event) => {
            event.stopPropagation();
            cancelLiveMeeting();
          }}
        >
          <span>Close live talk</span>
        </button>
      );
    }
    return null;
  };

  const follow = async () => {
    if (_.isEmpty(userState.id)) {
      setSignInPageStatus(true);
      return;
    }

    try {
      await followersService.follow(user);
    } catch (err) {}
    setFollowing(true);
  };

  const unfollow = async () => {
    try {
      await followersService.unfollow(user);
    } catch (err) {}
    setFollowing(false);
  };

  const getFollowButton = () => {
    if (isMyCard) {
      return null;
    }

    if (isFollowing)
      return (
        <button
          className="unfollow-button"
          onClick={(e) => {
            e.stopPropagation();
            unfollow();
          }}
        >
          Unfollow
        </button>
      );

    return (
      <button
        className="follow-button"
        onClick={(e) => {
          e.stopPropagation();
          follow();
        }}
      >
        Follow
      </button>
    );
  };

  const getAvatarClassName = () => {
    const defaultClassName = "coffee-card-user-img";

    if (coffeeType === MeetingTypes.LIVE) {
      return `${defaultClassName} live`;
    }

    return defaultClassName;
  };

  const getVicinityIcon = () => {
    if (vicinity === "Zoom")
      return (
        <img src={ZoomIcon} className="coffee-card-icon" alt="zoom-icon" />
      );

    return <MdPlace className="coffee-card-icon" />;
  };

  const getTimeIcon = () => {
    if ([MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(coffeeType)) {
      return (
        <img src={VoiceIcon} className="coffee-card-icon" alt="voice-icon" />
      );
    }

    return <FaClock className="coffee-card-icon" />;
  };

  const getTime = () => {
    if (coffeeType === MeetingTypes.LIVE) {
      return <span className="live-time">Live with {user.name}</span>;
    }

    const timeFrom =
      !_.isNull(details) && coffeeType !== MeetingTypes.ZOOM_GROUP
        ? _.get(details, "meetingStartTime.seconds", false)
        : _.get(coffee, "time_from", false);

    return `${
      TimePickerService.isTodayDate(timeFrom || coffee.time_from)
        ? "Today"
        : `${moment(timeFrom * 1000).format("ll")}`
    } from ${moment.unix(timeFrom || coffee.time_from).format("h:mm A")}`;
  };

  const createUserPayout = async () => {
    if (
      moment().isBefore(moment(coffee.time_from * 1000)) &&
      moment().diff(moment(coffee.time_from * 1000), "h") > 1
    )
      return;

    const coffeePayments = await stripePaymentFB.filterByField([
      {
        field: "paid",
        condition: "==",
        value: true,
      },
      {
        field: "coffeeId",
        condition: "==",
        value: coffee.id,
      },
    ]);

    if (!coffeePayments.length) {
      return;
    }

    let coffeeTimeBasedEvents = await timeBasedEventFB.filterByField([
      {
        field: "coffeeId",
        condition: "==",
        value: coffee.id,
      },
    ]);

    if (coffeeTimeBasedEvents?.length) {
      return;
    }

    await StripeService.createTransfer({
      stripeId: coffee.user.stripeId,
      userId: coffee.user.id,
      coffeeId: coffee.id,
    });
  };

  const showZoomLink = () => {
    if (isCoffeeCardOpened && isMyCard) {
      return (
        <>
          <div className="custom-hr"></div>
          <div className="coffee-details-text">
            <span className="title">Zoom Link</span>
            <a
              onClick={createUserPayout}
              href={coffee.zoom_link}
              rel="noopener noreferrer"
              target="_blank"
              className="zoom-link"
            >
              {coffee.zoom_link}
            </a>
          </div>
        </>
      );
    }
    return null;
  };

  function addCommaToNumbers(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const paymentType = useMemo(() => {
    switch (coffee.payment_type) {
      case PaymentTypes.FUNDRAISER:
        return 'Fundrasier talk';
      case PaymentTypes.PAID:
        return 'Paid Talk';
      default:
        return null;
    }
  }, [coffee]);

  return (
    <div
      id={coffee.id}
      onClick={onClickCoffeeCard}
      className={isCoffeeCardOpened ? openedCoffeeCard : coffeeCard}
      ref={coffeeCardRef}
    >
      {isUserAvatarOpened && (
        <UserAvatarEdit
          setUserAvatarOpened={setUserAvatarOpened}
          userAvatarLink={userAvatar}
          user={user}
          isUserAvatar={false}
        />
      )}
      <div className="coffee-card-user-avatar">
        <div
          onClick={(event) => {
            event.stopPropagation();
            onClickUserAvatar();
          }}
        >
          <UserSmallAvatar
            avatarClassName={getAvatarClassName()}
            userAvatarLink={userAvatar}
          />
        </div>
        {isCoffeeCardOpened && (
          <div className="coffee-card-user-name">
            <div>{userName}</div>
            {getFollowButton()}
          </div>
        )}
      </div>

      <div className="coffee-card-data">
        {isCoffeeCardOpened && isMyCard && (
          <div
            className="coffee-card-participants"
            onClick={(e) => {
              e.stopPropagation();
              setShowParticipantModal(true);
            }}
          >
            <div>
              <span>Participants</span>
              <span>{` - ${coffeeParticipants.length}`}</span>
              <span className="coffee-card-participants-amount">
                ($
                {addCommaToNumbers(
                  _.sumBy(coffeeParticipants, "paymentAmount") / 100
                )}{" "}
                USD)
              </span>
            </div>
          </div>
        )}
        <div className="data-container">
          <p
            className={
              isCoffeeCardOpened
                ? "coffee-card-title"
                : "coffee-card-title closed"
            }
          >
            {_.get(coffee, "description")}
          </p>

          {/* <p className="coffee-card-conversation-time">
           {_.get(coffee, "duration_time")} min conversation
           </p> */}
        </div>
        <div className="data-container time">
          <p
            className={`coffee-card-meet-info ${
              isAlmostOverTime ? "almost-over-time" : ""
            }`}
          >
            {getTimeIcon()}
            {getTime()}
          </p>
          <p className="coffee-card-meet-info">
            {getVicinityIcon()}
            {meetingVicinity()}
          </p>

          {isMyCard && (
            <Tooltip title={copyLinkTitle} placement="top">
              <p
                className="coffee-card-meet-info"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setCopyLinkTitle("Link copied");
                  navigator.clipboard.writeText(
                    `${APP_DOMAIN}?coffeeId=${coffee.id}`
                  );
                  setTimeout(() => setCopyLinkTitle("Copy link"), 2000);
                }}
              >
                <FaRegCopy className="coffee-card-icon" />
                Copy link
              </p>
            </Tooltip>
          )}
          {paymentAmount !== 0 && (
            <Tooltip
              title={`In order to join the talk you need to pay minimum $${paymentAmount} USD`}
              placement="top"
            >
              <p className="coffee-card-meet-info">
                <FaMoneyBill className="coffee-card-icon" />
                ${paymentAmount} USD - {paymentType}
              </p>
            </Tooltip>
          )}
        </div>
        {showZoomLink()}
        {isCoffeeCardOpened && !isMyCard ? getCardButton() : getCancelButton()}
      </div>

      {paymentModal && (
        <Stripe
          closeStripeModal={closeStripeModal}
          setPopUpNotificationText={setPopUpNotificationText}
          user={userState}
          coffee={coffee}
        />
      )}

      {showParticipantModal && (
        <div
          className="participants-modal-background"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="participants-modal" ref={modalContentRef}>
            <div className="participants-modal-header">
              <div>
                {`Participants ${coffeeParticipants.length} `}
                <span className="coffee-card-participants-amount">
                  ($
                  {addCommaToNumbers(
                    _.sumBy(coffeeParticipants, "paymentAmount") / 100
                  )}{" "}
                  USD)
                </span>
              </div>
              <IoMdClose onClick={() => setShowParticipantModal(false)} />
            </div>
            <div className="participants-modal-body">
              <div className="participants-modal-body-header">
                <div className="number">No.</div>
                <div className="name">
                  Name{" "}
                  <IoIosArrowDown
                    onClick={() =>
                      setSortOptions((prev) => ({
                        name: prev?.name === "desc" ? "asc" : "desc",
                      }))
                    }
                    className={sortOptions.name === "asc" ? "rotated" : ""}
                  />
                </div>
                <div className="date">
                  Registration{" "}
                  <IoIosArrowDown
                    onClick={() =>
                      setSortOptions((prev) => ({
                        date: prev?.date === "desc" ? "asc" : "desc",
                      }))
                    }
                    className={sortOptions.date === "asc" ? "rotated" : ""}
                  />
                </div>
                <div className="amount">
                  Price{" "}
                  <IoIosArrowDown
                    onClick={() =>
                      setSortOptions((prev) => ({
                        paymentAmount:
                          prev?.paymentAmount === "desc" ? "asc" : "desc",
                      }))
                    }
                    className={
                      sortOptions.paymentAmount === "asc" ? "rotated" : ""
                    }
                  />
                </div>
              </div>
              {_.map(coffeeParticipants, (participant, index) => (
                <div
                  key={participant.id}
                  className="participants-modal-body-item"
                >
                  <div className="number">{index + 1}</div>
                  <div className="name">
                    <img
                      className="participant-user-img"
                      alt="User"
                      src={participant.photoURL}
                      referrerPolicy="no-referrer"
                    />
                    {participant.name}
                  </div>
                  <div className="date">
                    {participant.date.format("DD MMM")}
                  </div>
                  <div className="amount">
                    ${addCommaToNumbers(participant.paymentAmount / 100)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoffeeCard;
