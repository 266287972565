import React from 'react';

import VerificationsIcon from '../../shared/icons/verification-icon.svg';
import NotVerificationsIcon from '../../shared/icons/not-verification-icon.svg';

import './Verifications.scss';

const Verifications = ({ authMethods }) => {
  const verificationType = (name, verification) => {
    return (
      <>
        <img
          src={verification ? VerificationsIcon : NotVerificationsIcon}
          className={
            verification ? 'verification-icon' : 'not-verification-icon'
          }
          alt="verification"
        />
        {name}
      </>
    );
  };

  return (
    <div className="verifications-container">
      <div className="verifications">
        {verificationType('Facebook', authMethods.facebook)}
        {verificationType('Google', authMethods.google)}
        {verificationType('Apple', authMethods.apple)}
      </div>
    </div>
  );
};

export default Verifications;
