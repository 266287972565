import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import GrabCoffeePage from '../components/grab-coffee-page';

export function mapStateToProps({ coffeeState, userState }) {
  return {};
}

export function mapDispatchToProps(dispatch) {
  return {
    setPopUpNotificationText: payload =>
      dispatch(actions.setPopUpNotificationText(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GrabCoffeePage);
