import React, { useEffect } from "react";

import { StripeService } from "../../services/Stripe";
import { HistoryHelper } from "../../helpers";
import Spinner from "../../components/spinner";
import "./Return.scss";

function StripeReturn({
  user,
  setPopUpNotificationText,
  setStripeModalStatus,
}) {
  useEffect(() => {
    if (!user.id) {
      return;
    }

    if (!user.stripeId) {
      HistoryHelper.redirectTo("/");
    }

    StripeService.getAccountData(user.stripeId)
      .then((res) => {
        if (res.charges_enabled) {
          setStripeModalStatus("done");
        } else {
          setStripeModalStatus("pending");
        }
      })
      .catch((err) => {
        const msg = err?.message || "Something went wrong. Try again later.";
        setPopUpNotificationText({
          text: msg,
        });
      })
      .finally(() => {
        HistoryHelper.redirectTo("/");
      });
  }, [user]); // eslint-disable-line

  return (
    <div className="return">
      <Spinner loaderContainerName="loader-container" blockName="modal" />
    </div>
  );
}

export default StripeReturn;
