import React, { useEffect, useState, useRef } from "react";

import useOutsideClick from "../../shared/OutsideClick";
import EditPopUpDeleteButton from "../../shared/icons/edit-pop-up-delete-button.svg";
import LeftArrow from "../../shared/icons/left-arrow.svg";
import { FiUser } from "react-icons/fi";
import Verifications from "../verifications";

import { Storage } from "../../services/Storage";
import { UserFirebaseService } from "../../services/UserDB";
import { FollowersService } from "../../services/Followers";

import Carousel from "nuka-carousel";

import * as _ from "lodash";
import "./UserAvatarEdit.scss";
import { useLocalStorage } from "../../shared/LocalStorageHook";

const followersService = new FollowersService();

const UserFullScreenAvatar = ({
  setUserAvatarOpened = () => {},
  userAvatarLink,
  setUserInfo,
  setIsLoadingAvatar,
  user,
  isUserAvatar = false,
}) => {
  const userFirebaseService = new UserFirebaseService();
  const [imageOrder, setImageOrder] = useState(1);
  const [isFollowing, setFollowing] = useState(null);
  const [localStorageUser, setLocalStorageUser] = useLocalStorage("user", null);

  const popUpRef = useRef(null);

  const closePopUp = () => {
    setUserAvatarOpened(false);
  };

  useOutsideClick(popUpRef, closePopUp);

  useEffect(() => {
    const checkFollow = async () => {
      const followingStatus = await followersService.checkIsFollowing(user?.id);
      setFollowing(followingStatus);
    };

    checkFollow();
    // eslint-disable-next-line
  }, []);

  const getPopUpClassName = () => {
    if (isUserAvatar) {
      return "user-avatar-edit-pop-up small";
    }

    let infoCounter = 0;

    if (user?.age) infoCounter++;
    if (user?.workplace) infoCounter++;
    if (user?.education) infoCounter++;

    if (infoCounter === 0) {
      return "user-avatar-edit-pop-up empty";
    }

    if (infoCounter === 1) {
      return "user-avatar-edit-pop-up one";
    }

    if (infoCounter === 2) {
      return "user-avatar-edit-pop-up two";
    }

    return "user-avatar-edit-pop-up";
  };

  const getAvatarClassName = () => {
    if (isUserAvatar) {
      return "user-avatar-edit small";
    }

    let infoCounterEdit = 0;

    if (user?.age) infoCounterEdit++;
    if (user?.workplace) infoCounterEdit++;
    if (user?.education) infoCounterEdit++;

    if (infoCounterEdit === 0) {
      return "user-avatar-edit empty";
    }

    if (infoCounterEdit === 1) {
      return "user-avatar-edit one";
    }

    if (infoCounterEdit === 2) {
      return "user-avatar-edit two";
    }

    return "user-avatar-edit";
  };

  const uploadImage = async (e) => {
    e.preventDefault();
    setIsLoadingAvatar(true);

    const uploadedImage = e.target.files[0];
    const userAvatarList = getUserAvatarList();

    const linkToUploadImage = await Storage.loadPictureToStorage(uploadedImage);

    const newUserAvatarList = [
      linkToUploadImage,
      ...userAvatarList.slice(0, 2),
    ];

    const updatedUser = _.assign({}, user, { photoURL: newUserAvatarList });

    await userFirebaseService.updateUser(updatedUser);
    setUserInfo(updatedUser);
    setLocalStorageUser(updatedUser);
    setIsLoadingAvatar(false);
  };

  const follow = async () => {
    await followersService.follow(user);
    setFollowing(true);
  };

  const unfollow = async () => {
    await followersService.unfollow(user);
    setFollowing(false);
  };

  const uploadButton = () => {
    return (
      <div className="upload-button-container">
        <input
          id="file-upload"
          type="file"
          accept="image/png, image/jpeg"
          onChange={uploadImage}
        />
        <label htmlFor="file-upload" className="upload-avatar">
          Upload Photo
        </label>
      </div>
    );
  };

  const getFollowButton = () => {
    if (!user?.id || user?.id === localStorageUser?.id) {
      return null;
    }

    if (isFollowing)
      return (
        <button className="unfollow-button" onClick={unfollow}>
          Unfollow
        </button>
      );

    if (isFollowing === false)
      return (
        <button className="follow-button" onClick={follow}>
          Follow
        </button>
      );
  };

  const userDetails = () => {
    return (
      <div className="user-details">
        {user.name && (
          <div className="user-name-container">
            <div className="follow">
              <span className="name">{user.name}</span>
              {getFollowButton()}
            </div>
            {user.authMethods && (
              <div className="verification">
                <Verifications authMethods={user.authMethods} />
              </div>
            )}
          </div>
        )}

        {user.age && (
          <div className="detail-container">
            <div className="detail">Age</div>
            <div className="detail value">{user.age}</div>
          </div>
        )}

        {user.education && (
          <div className="detail-container">
            <div className="detail">Education</div>
            <div className="detail value">{user.education}</div>
          </div>
        )}

        {user.workplace && (
          <div className="detail-container">
            <div className="detail">Workplace</div>
            <div className="detail value">{user.workplace}</div>
          </div>
        )}
      </div>
    );
  };

  const getUserAvatarList = () => {
    if (_.isArray(userAvatarLink)) {
      const movedEmptyAvatarsToTail = userAvatarLink.filter((a) => a !== null);
      if (movedEmptyAvatarsToTail.length === 1) {
        return movedEmptyAvatarsToTail.concat([null, null]);
      }
      if (movedEmptyAvatarsToTail.length === 2) {
        return movedEmptyAvatarsToTail.concat([null]);
      }
      return movedEmptyAvatarsToTail;
    }

    return [userAvatarLink, null, null];
  };

  const userAvatars = () => {
    if (_.isArray(userAvatarLink)) {
      return userAvatarLink.map((link, id) => {
        if (_.isNull(link)) {
          return null;
        }

        return <img src={link} key={id} alt="user avatar" />;
      });
    }

    if (!_.isEmpty(userAvatarLink) && isUserAvatar) {
      return [...Array(3)].map((el, id) => {
        if (id === 0) {
          return <img src={userAvatarLink} key={id} alt="user avatar" />;
        }
        return null;
      });
    }

    if (!_.isEmpty(userAvatarLink)) {
      return <img src={userAvatarLink} alt="user avatar" />;
    }

    return <FiUser className="empty-avatar" />;
  };

  const deleteImage = async () => {
    setIsLoadingAvatar(true);
    const userAvatarList = getUserAvatarList();

    const newUserAvatarList = userAvatarList
      .map((link, id) => {
        if (+id + 1 === imageOrder) {
          return null;
        }
        return link;
      })
      .sort();

    const updatedUser = _.assign({}, user, { photoURL: newUserAvatarList });

    await userFirebaseService.updateUser(updatedUser);
    setUserInfo(updatedUser);
    setLocalStorageUser(updatedUser);
    setIsLoadingAvatar(false);
  };

  return (
    <div
      className="user-avatar-edit-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={getPopUpClassName()} ref={popUpRef}>
        {isUserAvatar && (
          <img
            className="close"
            src={EditPopUpDeleteButton}
            onClick={(event) => {
              event.stopPropagation();
              deleteImage();
            }}
            alt="delete"
          />
        )}

        {isUserAvatar && uploadButton()}

        <div className={getAvatarClassName()}>
          <Carousel
            dragging={true}
            afterSlide={(slideIndex) => {
              setImageOrder(slideIndex + 1);
            }}
            renderCenterLeftControls={({ previousSlide }) => (
              <button className="left-arrow" onClick={previousSlide}>
                <img src={LeftArrow} alt="previous" />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button className="right-arrow" onClick={nextSlide}>
                <img src={LeftArrow} alt="next" />
              </button>
            )}
            defaultControlsConfig={{
              nextButtonText: "Custom Next",
              prevButtonText: "Customn Prev",
              pagingDotsStyle: {
                fill: "#fa6400",
              },
            }}
            disableEdgeSwiping={true}
          >
            {userAvatars()}
          </Carousel>
        </div>
        {!isUserAvatar && userDetails()}
      </div>
    </div>
  );
};

export default UserFullScreenAvatar;
