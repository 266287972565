import React from "react";
import { TimePickerService } from "../../services/TimePicker";

import "./InvitationDetails.scss";
import * as _ from "lodash";
import * as moment from "moment";

const coffeeDetailsData = ({ invitation }) => {
  const cafe = _.get(invitation, "selectedCafe");
  const timeFrom = _.get(invitation, "coffee.time_from");

  return (
    <React.Fragment>
      <div className="grab-coffee-meeting-details-titles">
        <div className="coffee-details-text">
          <span className="title">Meeting with</span>
          <span className="bold-details-text">
            {_.get(invitation, "user.name")}
          </span>
        </div>

        <div className="coffee-details-text">
          <span className="title">Time & Date</span>
          <div className="grab-coffee-meeting-details-time">
            <div className="text-button-container">
              <span className="bold-details-text">
                {TimePickerService.isTodayDate(timeFrom)
                  ? "Today at"
                  : `${moment(timeFrom * 1000).format("ll")}`}
                {" at "}
                {TimePickerService.getTimeFromMS(
                  _.get(invitation, "meetingStartTime.seconds") * 1000
                )}
              </span>
            </div>

            {/* <div className="details-text">
              <span className="details-text">
                for a {_.get(invitation, "coffee.duration_time")} minute
                conversation
              </span>
            </div> */}
          </div>
        </div>

        {cafe !== null && (
          <>
            <div className="coffee-details-text">
              <span className="title">Coffee shop</span>
              <div className="grab-coffee-meeting-details-coffee-shop">
                <span className="bold-details-text">
                  {_.get(invitation, "selectedCafe.name")}
                </span>
                <span className="details-text">
                  {_.get(invitation, "selectedCafe.vicinity")}
                </span>
              </div>
            </div>

            <div className="coffee-details-text">
              <span className="title">Offer to pay?</span>
              <span className="offer-to-pay-text">
                {_.get(invitation, "user.name")} is{" "}
                {_.get(invitation, "offerToPay") ? "" : "not"} offering to pay
                for coffee
              </span>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default coffeeDetailsData;
