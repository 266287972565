import * as constants from "../constants";

const initialState = {
  availabilityPageStatus: false,
  splashStatus: false,
  signInPageStatus: false,
  stripeModalStatus: "",
  signInPageText: "Log In",
};

const actionHandlers = {
  [constants.SET_AVAILABILITY_PAGE_STATUS](state, appPagesStatus) {
    return { ...state, ...appPagesStatus };
  },
  [constants.SET_SIGN_IN_PAGE](state, signInPageStatus) {
    return { ...state, signInPageStatus };
  },
  [constants.SET_SIGN_IN_PAGE_TEXT](state, signInPageText) {
    return { ...state, signInPageText };
  },
  [constants.SET_SPLASH_STATUS](state, splashStatus) {
    return { ...state, splashStatus };
  },
  [constants.SET_STRIPE_MODAL_STATUS](state, stripeModalStatus) {
    return { ...state, stripeModalStatus };
  },
};

export default function appPagesStatus(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
