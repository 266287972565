export class PermissionsService {
  static async getLocationPermission() {
    const getAccessToUserLocation = function() {
      return new Promise(resolve => {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve(true);
          },
          error => {
            resolve(false);
          }
        );
      });
    };
    const access = await getAccessToUserLocation();
    return access;
  }
}
