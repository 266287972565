import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import GoogleIcon from "../../shared/icons/google-icon.png";
import { facebookIcon } from "../../shared/icons/icons";
import AppleIcon from "../../shared/icons/apple-icon.png";
import { IoMdClose } from "react-icons/io";

import { FireBase } from "../../services";
import * as _ from "lodash";

import "./Auth.scss";
import { useLocalStorage } from "../../shared/LocalStorageHook";
import history from "../../history";
import { NavLinkHelper } from "../../helpers/NavLink";
import { setSignInPageText } from "../../redux/actions";
import { APP_EMAIL_DOMAIN } from "../../shared/constants";

const firebaseApp = FireBase.getInstance().getFirebaseApp();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");

appleAuthProvider.addScope("email");
appleAuthProvider.addScope("name");

const getTextForAuthButtons = (signInPageText) => {
  if (!_.isUndefined(signInPageText)) {
    const buttonText = signInPageText.replace(
      /Up|In/g,
      signInPageText.slice(-2).toLowerCase()
    );
    return buttonText;
  }
  setSignInPageText("Log In");
  return "Log in";
};

const getUpperAuthText = (authText) => {
  const slicedText = authText.slice(-2);
  const upperText = slicedText.charAt(0).toUpperCase() + slicedText.charAt(1);
  const buttonText = authText.replace(/up|in/g, upperText);
  return buttonText;
};

const SignInScreen = (props) => {
  const {
    setSignInPageStatus,
    setTitle = () => {},
    closeModalOnGuestPage,
    signInPageText,
    setSignInPageText,
    setPopUpNotificationText,
  } = props;

  const [authButtonsText, setAuthButtonsText] = useState(
    getTextForAuthButtons(signInPageText)
  );
  // eslint-disable-next-line
  const [userFromLocalStorage, setUser] = useLocalStorage("user", null);
  const [currentTitle] = useState(
    NavLinkHelper.getTitleByPathname(history.location.pathname)
  );

  const handleAuthError = (err) => {
    if (err && err?.message) {
      setPopUpNotificationText({ text: err.message });
    }
  };

  const callAppleSignIn = () => {
    firebaseApp
      .auth()
      .signInWithRedirect(appleAuthProvider)
      .catch((err) => {
        handleAuthError(err);
      });
  };

  const callGoogleSignIn = () => {
    firebaseApp
      .auth()
      .signInWithRedirect(googleAuthProvider)
      .catch((err) => {
        handleAuthError(err);
      });
  };

  const callFacebookSignIn = () => {
    firebaseApp
      .auth()
      .signInWithRedirect(facebookAuthProvider)
      .catch((err) => {
        handleAuthError(err);
      });
  };

  useEffect(() => {
    setTitle(getUpperAuthText(authButtonsText));
    return () => {
      setTitle(currentTitle);
    };
    // eslint-disable-next-line
  }, []);

  const authState = () => {
    if (authButtonsText === "Sign up") {
      return (
        <div className="auth-state">
          {"Already have an account? "}
          <button className="sign-button" onClick={changeTitle}>
            Log In
          </button>
        </div>
      );
    }
    return (
      <div className="auth-state">
        {"Don’t have an account?  "}
        <button className="sign-button" onClick={changeTitle}>
          Sign Up
        </button>
      </div>
    );
  };

  const changeTitle = () => {
    if (authButtonsText === "Log in") {
      setTitle("Sign Up");
      setAuthButtonsText("Sign up");
      setSignInPageText("Sign Up");
      return;
    }
    setTitle("Log In");
    setAuthButtonsText("Log in");
    setSignInPageText("Log In");
  };

  return (
    <div className="auth-container">
      <div className="auth-content">
        <div className="modal-window-header">
          <div className="back-button-with-icon">
            <IoMdClose
              className="close-navigation"
              onClick={() => {
                !_.isUndefined(closeModalOnGuestPage)
                  ? closeModalOnGuestPage(false)
                  : setSignInPageStatus(false);
              }}
            />
            <h1>{getUpperAuthText(authButtonsText)}</h1>
          </div>
        </div>
        <div className="auth-buttons">
          <div className="app-title">CoffeeTalk</div>
          <button
            className="gmail-button"
            type="button"
            onClick={callGoogleSignIn}
          >
            <img className="social-icon" src={GoogleIcon} alt="google" />
            {authButtonsText} with Google
            <div className="social-icon"></div>
          </button>
          {/*<button*/}
          {/*  className="facebook-button"*/}
          {/*  type="button"*/}
          {/*  onClick={callFacebookSignIn}*/}
          {/*>*/}
          {/*  {facebookIcon()}*/}
          {/*  {authButtonsText} with Facebook*/}
          {/*  <div className="social-icon"></div>*/}
          {/*</button>*/}
          <button
            className="apple-button"
            type="button"
            onClick={callAppleSignIn}
          >
            <img className="social-icon" src={AppleIcon} alt="apple" />
            {authButtonsText} with Apple
            <div className="social-icon"></div>
          </button>
          {authState()}
        </div>

        <div className="bottom-links">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          <div className="dot">•</div>
          <a href={`mailto:help@${APP_EMAIL_DOMAIN}`}>Contact us</a>
          <div className="dot">•</div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/term-of-use"
          >
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  )
};

export default SignInScreen;
