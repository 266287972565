import React from 'react';

import './SettingInput.scss';

const SettingInput = ({
  placeholder = '',
  inputValue = '',
  setInputValue = () => {},
  type = 'text',
  onInputCancel = () => {},
  onInputSave = () => {},
}) => {
  return (
    <div className="setting-input-container">
      <input
        placeholder={placeholder}
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        type={type}
        min="16"
        step="1"
      />
      <div className="setting-buttons-container">
        <button className="setting-cancel-button" onClick={onInputCancel}>
          Cancel
        </button>
        <button className="setting-save-button" onClick={onInputSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default SettingInput;
