import React from 'react';
import { Link } from 'react-router-dom';

import './InvitationsList.scss';
import UserSmallAvatar from '../user-small-avatar';
import { TimePickerService } from '../../services/TimePicker';
import useWindowDimensions from '../../shared/windowResize';
import { MOBILE_WIDTH } from '../../shared/constants';

import * as _ from 'lodash';

const InvitationsList = ({ invitations = [] }) => {
  const { screenWidth } = useWindowDimensions();

  const isMobileDevice = screenWidth < MOBILE_WIDTH;

  return invitations.map((invitation, index) => {
    return (
      <div className="invitations-container" key={index}>
        <UserSmallAvatar
          userAvatarLink={_.get(invitation, 'user.photoURL')}
          avatarClassName="interlocutor-avatar"
        />
        <div className="invitation-text-container">
          <p className="invitation-text">
            {_.get(invitation, 'user.name')} invited you to{' '}
            {_.get(invitation, 'coffee.duration_time')} minute coffee meeting.{' '}
            {isMobileDevice && (
              <span className="invitation-time">
                {TimePickerService.getTimeDiff(
                  _.get(invitation, 'meetingStartTime.seconds')
                )}
              </span>
            )}
          </p>
          {!isMobileDevice && (
            <span className="invitation-time">
              {TimePickerService.getTimeDiff(
                _.get(invitation, 'meetingStartTime.seconds')
              )}
            </span>
          )}
        </div>
        <Link className="see-details" to={'notification/' + invitation.id}>
          See details
        </Link>
      </div>
    );
  });
};

export default InvitationsList;
