const url = "https://us-central1-coffee-me-dev.cloudfunctions.net/app/stripe";
// const url = "http://localhost:4242";
export class StripeService {
  static async createStripeAccount() {
    const createAccountUrl = `${url}/create-account`;

    try {
      const account = await fetch(createAccountUrl, {
        method: "POST",
      }).then((res) => res.json());
      return account;
    } catch (err) {
      return null;
    }
  }

  static async getAccountData(accountId) {
    const accountUrl = `${url}/account/${accountId}`;

    try {
      const account = await fetch(accountUrl).then((res) => res.json());
      return account;
    } catch (err) {
      return null;
    }
  }

  static async getAccountLink(accountId) {
    const accountUrl = `${url}/account-link/${accountId}`;

    try {
      const account = await fetch(accountUrl).then((res) => res.json());
      return account;
    } catch (err) {
      return null;
    }
  }

  static async createPayment(body) {
    return fetch(`${url}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        const clientSecret = data.clientSecret;
        return clientSecret;
      })
      .catch((err) => {
        return null;
      });
  }

  static async createTransfer(body) {
    return fetch(`${url}/create-transfer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .catch((err) => {
        return null;
      });
  }
}
