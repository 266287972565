import * as _ from 'lodash';

export const getBlockWidthByTagName = className => {
  const block = document.getElementsByClassName(className);

  if (_.isUndefined(block[0])) {
    return '100%';
  }

  const blockWidth = block[0].getBoundingClientRect('').width; // 'main' block width

  const roundedWidth = Math.floor(blockWidth);
  return roundedWidth;
};
