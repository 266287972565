import { UserFirebaseService } from "./UserDB";
import { CoffeeFirebaseService } from "./CoffeeDB";
import { RelationsFirebaseService } from "./RelationsDB";
import { RelationControlCoffeeService } from "./RelationControlCoffee";

import { store } from "./../redux/store/index";
import * as _ from "lodash";
import { FormatHelper } from "../helpers";
import moment from "moment";
import {
  ACTIVE_STATUS,
  PAST_STATUS,
  USER_ROLE,
  ADMIN_ROLE,
} from "./RelationControlCoffee";
import { MeetingTypes } from "../shared/MeetingTypes";

const userService = new UserFirebaseService();
const coffeeService = new CoffeeFirebaseService();
const relationService = new RelationsFirebaseService();

export class RelationControlInvitationsService {
  static async sendInvitation(
    coffeeId,
    selectedCafe,
    interests,
    meetingStartTime,
    meetingEndTime,
    offerToPay
  ) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const myCurrentInvitations = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "meetingEndTime",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const currentInvitationsInNewCoffeeInvitationTimeRange = _.filter(
      myCurrentInvitations,
      (relation) => {
        return (
          (relation.meetingStartTime.seconds < meetingEndTime &&
            relation.meetingEndTime.seconds > meetingStartTime) ||
          relation.coffeeId === coffeeId
        );
      }
    );

    const myApprovedMeeting = await this.getMyAcceptedMeetings();

    const isAcceptedInvitationAndCurrentMeetingsTimeIntersects = myApprovedMeeting.some(
      (meeting) => {
        return (
          (_.get(meeting, "meetingEndTime.seconds") > meetingStartTime &&
            _.get(meeting, "meetingStartTime.seconds") < meetingEndTime) ||
          meeting.coffeeId === coffeeId
        );
      }
    );

    const currentMeeting = await this.getCurrentMeeting();

    const isCurrentMeetingInCoffeeRange =
      _.get(currentMeeting, "details.meetingEndTime.seconds") >
      meetingStartTime;

    if (
      !_.isEmpty(currentInvitationsInNewCoffeeInvitationTimeRange) ||
      isCurrentMeetingInCoffeeRange ||
      isAcceptedInvitationAndCurrentMeetingsTimeIntersects
    ) {
      return;
    }

    const relation = {
      userId,
      coffeeId,
      role: USER_ROLE,
      status: ACTIVE_STATUS,
      interests,
      selectedCafe,
      offerToPay,
      meetingStartTime: FormatHelper.getTimestamp(meetingStartTime),
      meetingEndTime: FormatHelper.getTimestamp(meetingEndTime),
    };

    const relationId = await relationService.add(relation);

    return relationId;
  }

  static async acceptInvitation(acceptedInvitation) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const allMyInvitations = await this.getInvitations();

    const myApprovedMeeting = await this.getMyAcceptedMeetings();

    const isAcceptedInvitationAndCurrentMeetingsTimeIntersects = myApprovedMeeting.some(
      (meeting) => {
        return (
          _.get(meeting, "details.meetingEndTime.seconds") >
            _.get(acceptedInvitation, "meetingStartTime.seconds") &&
          _.get(meeting, "details.meetingStartTime.seconds") <
            _.get(acceptedInvitation, "meetingEndTime.seconds")
        );
      }
    );

    if (
      _.isEmpty(allMyInvitations) ||
      isAcceptedInvitationAndCurrentMeetingsTimeIntersects
    ) {
      return false;
    }

    const declinedInvitations = allMyInvitations.filter(
      (invitation) =>
        invitation.id !== acceptedInvitation.id &&
        invitation.coffeeId === acceptedInvitation.coffeeId
    );

    const coffeeToAccept = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "coffeeId",
        condition: "==",
        value: acceptedInvitation.coffeeId,
      },
      {
        field: "role",
        condition: "==",
        value: ADMIN_ROLE,
      },
    ]);

    if (_.isEmpty(coffeeToAccept)) {
      return false;
    }

    await relationService.update(coffeeToAccept[0].id, {
      status: PAST_STATUS,
    });

    await relationService.update(acceptedInvitation.id, {
      status: PAST_STATUS,
    });

    for (const invitation of declinedInvitations) {
      await this.declineInvitation(invitation.id);
    }

    return true;
  }

  static async declineInvitation(invitationId) {
    await relationService.delete(invitationId);
    return true;
  }

  static async getCurrentMeeting() {
    const myApprovedMeetings = await this.getMyAcceptedMeetings();

    if (_.isEmpty(myApprovedMeetings)) {
      return;
    }

    const invitationsIncludesCurrentTime = myApprovedMeetings.filter(
      (meeting) => {
        const isCurrentTimeIncludesInMeetingTime =
          moment().isSameOrAfter(
            moment.unix(_.get(meeting, "details.meetingStartTime.seconds"))
          ) &&
          moment().isSameOrBefore(
            moment.unix(_.get(meeting, "details.meetingEndTime.seconds"))
          );
        const isWebinar = Boolean(_.get(meeting, "coffee.webinar", false));
        if (isWebinar) {
          const isCurrentTimeIncludesInWebinarTime =
            moment().isSameOrAfter(
              moment.unix(_.get(meeting, "coffee.time_from"))
            ) &&
            moment().isSameOrBefore(
              moment.unix(_.get(meeting, "coffee.time_to"))
            );
          return isCurrentTimeIncludesInWebinarTime;
        }
        return isCurrentTimeIncludesInMeetingTime;
      }
    );

    if (_.isEmpty(invitationsIncludesCurrentTime)) {
      return;
    }

    const currentMeeting = await RelationControlCoffeeService.getPastCoffeeWithAdminAndUser(
      _.head(invitationsIncludesCurrentTime).coffeeId
    );

    return currentMeeting;
  }

  static async getInvitationById(id) {
    const relation = await relationService.getById(id);

    if (_.isEmpty(relation)) {
      return false;
    }

    if (
      _.isEmpty(relation) ||
      relation.meetingEndTime.seconds < moment().unix()
    ) {
      return;
    }

    const user = await userService.getById(relation.userId);
    const coffee = await coffeeService.getById(relation.coffeeId);
    const adminRelation = await relationService.filterByField([
      {
        field: "coffeeId",
        condition: "==",
        value: relation.coffeeId,
      },
      {
        field: "role",
        condition: "==",
        value: ADMIN_ROLE,
      },
    ]);

    if (_.isEmpty(adminRelation)) {
      return;
    }

    const admin = await userService.getById(adminRelation[0].userId);

    relation.user = user;
    relation.coffee = {
      ...coffee,
      time_from: coffee.time_from.seconds,
      time_to: coffee.time_to.seconds,
    };
    relation.admin = admin;
    return relation;
  }

  static async getInvitations() {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const myAdminRelationFromCurrentTime = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "status",
        condition: "==",
        value: ACTIVE_STATUS,
      },
      {
        field: "role",
        condition: "==",
        value: ADMIN_ROLE,
      },
      {
        field: "time",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    if (_.isEmpty(myAdminRelationFromCurrentTime)) {
      return [];
    }

    const myCoffees = [];

    for (let relation of myAdminRelationFromCurrentTime) {
      const coffee = await coffeeService.getById(relation.coffeeId);

      if (coffee.time_to.seconds < moment().unix()) {
        continue;
      }

      myCoffees.push(coffee);
    }

    const allActiveUsersInvitations = await relationService.filterByField([
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "status",
        condition: "==",
        value: ACTIVE_STATUS,
      },
    ]);

    if (_.isEmpty(allActiveUsersInvitations)) {
      return [];
    }

    const myCoffeeIds = myCoffees.map((coffee) => coffee.id);

    const currentInvitationsInMyCoffeeIds = allActiveUsersInvitations.filter(
      (relation) =>
        myCoffeeIds.includes(relation.coffeeId) &&
        relation.meetingStartTime.seconds > moment().unix()
    );

    const usersFromInvitations = [];

    for (let relation of currentInvitationsInMyCoffeeIds) {
      const user = await userService.getById(relation.userId);

      usersFromInvitations.push(user);
    }

    const myInvitationsWithCoffeeAndUsers = currentInvitationsInMyCoffeeIds.map(
      (relation) => {
        relation.user = _.find(usersFromInvitations, { id: relation.userId });
        relation.coffee = _.find(myCoffees, { id: relation.coffeeId });
        return relation;
      }
    );
    return myInvitationsWithCoffeeAndUsers;
  }

  static async getMySendedInvitations() {
    const state = store.getState();

    const userId = _.get(state, "userState.id");
    const mySendedInvitations = await relationService.filterByField([
      {
        field: "status",
        condition: "==",
        value: ACTIVE_STATUS,
      },
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "meetingEndTime",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    if (_.isEmpty(mySendedInvitations)) {
      return [];
    }

    const mySendedInvitationsWithCoffeeAndAdmin = await this.getAdminCardsFromRelationsArray(
      mySendedInvitations
    );

    return mySendedInvitationsWithCoffeeAndAdmin;
  }

  static async getMyAcceptedMeetings() {
    const state = store.getState();

    const userId = _.get(state, "userState.id");
    const allRelationFromCurrentTime = await relationService.filterByField([
      {
        field: "status",
        condition: "==",
        value: PAST_STATUS,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "meetingEndTime",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const myAdminRelationFromCurrentTime = await relationService.filterByField([
      {
        field: "status",
        condition: "==",
        value: PAST_STATUS,
      },
      {
        field: "role",
        condition: "==",
        value: ADMIN_ROLE,
      },
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "time",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const myActiveAdminRelationFromCurrentTime = await relationService.filterByField(
      [
        {
          field: "status",
          condition: "==",
          value: ACTIVE_STATUS,
        },
        {
          field: "role",
          condition: "==",
          value: ADMIN_ROLE,
        },
        {
          field: "userId",
          condition: "==",
          value: userId,
        },
        {
          field: "time",
          condition: ">",
          value: FormatHelper.getTimestamp(moment().unix()),
        },
      ]
    );

    const myWebinars = [];
    if (!_.isEmpty(myActiveAdminRelationFromCurrentTime)) {
      for (const activeAdminRelation of myActiveAdminRelationFromCurrentTime) {
        const coffee = await coffeeService.getById(
          activeAdminRelation.coffeeId
        );
        const isWebinar = [
          MeetingTypes.ZOOM_GROUP,
          MeetingTypes.LIVE,
          MeetingTypes.COFFEE_GROUP,
        ].includes(coffee.type);
        if (isWebinar) {
          myWebinars.push(activeAdminRelation);
        }
      }
    }

    const adminRelations = _.isEmpty(myAdminRelationFromCurrentTime)
      ? myWebinars
      : myAdminRelationFromCurrentTime.concat(myWebinars);

    const allRelations = _.isEmpty(allRelationFromCurrentTime)
      ? adminRelations
      : [...adminRelations, ...allRelationFromCurrentTime].filter(
          (i) => i.coffeeId
        );

    if (_.isEmpty(allRelations)) {
      return [];
    }

    const myFilteredRelations = allRelations.filter((relation) => {
      return relation.userId === userId;
    });

    const myFilteredRelationsWithCoffeeAndAdmin = await this.getAdminCardsFromRelationsArray(
      myFilteredRelations
    );

    return myFilteredRelationsWithCoffeeAndAdmin;
  }

  static async getMyWebinars(meetingStartTime, meetingEndTime) {
    const state = store.getState();
    const userId = _.get(state, "userState.id");

    const myActiveAdminRelationFromCurrentTime = await relationService.filterByField(
      [
        {
          field: "status",
          condition: "==",
          value: ACTIVE_STATUS,
        },
        {
          field: "role",
          condition: "==",
          value: ADMIN_ROLE,
        },
        {
          field: "userId",
          condition: "==",
          value: userId,
        },
        {
          field: "time",
          condition: ">",
          value: FormatHelper.getTimestamp(moment().unix()),
        },
      ]
    );

    // user webinars
    const myPastUserRelationFromCurrentTime = await relationService.filterByField(
      [
        {
          field: "status",
          condition: "==",
          value: PAST_STATUS,
        },
        {
          field: "role",
          condition: "==",
          value: USER_ROLE,
        },
        {
          field: "userId",
          condition: "==",
          value: userId,
        },
        {
          field: "meetingEndTime",
          condition: ">",
          value: FormatHelper.getTimestamp(moment().unix()),
        },
      ]
    );

    const myWebinars = [];
    if (!_.isEmpty(myActiveAdminRelationFromCurrentTime)) {
      for (const activeAdminRelation of myActiveAdminRelationFromCurrentTime) {
        const coffee = await coffeeService.getById(
          activeAdminRelation.coffeeId
        );
        const isWebinar = [MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(
          coffee.type
        );
        if (isWebinar) {
          myWebinars.push(coffee);
        }
      }
    }

    if (!_.isEmpty(myPastUserRelationFromCurrentTime)) {
      for (const pastUserRelation of myPastUserRelationFromCurrentTime) {
        const coffee = await coffeeService.getById(pastUserRelation.coffeeId);
        const isWebinar = [MeetingTypes.ZOOM_GROUP, MeetingTypes.LIVE].includes(
          coffee.type
        );
        if (isWebinar) {
          myWebinars.push(coffee);
        }
      }
    }

    if (_.isEmpty(myWebinars)) {
      return false;
    }

    const isWebinarAlreadyExist = myWebinars.some((meeting) => {
      const existWebinarTimeFrom = _.get(meeting, "time_from.seconds");
      const existWebinarTimeTo = _.get(meeting, "time_to.seconds");

      return (
        (meetingStartTime >= existWebinarTimeFrom &&
          meetingStartTime <= existWebinarTimeTo) ||
        (meetingEndTime >= existWebinarTimeFrom &&
          meetingEndTime <= existWebinarTimeTo)
      );
    });

    return isWebinarAlreadyExist;
  }

  static async getAdminCardsFromRelationsArray(relations) {
    const resultRelations = [];
    for (const relation of relations) {
      const coffee = await coffeeService.getById(relation.coffeeId);

      const adminRelation = await relationService.filterByField([
        {
          field: "coffeeId",
          condition: "==",
          value: relation.coffeeId,
        },
        {
          field: "role",
          condition: "==",
          value: ADMIN_ROLE,
        },
      ]);

      if (_.isEmpty(adminRelation)) {
        return;
      }

      let admin = {};

      if (adminRelation[0].fake) {
        admin = adminRelation[0].admin;
      } else {
        admin = await userService.getById(adminRelation[0].userId);
      }

      const userRelation = await relationService.filterByField([
        {
          field: "coffeeId",
          condition: "==",
          value: relation.coffeeId,
        },
        {
          field: "role",
          condition: "==",
          value: USER_ROLE,
        },
      ]);

      let user = {};
      if (!_.isEmpty(userRelation)) {
        user = await userService.getById(userRelation[0].userId);
      }

      const updatedRelation = _.assign(_.head(adminRelation), {
        admin,
        user,
        coffee: {
          ...coffee,
          time_from: coffee.time_from.seconds,
          time_to: coffee.time_to.seconds,
        },
        details: !_.isEmpty(userRelation) ? userRelation[0] : relation,
      });
      resultRelations.push(updatedRelation);
    }

    return resultRelations;
  }

  static async joinWebinar(coffeeId, meetingStartTime, meetingEndTime) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const myCurrentInvitations = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "meetingEndTime",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const currentInvitationsInNewCoffeeInvitationTimeRange = _.filter(
      myCurrentInvitations,
      (relation) => {
        return (
          (relation.meetingStartTime.seconds < meetingEndTime &&
            relation.meetingEndTime.seconds > meetingStartTime) ||
          relation.coffeeId === coffeeId
        );
      }
    );

    const myApprovedMeeting = await this.getMyAcceptedMeetings();

    const isAcceptedInvitationAndCurrentMeetingsTimeIntersects = myApprovedMeeting.some(
      (meeting) => {
        return (
          (_.get(meeting, "meetingEndTime.seconds") > meetingStartTime &&
            _.get(meeting, "meetingStartTime.seconds") < meetingEndTime) ||
          meeting.coffeeId === coffeeId
        );
      }
    );

    const currentMeeting = await this.getCurrentMeeting();

    const isCurrentMeetingInCoffeeRange =
      _.get(currentMeeting, "details.meetingEndTime.seconds") >
      meetingStartTime;

    if (
      !_.isEmpty(currentInvitationsInNewCoffeeInvitationTimeRange) ||
      isCurrentMeetingInCoffeeRange ||
      isAcceptedInvitationAndCurrentMeetingsTimeIntersects
    ) {
      return null;
    }

    const currentParticipantsCount = await RelationControlCoffeeService.getWebinarUsersCount(
      coffeeId
    );

    if (currentParticipantsCount < 100) {
      const relation = {
        userId,
        coffeeId,
        role: USER_ROLE,
        status: PAST_STATUS,
        interests: "",
        selectedCafe: null,
        offerToPay: false,
        meetingStartTime: FormatHelper.getTimestamp(meetingStartTime),
        meetingEndTime: FormatHelper.getTimestamp(meetingEndTime),
      };

      const relationId = await relationService.add(relation);

      return relationId;
    }
    return null;
  }

  static async joinGroupCafeMeeting(
    coffeeId,
    meetingStartTime,
    meetingEndTime,
    selectedCafe
  ) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const myCurrentInvitations = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "meetingEndTime",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const currentInvitationsInNewCoffeeInvitationTimeRange = _.filter(
      myCurrentInvitations,
      (relation) => {
        return (
          (relation.meetingStartTime.seconds < meetingEndTime &&
            relation.meetingEndTime.seconds > meetingStartTime) ||
          relation.coffeeId === coffeeId
        );
      }
    );

    const myApprovedMeeting = await this.getMyAcceptedMeetings();

    const isAcceptedInvitationAndCurrentMeetingsTimeIntersects = myApprovedMeeting.some(
      (meeting) => {
        return (
          (_.get(meeting, "meetingEndTime.seconds") > meetingStartTime &&
            _.get(meeting, "meetingStartTime.seconds") < meetingEndTime) ||
          meeting.coffeeId === coffeeId
        );
      }
    );

    const currentMeeting = await this.getCurrentMeeting();

    const isCurrentMeetingInCoffeeRange =
      _.get(currentMeeting, "details.meetingEndTime.seconds") >
      meetingStartTime;

    if (
      !_.isEmpty(currentInvitationsInNewCoffeeInvitationTimeRange) ||
      isCurrentMeetingInCoffeeRange ||
      isAcceptedInvitationAndCurrentMeetingsTimeIntersects
    ) {
      return null;
    }

    const relation = {
      userId,
      coffeeId,
      role: USER_ROLE,
      status: PAST_STATUS,
      interests: "",
      offerToPay: false,
      meetingStartTime: FormatHelper.getTimestamp(meetingStartTime),
      meetingEndTime: FormatHelper.getTimestamp(meetingEndTime),
      selectedCafe: selectedCafe,
    };

    const relationId = await relationService.add(relation);

    return relationId;
  }

  static async cancelLiveCoffee(coffeeId) {
    const relations = await relationService.filterByField([
      {
        field: "coffeeId",
        condition: "==",
        value: coffeeId,
      },
    ]);

    if (_.isEmpty(relations)) {
      return;
    }

    for (const r of relations) {
      await relationService.delete(r.id);
    }
    await coffeeService.delete(coffeeId);
  }

  static async canIJoinWebinar(coffeeId, meetingStartTime, meetingEndTime) {
    const state = store.getState();

    const userId = _.get(state, "userState.id");

    const myCurrentInvitations = await relationService.filterByField([
      {
        field: "userId",
        condition: "==",
        value: userId,
      },
      {
        field: "role",
        condition: "==",
        value: USER_ROLE,
      },
      {
        field: "meetingEndTime",
        condition: ">",
        value: FormatHelper.getTimestamp(moment().unix()),
      },
    ]);

    const currentInvitationsInNewCoffeeInvitationTimeRange = _.filter(
      myCurrentInvitations,
      (relation) => {
        return (
          (relation.meetingStartTime.seconds < meetingEndTime &&
            relation.meetingEndTime.seconds > meetingStartTime) ||
          relation.coffeeId === coffeeId
        );
      }
    );

    const myApprovedMeeting = await this.getMyAcceptedMeetings();

    const isAcceptedInvitationAndCurrentMeetingsTimeIntersects = myApprovedMeeting.some(
      (meeting) => {
        return (
          (_.get(meeting, "meetingEndTime.seconds") > meetingStartTime &&
            _.get(meeting, "meetingStartTime.seconds") < meetingEndTime) ||
          meeting.coffeeId === coffeeId
        );
      }
    );

    const currentMeeting = await this.getCurrentMeeting();

    const isCurrentMeetingInCoffeeRange =
      _.get(currentMeeting, "details.meetingEndTime.seconds") >
      meetingStartTime;

    if (
      !_.isEmpty(currentInvitationsInNewCoffeeInvitationTimeRange) ||
      isCurrentMeetingInCoffeeRange ||
      isAcceptedInvitationAndCurrentMeetingsTimeIntersects
    ) {
      return null;
    }

    const currentParticipantsCount = await RelationControlCoffeeService.getWebinarUsersCount(
      coffeeId
    );

    if (currentParticipantsCount < 100) {
      return true;
    }
    return false;
  }

  static async joinWebinarAfterPayment(coffeeId) {
    const state = store.getState();
    const userId = _.get(state, "userState.id");

    const coffee = await coffeeService.getById(coffeeId);

    const relation = {
      userId,
      coffeeId,
      role: USER_ROLE,
      status: PAST_STATUS,
      interests: "",
      selectedCafe: null,
      offerToPay: false,
      meetingStartTime: coffee.time_from,
      meetingEndTime: coffee.time_to,
    };

    await relationService.add(relation);
  }
}
