import React from "react";
import "./Support.scss";
import { APP_EMAIL_DOMAIN } from "../../shared/constants";

const Support = () => {
  return (
    <div className="support-container">
      <div className="support">
        <div className="app-title-container">
          <a className="app-title" href="/">
            CoffeeTalk
          </a>
        </div>
        <h2>Zoom App Documentation</h2>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/coffee-me-dev.appspot.com/o/files%2FCoffeeTalk_Zoom_Installation.pdf?alt=media&token=f591e92b-b1fc-421f-bdd8-3fa5e54ff346"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zoom app installation and usage documentation
        </a>
        <h2>Zoom App Support</h2>
        <p>
          If you are facing difficulty of app installation or usage, please
          contact us below.
        </p>
        <p>We will get back to you in about 1 - 2 business days.</p>
        <a href={`mailto:help@${APP_EMAIL_DOMAIN}`}>Contact us</a>
      </div>
    </div>
  );
};

export default Support;
