import { combineReducers } from "redux";
import mapState from "./map";
import userState from "./auth";
import coffeeState from "./coffee";
import appPagesState from "./app-pages";
import popUpNotificationState from "./pop-up-notification";
import topicsState from "./topics";

export const rootReducer = combineReducers({
  mapState,
  userState,
  coffeeState,
  appPagesState,
  popUpNotificationState,
  topicsState,
});
