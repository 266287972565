import React, { useState, useEffect, useRef } from "react";
import ResizePanel from "../resize-panel";

import { MapHelper } from "../../helpers/Map";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";
import { RelationControlInvitationsService } from "../../services/RelationControl";
import useOutsideClick from "../../shared/OutsideClick";
import firebase from "firebase/app";
import "firebase/auth";
import { FireBase, UserFirebaseService } from "../../services";
import { FollowersService } from "../../services/Followers";

import { FormatHelper, HistoryHelper } from "../../helpers";

import { useLocalStorage } from "../../shared/LocalStorageHook";

import UserSmallAvatar from "../user-small-avatar";
import UserAvatarEdit from "../user-avatar-edit";
import Tabs from "../tabs";

import Verifications from "../verifications";
import Spinner from "../spinner";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgLogOut } from "react-icons/cg";
import { TiDeleteOutline, TiDocument } from "react-icons/ti";
import { RiSettings5Line } from "react-icons/ri";
import { IoIosArrowBack } from "react-icons/io";
import { SiAdguard } from "react-icons/si";

import "./UserPage.scss";

import * as _ from "lodash";

import useWindowDimensions from "../../shared/windowResize";
import { MOBILE_WIDTH } from "../../shared/constants";
import ConfirmWidnow from "../confirm-window";
import ProfileSettings from "../profile-settings";
import RenderCardList from "./RenderCardList";
import FollowersList from "../followers-list";
import DesktopContextMenu from "./desktop-context-menu";
import StripeConnect from "./ConnectStripe";

const followersService = new FollowersService();

const UserPage = ({
  setTitle,
  user,
  currentLocation,
  setUserInfo,
  setClickedIcon,
  setEmptyUserInfo,
  getMainRef = null,
  setSplashStatus,
}) => {
  setTitle(_.get(user, "name"));
  const { screenWidth } = useWindowDimensions();
  const [isUserAvatarOpened, setUserAvatarOpened] = useState(false);

  const [coffees, setCoffees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [isOpenContextMenu, setOpenContextMenu] = useState(false);
  const [isOpenConfirmWindow, setOpenConfirmWindow] = useState(false);
  const [relationForInfinityScroll, setRelationForInfinityScroll] = useState(
    []
  );
  const [scrollingCounter, setScrollingCounter] = useState(0);
  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  const [currentTab, setCurrentTab] = useState("past-coffees");
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [openedList, setOpenedList] = useState(null);
  const [contextMenu, setContextMenu] = useState(0);
  const [contextMenuWidth, setContextMenuWidth] = useState(100);
  // eslint-disable-next-line
  const [localStorageUser, setLocalStorageUser] = useLocalStorage("user", null);

  const scrollContainerRef = useRef();
  const scrollElementRef = useRef();
  const contextMenuContainerRef = useRef(null);
  const firebaseApp = FireBase.getInstance().getFirebaseApp();
  const userService = new UserFirebaseService();

  const isMobileDevice = screenWidth < MOBILE_WIDTH;

  useEffect(() => {
    const getFollowers = async () => {
      const myFollowers = followersService.getMySubscribers();
      const myFollowing = followersService.getMySubscriptions();

      Promise.all([myFollowers, myFollowing])
        .then((res) => {
          setFollowers(res[0]);
          setFollowing(res[1]);
        })
        .catch((err) => {});
    };

    getFollowers();
  }, []);

  useEffect(() => {
    if (!isMobileDevice) return;
    if (!currentLocation?.lat || !currentLocation?.lng) {
      return;
    }
    async function getUserLocation() {
      const response = await MapHelper.getLocationNameByLatLng(
        currentLocation.lat,
        currentLocation.lng
      );
      setUserCurrentLocation(response);
      return response;
    }

    getUserLocation();
    // eslint-disable-next-line
  }, [currentLocation]);

  useEffect(() => {
    const countOfCards = isMobileDevice ? 5 : 10;

    if (!isMobileDevice && !_.isNil(scrollContainerRef.current)) {
      const getPastCoffeesByArray = async () => {
        if (_.isNull(scrollContainerRef.current)) {
          return;
        }

        const height =
          scrollContainerRef.current.getBoundingClientRect().height -
          getMainRef.current.scrollTop -
          400;

        if (height > 400 || currentTab === "coffee-soon") {
          return;
        }

        const dataForRequest = relationForInfinityScroll.slice(
          scrollingCounter * countOfCards,
          scrollingCounter * countOfCards + countOfCards
        );

        if (_.isEmpty(dataForRequest) || !scrollingCounter) {
          return;
        }

        setIsLoading(true);

        const pastCoffees = await RelationControlCoffeeService.getPastCoffeesByArray(
          dataForRequest
        );

        setCoffees(coffees.concat(pastCoffees));

        setScrollingCounter(scrollingCounter + 1);
        setIsLoading(false);
      };

      getMainRef.current.addEventListener("scroll", getPastCoffeesByArray);

      return () => {
        scrollContainerRef.current.removeEventListener(
          "scroll",
          getPastCoffeesByArray
        );

        if (!_.isNil(getMainRef.current) && !isOpenConfirmWindow) {
          // eslint-disable-next-line
          getMainRef.current.removeEventListener(
            "scroll",
            getPastCoffeesByArray
          );
        }
      };
    } else {
      const getPastCoffeesByArray = async () => {
        const height =
          scrollElementRef.current.getBoundingClientRect().height -
          (scrollContainerRef.current.getBoundingClientRect().height +
            scrollContainerRef.current.scrollTop);

        if (height > 30 || height < 0 || currentTab === "coffee-soon") {
          return;
        }

        const dataForRequest = relationForInfinityScroll.slice(
          scrollingCounter * countOfCards,
          scrollingCounter * countOfCards + countOfCards
        );

        if (_.isEmpty(dataForRequest) || !scrollingCounter) {
          return;
        }

        setIsLoading(true);

        const pastCoffees = await RelationControlCoffeeService.getPastCoffeesByArray(
          dataForRequest
        );

        setCoffees(coffees.concat(pastCoffees));

        setScrollingCounter(scrollingCounter + 1);
        setIsLoading(false);
      };

      scrollContainerRef.current.addEventListener(
        "scroll",
        getPastCoffeesByArray
      );

      return () => {
        // eslint-disable-next-line
        scrollContainerRef.current.removeEventListener(
          "scroll",
          getPastCoffeesByArray
        );
      };
    }
  });

  useEffect(() => {
    if (contextMenuWidth < 30 && contextMenuWidth !== 0) {
      handleContextMenu();
    }
    // eslint-disable-next-line
  }, [contextMenuWidth]);

  const getCoffeeSoon = async () => {
    setIsLoading(true);
    const allMyAcceptedMeetings = await RelationControlInvitationsService.getMyAcceptedMeetings();
    const allMySendedInvitations = await RelationControlInvitationsService.getMySendedInvitations();

    return {
      allMyAcceptedMeetings: allMyAcceptedMeetings,
      allMySendedInvitations: allMySendedInvitations,
    };
  };

  const afterCoffeeSoonLoad = (data) => {
    setCoffees([
      ..._.concat(data.allMyAcceptedMeetings, data.allMySendedInvitations),
    ]);
    setCurrentTab("coffee-soon");
    setIsLoading(false);
  };

  const getPastCoffees = async () => {
    setIsLoading(true);
    const coffeeCount = isMobileDevice ? 5 : 10;
    const pastCoffeesObj = await RelationControlCoffeeService.getPastCoffees(
      coffeeCount
    );

    return pastCoffeesObj;
  };

  const afterPastCoffeeLoad = (pastCoffeesObj) => {
    if (_.isEmpty(pastCoffeesObj)) {
      setIsLoading(false);
      return;
    }

    setCurrentTab("past-coffees");
    setCoffees(pastCoffeesObj.pastCoffees);
    setRelationForInfinityScroll(pastCoffeesObj.relations);
    setScrollingCounter(1);
    setIsLoading(false);
  };

  const deleteInvitation = async (invitationId) => {
    const isInvitationDeleted = await RelationControlInvitationsService.declineInvitation(
      invitationId
    );
    if (isInvitationDeleted) {
      const updatedCoffees = _.filter(coffees, (coffee) => {
        return _.get(coffee, "details.id") !== invitationId;
      });
      setCoffees(updatedCoffees);
    }
  };

  const userOpenedAvatar = () => {
    return (
      <UserAvatarEdit
        setUserAvatarOpened={setUserAvatarOpened}
        setUserInfo={setUserInfo}
        setIsLoadingAvatar={setIsLoadingAvatar}
        userAvatarLink={_.get(user, "photoURL")}
        user={user}
        isUserAvatar={true}
      />
    );
  };

  const userAvatar = () => {
    return (
      <div className="user-avatar">
        {isMobileDevice && (
          <button
            className="followers-button"
            onClick={() => setOpenedList("followers")}
          >
            <p>{followers.length}</p>
            <p className="text">Followers</p>
          </button>
        )}
        <UserSmallAvatar
          setUserAvatarOpened={setUserAvatarOpened}
          userAvatarLink={_.get(user, "photoURL")}
          avatarClassName="user-img"
          edit={true}
          onClick={(event) => {
            setUserAvatarOpened(true);
          }}
        />
        {isMobileDevice && (
          <button
            className="followers-button"
            onClick={() => setOpenedList("following")}
          >
            <p>{following.length}</p>
            <p className="text">Following</p>
          </button>
        )}
      </div>
    );
  };

  const handleContextMenu = (e) => {
    if (
      e?.toElement?.id === "dots" ||
      (e?.target?.tagName === "path" && isOpenContextMenu)
    ) {
      return;
    }

    if (isOpenContextMenu) {
      setOpenContextMenu(false);
      return;
    }
    setContextMenu(0);
    setOpenContextMenu(true);
  };

  const clearStorages = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const logOut = () => {
    firebaseApp.auth().signOut();
    setEmptyUserInfo();
    clearStorages();
    setLocalStorageUser(FormatHelper.getEmptyUserInfoForRedux());
    setClickedIcon("home-icon");
    HistoryHelper.redirectTo("/");
    setSplashStatus(false);
  };

  const goToTermOfUse = () => {
    HistoryHelper.redirectTo("/term-of-use");
  };

  const goToPrivacyPolicy = () => {
    HistoryHelper.redirectTo("/privacy-policy");
  };

  const openConfirmWindow = () => {
    handleContextMenu();
    setOpenConfirmWindow(true);
  };

  const deleteAccount = async () => {
    await userService.deleteUser(user);

    const currentUser = firebase.auth().currentUser;
    await currentUser.delete();

    await firebaseApp.auth().signOut();
    clearStorages();
    setEmptyUserInfo();
    setLocalStorageUser(FormatHelper.getEmptyUserInfoForRedux());
    setClickedIcon("home-icon");
    HistoryHelper.redirectTo("/");
  };

  const removeUserFromFollowingList = (user) => {
    const updatedList = following.filter((f) => f.follower.id !== user.id);
    setFollowing(updatedList);
  };

  const filterCoffeesAfterCancel = (coffeeId) => {
    const updatedCoffees = coffees.filter((c) => {
      if (c?.coffeeId) {
        return c.coffeeId !== coffeeId;
      }
      return c.id !== coffeeId;
    });
    setCoffees(updatedCoffees);
  };

  const initContextMenu = () => {
    return (
      <div>
        <button className="menu-button" onClick={goToPrivacyPolicy}>
          <SiAdguard />
          <p>Privacy Policy</p>
        </button>
        <button className="menu-button" onClick={goToTermOfUse}>
          <TiDocument />
          <p>Terms of Service</p>
        </button>
        <button className="menu-button" onClick={logOut}>
          <CgLogOut />
          <p>Sign Out</p>
        </button>
        <button className="menu-button" onClick={() => setContextMenu(1)}>
          <RiSettings5Line />
          <p>Settings and preferences</p>
        </button>
      </div>
    );
  };

  const secondStepContextMenu = () => {
    return (
      <div>
        <button className="menu-button" onClick={() => setContextMenu(0)}>
          <IoIosArrowBack />
          <p>Go back</p>
        </button>
        <button className="menu-button" onClick={openConfirmWindow}>
          <TiDeleteOutline />
          <p>Delete account</p>
        </button>
      </div>
    );
  };

  useOutsideClick(contextMenuContainerRef, handleContextMenu);

  return (
    <div className="user-page">
      {!isMobileDevice && (
        <div className="profile-title">
          <div>Profile</div>
          <BsThreeDotsVertical
            onClick={(e) => {
              e.stopPropagation();
              handleContextMenu();
            }}
            className="dots"
            id="dots"
          />
        </div>
      )}
      {isUserAvatarOpened && userOpenedAvatar()}
      {openedList && (
        <FollowersList
          followers={followers}
          following={following}
          openedList={openedList}
          setOpenedList={setOpenedList}
          removeUserFromFollowingList={removeUserFromFollowingList}
        />
      )}
      {isOpenConfirmWindow && (
        <ConfirmWidnow
          text="Deleting your account will remove all of your information, the action is permanent and cannot be undone."
          deleteAccount={deleteAccount}
          setOpenConfirmWindow={setOpenConfirmWindow}
        />
      )}
      {isLoadingAvatar && (
        <Spinner loaderContainerName="loader-container" blockName="user-page" />
      )}
      <div className="user-data-container">
        {isMobileDevice && (
          <BsThreeDotsVertical onClick={handleContextMenu} className="dots" />
        )}
        {isMobileDevice && isOpenContextMenu && (
          <div className="context-menu-container">
            <div className="context-menu" ref={contextMenuContainerRef}>
              <ResizePanel
                direction="n"
                handleClass="customHandle"
                borderClass="customResizeBorder"
                setContextMenuWidth={setContextMenuWidth}
              >
                {contextMenu === 0
                  ? initContextMenu()
                  : secondStepContextMenu()}
              </ResizePanel>
            </div>
          </div>
        )}
        {!isMobileDevice && isOpenContextMenu && (
          <DesktopContextMenu
            contextMenu={contextMenu}
            handleOutsideClickSettingsMenu={handleContextMenu}
            initContextMenu={initContextMenu}
            secondStepContextMenu={secondStepContextMenu}
          />
        )}
        {userAvatar()}
        {isMobileDevice && (
          <>
            <p className="user-name">{_.get(user, "name")}</p>
            <p className="user-location">{userCurrentLocation}</p>
          </>
        )}
        {isMobileDevice ? (
          <div className="user-verifications-container">
            <span className="verifications-text">Verifications</span>
            <Verifications authMethods={_.get(user, "authMethods")} />
          </div>
        ) : (
          <React.Fragment>
            <div className="user-data">
              <p className="user-name">{_.get(user, "name")}</p>
              <Verifications authMethods={_.get(user, "authMethods")} />
            </div>
            <div className="followers-list">
              <button
                onClick={() => setOpenedList("followers")}
                className="followers-button"
              >
                <p>{followers.length}</p>
                <p className="text">Followers</p>
              </button>
              <button
                onClick={() => setOpenedList("following")}
                className="followers-button"
              >
                <p>{following.length}</p>
                <p className="text">Following</p>
              </button>
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="stripe-connect-wrapper">
        <StripeConnect user={user} setUserInfo={setUserInfo} />
      </div>

      <ProfileSettings
        user={user}
        setUserInfo={setUserInfo}
        setLocalStorageUser={setLocalStorageUser}
      />

      <Tabs
        tabs={[
          {
            text: "Upcoming Talks",
            getData: getCoffeeSoon,
            afterLoad: afterCoffeeSoonLoad,
          },
          {
            text: "All Talks",
            getData: getPastCoffees,
            afterLoad: afterPastCoffeeLoad,
          },
        ]}
        currentLocation={currentLocation}
        tabByDefault={0}
        setIsLoading={setIsLoading}
      />
      <div className="coffee-cards-container-wrapper" ref={scrollContainerRef}>
        <RenderCardList
          isLoading={isLoading}
          scrollElementRef={scrollElementRef}
          coffees={coffees}
          deleteInvitation={deleteInvitation}
          user={user}
          currentTab={currentTab}
          filterCoffeesAfterCancel={filterCoffeesAfterCancel}
        />
      </div>
    </div>
  );
};

export default UserPage;
