import React, { useRef } from "react";

import { Grid } from "@material-ui/core";

import useOutsideClick from "../../shared/OutsideClick";
import CloseButton from "../../shared/icons/edit-pop-up-close-button.svg";
import { HistoryHelper } from "../../helpers";

import "./StripeModal.scss";

const StripeModal = ({ state, setStripeModalStatus }) => {
  const popUpRef = useRef(null);

  const closePopUp = () => {
    setStripeModalStatus("");
  };

  useOutsideClick(popUpRef, closePopUp);

  const onContinueButtonClick = () => {
    if (state === "done") {
      HistoryHelper.redirectTo("/new-talk");
      closePopUp();
      return;
    }

    closePopUp();
  };

  return (
    <div className="user-avatar-edit-container">
      <div className="stripe-container" ref={popUpRef}>
        <Grid container justify="flex-end" alignItems="center">
          <button onClick={closePopUp}>
            <img src={CloseButton} alt="close" />
          </button>
        </Grid>

        <h2>
          {state === "pending"
            ? "Complete registration with stripe."
            : "You are connected with stripe, you can now continue and get paid."}
        </h2>

        <button className="save-button" onClick={onContinueButtonClick}>
          {state === "pending" ? "Continue" : "Get paid now"}
        </button>
      </div>
    </div>
  );
};

export default StripeModal;
