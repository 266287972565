import * as _ from 'lodash';
import { FirestoreDatabaseService } from '../shared/FirestoreDatabase';
import { CoffeeFirebaseService } from './CoffeeDB';

import { store } from './../redux/store/index';

const TABLE_NAME = '/rates';
const coffeeService = new CoffeeFirebaseService();

export class RateFirebaseService extends FirestoreDatabaseService {
  constructor() {
    super(TABLE_NAME);
  }

  async addRate(rate, coffeeId, cardAdminId, cardUserId) {
    const state = store.getState();
    const userId = _.get(state, 'userState.id');

    if (userId !== cardUserId) {
      return;
    }

    const coffee = await coffeeService.getById(coffeeId);

    const isCoffeeRated = _.get(coffee, 'rate');
    if (isCoffeeRated) {
      return;
    }

    const updatedCoffee = _.assign({}, coffee, { rate: true });
    await coffeeService.update(coffeeId, updatedCoffee);

    const rateForAdd = _.assign({}, rate, {
      coffeeId: coffeeId,
      cardAdminId: cardAdminId,
      cardUserId: cardUserId,
    });
    await this.add(rateForAdd);
  }
}
