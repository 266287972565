import * as moment from "moment";
import * as _ from "lodash";

export class TimePickerService {
  static getCurrentTime() {
    const currentTime = moment()
      .add(1, "hour")
      .startOf("hour");
    return currentTime;
  }

  static getRoundedTime() {
    let hours = moment().hours();
    let minutes = Math.ceil(moment().minute() / 15) * 15;

    if (minutes === 60) {
      minutes = 0;
      hours++;
    }

    return moment(`${hours}:${minutes}`, ["h:mm A"]);
  }

  static getTimeWithPeriod(time, period) {
    return moment(time.toString() + " " + period.toString(), ["h:mm A"]).second(
      0
    );
  }

  static calculateViewTimeIntervals(selectedTimeInterval) {
    let timeInterval = selectedTimeInterval;
    if (_.isUndefined(timeInterval)) {
      timeInterval = this.getCurrentTime();
    } else {
      timeInterval = moment(timeInterval.toString(), ["h:mm A"]).format();
    }
    const timeIntervals = [];
    const startTime = moment(timeInterval).subtract(30, "minutes");
    const endTime = moment(timeInterval).add(31, "minutes");

    while (startTime.isBefore(endTime, "minutes")) {
      timeIntervals.push(moment(startTime).format("h:mm"));
      startTime.add(15, "minutes");
    }
    return timeIntervals;
  }

  static getTypeOfDateTimeFrom(user) {
    const availabilityFrom = _.get(user, "availabilityFrom");
    if (_.isUndefined(availabilityFrom)) {
      const currentTime = this.getRoundedTime();

      const availabilityFrom = moment(currentTime).format("A");
      return availabilityFrom;
    }

    const availability = moment(user.availabilityFrom * 1000).format("A");
    return availability;
  }

  static getTypeOfDateTimeTo(user) {
    const availabilityTo = _.get(user, "availabilityTo");
    if (_.isUndefined(availabilityTo)) {
      const currentTime = this.getRoundedTime();
      const availabilityTo = moment(currentTime)
        .add(15, "m")
        .format("A");
      return availabilityTo;
    }

    const availability = moment(user.availabilityTo * 1000).format("A");

    return availability;
  }

  static getTypeOfSelectedDay(user) {
    const availabilityFrom = _.get(user, "availabilityFrom");
    if (_.isUndefined(availabilityFrom)) {
      return "soon";
    }

    const currentTime = moment().format("L");
    const availabilityTime = moment(availabilityFrom * 1000).format("L");

    if (moment(currentTime).isSame(availabilityTime, "date")) {
      return "soon";
    }

    if (moment(availabilityTime).diff(currentTime, "days") === 1) {
      return "tomorrow";
    }

    return "soon";
  }

  static getTimeFromMS(timeInMS, addMinutes = 0) {
    if (!_.isUndefined(timeInMS)) {
      return moment(timeInMS).format("h:mm A");
    }
    return TimePickerService.getRoundedTime()
      .add(addMinutes, "minutes")
      .format("h:mm A");
  }

  static isTodayDate(date) {
    return moment().isSame(date * 1000, "day");
  }

  static getAvailabilitiesIntersection(
    userAvailabilityStart,
    coffeeAvailabilityStart
  ) {
    const currentTime = new Date().getTime();

    if (userAvailabilityStart < coffeeAvailabilityStart) {
      const isCoffeeStartTimeBeforeCurrent =
        coffeeAvailabilityStart * 1000 < currentTime;

      if (isCoffeeStartTimeBeforeCurrent) {
        const minutes = moment(currentTime).get("minutes");
        const delay = 5 - (minutes % 5);
        return moment(currentTime)
          .add(delay, "minute")
          .unix();
      }
      return coffeeAvailabilityStart;
    }

    const isUserAvailabilityStartTimeBeforeCurrent =
      userAvailabilityStart * 1000 < currentTime;

    if (isUserAvailabilityStartTimeBeforeCurrent) {
      const minutes = moment(currentTime).get("minutes");
      const delay = 5 - (minutes % 5);
      return moment(currentTime)
        .add(delay, "minute")
        .unix();
    }
    return userAvailabilityStart;
  }

  static getTimeDiff(meetingStart) {
    const minutesDiff = moment(meetingStart * 1000).diff(moment(), "minutes");

    if (minutesDiff < 1) {
      return "right now";
    }
    const diffFromStartOfDay = moment()
      .startOf("day")
      .add(minutesDiff, "minutes");
    const formattedTimeDiff = `${diffFromStartOfDay.format(
      "HH"
    )}h ${diffFromStartOfDay.format("mm")} min`;
    return formattedTimeDiff;
  }
}
