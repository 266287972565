import * as actions from '../redux/actions';
import { connect } from 'react-redux';
import TimePageComponent from '../components/time-page/index';

export function mapStateToProps({ userState }) {
  return {
    user: userState,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setUserInfo: payload => dispatch(actions.setUserInfo(payload)),
    setAppPagesStatus: payload => dispatch(actions.setAppPagesStatus(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimePageComponent);
