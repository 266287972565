import firebase from "firebase/app";
import {APP_DOMAIN} from "../shared/constants";

export class EmailService {
  static async sendMessage(email, subject, text) {
    await firebase
      .firestore()
      .collection("mail")
      .add({
        to: email,
        message: {
          subject: subject,
          text: text,
        },
      });
  }

  static async sendNotificationEmail(bcc, subject, text) {
    await firebase
      .firestore()
      .collection("mail")
      .add({
        to: "",
        bcc: bcc,
        message: {
          subject: subject,
          html: `<p>${text}</p><a href={APP_DOMAIN} target="_blank" rel="noopener noreferrer">See all upcoming events</a>`,
        },
      });
  }

  static async sendHtmlMessage(email, subject, html) {
    await firebase
      .firestore()
      .collection("mail")
      .add({
        to: email,
        message: {
          subject: subject,
          html: html,
        },
      });
  }
}
