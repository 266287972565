import { SET_CURRENT_LOCATION } from "../constants/index";

const initialState = {
  currentLocation: null,
};

const actionHandlers = {
  [SET_CURRENT_LOCATION](state, currentLocation) {
    return { ...state, currentLocation };
  },
};

export default function mapState(state = initialState, action) {
  const { type, payload = null } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
}
