import React from 'react';

import MobileUserShortInfo from './MobileUserShortInfo';
import DesktopUserShortInfo from './DesktopUserShortInfo';

import useWindowDimensions from '../../shared/windowResize';
import { MOBILE_WIDTH } from '../../shared/constants';

const UserShortInfo = props => {
  const { screenWidth } = useWindowDimensions();

  return screenWidth < MOBILE_WIDTH ? (
    <MobileUserShortInfo {...props} />
  ) : (
    <DesktopUserShortInfo {...props} />
  );
};

export default UserShortInfo;
