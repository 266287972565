import React, {useEffect, useState} from "react";

import {useStripe} from "@stripe/react-stripe-js";
import "./ConfirmPayment.scss";
import {StripePaymentFirebaseService} from "../../services/StripePaymentsDB";
import {HistoryHelper} from "../../helpers";

function ConfirmPayment() {
  const stripe = useStripe();
  const [message, setMessage] = useState("");

  const stripePaymentFB = new StripePaymentFirebaseService();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      let status = "Something went wrong.";

      switch (paymentIntent.status) {
        case "succeeded":
          stripePaymentFB.confirmPayment(clientSecret, paymentIntent);
          setMessage("Payment succeeded!");
          status = "Payment succeeded!";
          break;
        case "processing":
          setMessage("Your payment is processing.");
          status = "Your payment is processing.";
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          status = "Your payment was not successful, please try again.";
          break;
        default:
          setMessage("Something went wrong.");
          status = "Something went wrong.";
          break;
      }

      HistoryHelper.redirectTo({
        pathname: '/',
        search: `?status=${status}`
      });
    });
  }, [stripe, stripePaymentFB]);

  return (
    <div className="confirm-payment">
      <p>{message}</p>
      {/*<div>*/}
      {/*  <Link to={"/"}>Go Home Page</Link>*/}
      {/*  <Link to={"/user"}>Open Upcoming Talks</Link>*/}
      {/*</div>*/}
    </div>
  );
}

export default ConfirmPayment;
