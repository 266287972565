import React, { useState, useEffect } from "react";
import MapContainer from "../../containers/MapSearch";
import { TimePickerService } from "../../services/TimePicker";
import CoffeeDetails from "../coffee-details";
import CoffeeList from "../coffee-list";
import Spinner from "../spinner";

import { HistoryHelper } from "../../helpers";
import "./GrabCoffee.scss";
import * as _ from "lodash";
import { RelationControlInvitationsService } from "../../services/RelationControl";
import { RelationControlCoffeeService } from "../../services/RelationControlCoffee";
import moment from "moment";
import { EmailService } from "../../services/Email";
import { NotificationsService } from "../../services/Notifications";
import { APP_DOMAIN, APP_EMAIL_DOMAIN } from "../../shared/constants";

const MAX_LENGTH = {
  perspective: 120,
};

const GrabCoffeePage = ({
  coffee,
  user,
  setPopUpNotificationText,
  setActiveGrabCoffee,
}) => {
  const cafes = _.get(coffee, "coffees", []);
  const vicinity = _.get(coffee, "coffees[0].vicinity", "Zoom");
  const timeFrom = _.get(coffee, "time_from");

  const [currentCoffees] = useState(cafes);
  const [selectedCoffees, setSelectedCoffees] = useState([]);
  const [currentStep, setCurrentStep] = useState(vicinity === "Zoom" ? 1 : 0);

  const [perspectiveText, setPerspectiveText] = useState("");
  const [charactersLeft, setCharactersLeft] = useState(120);
  const [currentCoffeeDetailStep, setCurrentCoffeeDetailStep] = useState(0);
  const [title, setTitle] = useState("Talk Details");
  // eslint-disable-next-line
  const [relationId, setRelationId] = useState(null);
  const [treat, setTreatStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const meetingStartTime = TimePickerService.getTimeFromMS(
    TimePickerService.getAvailabilitiesIntersection(
      user.availabilityFrom,
      coffee.time_from
    ) * 1000
  );

  useEffect(() => {
    const header = document.getElementsByTagName("main")[0];
    header.style.top = "0";
    return () => {
      header.style.top = "55px";
    };
  }, []);

  const onCancelClick = () => {
    setActiveGrabCoffee({});
  };

  const setFormField = (event) => {
    const formValueFromEvent = event.target.value;
    const validCharactersIsLeft =
      MAX_LENGTH.perspective - formValueFromEvent.length;
    if (validCharactersIsLeft < 0) {
      return;
    }
    setPerspectiveText(formValueFromEvent);
    setCharactersLeft(validCharactersIsLeft);
  };

  const isAlreadySelected = (coffee) => {
    return !!_.find(selectedCoffees, { id: coffee.id });
  };

  const addCoffees = (coffee) => {
    if (isAlreadySelected(coffee)) {
      _.remove(selectedCoffees, { id: coffee.id });
    } else {
      if (selectedCoffees.length >= 1) {
        return;
      }
      selectedCoffees.push(coffee);
    }

    setSelectedCoffees(() => [...selectedCoffees]);
  };

  const openInvitationView = () => {
    setCurrentCoffeeDetailStep(1);
    setTitle("Coffee Meeting Confirmation");
  };

  // const getUserName = (userFullName) => {
  //   const splitedName = userFullName.split(" ");
  //   return splitedName[0];
  // };

  const sendInvitation = async () => {
    setIsLoading(true);

    if (coffee.fake) {
      await EmailService.sendMessage(
        `help@${APP_EMAIL_DOMAIN}`,
        `CoffeeTalk user clicked on grab coffee`,
        `CoffeeTalk user ${user.name} clicked on grab coffee "${coffee.description}"`
      );

      const hoursDiff = moment(coffee.time_from * 1000).diff(moment(), "hours");

      const dayDiff = hoursDiff / 24;
      const roundedDaysDiff = Math.ceil(dayDiff);

      const grabTime = TimePickerService.isTodayDate(timeFrom)
        ? moment(meetingStartTime, ["h:mm A"])
        : moment(meetingStartTime, ["h:mm A"]).add(roundedDaysDiff, "d");

      const newCoffee = await RelationControlCoffeeService.addFakeCoffee(
        coffee
      );

      const cafe = vicinity === "Zoom" ? null : selectedCoffees[0];

      const invitationRelationId = await RelationControlInvitationsService.sendInvitation(
        newCoffee,
        cafe,
        perspectiveText,
        grabTime.unix(),
        grabTime.add(coffee.duration_time, "m").unix(),
        treat
      );

      setIsLoading(false);

      if (_.isEmpty(invitationRelationId)) {
        setPopUpNotificationText({
          text:
            "You have already sent invitation for this coffee or at this time",
        });
        return;
      }

      HistoryHelper.redirectTo("/user");
      return;
    }

    const hoursDiff = moment(coffee.time_from * 1000).diff(moment(), "hours");

    const dayDiff = hoursDiff / 24;
    const roundedDaysDiff = Math.ceil(dayDiff);

    const grabTime = TimePickerService.isTodayDate(timeFrom)
      ? moment(meetingStartTime, ["h:mm A"])
      : moment(meetingStartTime, ["h:mm A"]).add(roundedDaysDiff, "d");

    const cafe = vicinity === "Zoom" ? null : selectedCoffees[0];

    const invitationRelationId = await RelationControlInvitationsService.sendInvitation(
      coffee.id,
      cafe,
      perspectiveText,
      grabTime.unix(),
      grabTime.add(coffee.duration_time, "m").unix(),
      treat
    );

    setIsLoading(false);

    if (_.isEmpty(invitationRelationId)) {
      setPopUpNotificationText({
        text:
          "You have already sent invitation for this coffee or at this time",
      });
      return;
    }

    const cardAdminToken = _.get(coffee, "user.token");
    const username = _.get(user, "name");

    await NotificationsService.sendNotification(
      cardAdminToken,
      ` ${username} wants to grab coffee! Check CoffeeTalk to prepare for your meeting`,
      "/notifications"
    );

    await EmailService.sendHtmlMessage(
      _.get(coffee, "user.email"),
      `CoffeeTalk invitation with ${user.name}`,
      `<p>Hi! You have an invitation from ${user.name} about "${coffee.description}".</p>
      <p>Open CoffeeTalk to accept the meeting.</p>
      <p>If you don't want to have a meeting just ignore this email.</p>
      <p>Link: <a href=${APP_DOMAIN} target="_blank" rel="noopener noreferrer" >${APP_DOMAIN}</a></p>`
    );

    setRelationId(invitationRelationId);
    setPopUpNotificationText({
      text: "Your invitation has been sent.",
    });
    HistoryHelper.redirectTo("/user");
  };

  const goBack = () => {
    if (vicinity !== "Zoom") {
      setCurrentStep(0);
      return;
    }
    onCancelClick();
  };

  const meetingStep = () => {
    return (
      <React.Fragment>
        <div className="map-container">
          <p className="where-to-meet-text">Where we meeting?</p>
          <p className="select-shops-text">
            Pick from one of these coffee shops
          </p>
          <MapContainer isSearchable={false} coffeeList={coffee.coffees} />
        </div>
        <div className="coffee-list-container">
          {currentCoffees.length > 0 && (
            <CoffeeList
              coffees={currentCoffees}
              addCoffees={addCoffees}
              isAlreadySelected={isAlreadySelected}
            />
          )}
        </div>
        <div className="finish-button-container">
          <button className="cancel-button" onClick={onCancelClick}>
            Cancel
          </button>
          <button
            className={`done-button ${
              selectedCoffees.length === 1 ? "active" : ""
            }`}
            onClick={() => {
              if (selectedCoffees.length === 0) {
                return;
              }
              setCurrentStep(1);
            }}
          >
            Talk Details
          </button>
        </div>
      </React.Fragment>
    );
  };

  const perspectiveTextareaComponent = () => {
    return (
      <React.Fragment>
        <div>
          <textarea
            value={perspectiveText}
            onChange={(e) => setFormField(e)}
            name="perspective"
            placeholder="What’s your perspective?"
          />
          <div className="characters-left-container">
            <p className="characters-left">{charactersLeft} characters left</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const perspectiveTextComponent = () => {
    return (
      <React.Fragment>
        <span className="perspective-text">{perspectiveText}</span>
      </React.Fragment>
    );
  };

  const coffeeDetailsButtons = () => {
    return (
      <React.Fragment>
        <div
          className={
            vicinity !== "Zoom"
              ? "invite-button-container"
              : "invite-button-container zoom"
          }
        >
          <button
            className="back-button"
            onClick={() => {
              goBack();
            }}
          >
            Back
          </button>

          <button className="done-button active" onClick={openInvitationView}>
            {/*Invite {getUserName(_.get(coffee, "user.name"))} to meet*/}
            Join Talk
          </button>
        </div>
      </React.Fragment>
    );
  };

  const coffeeMeetingConfirmationButtons = () => {
    return (
      <React.Fragment>
        {isLoading && (
          <Spinner
            loaderContainerName="invitation-page-loader-container"
            blockName="grab-coffee-container"
          />
        )}
        <div className="meeting-confirmation-container">
          <button className="cancel-talk-button" onClick={onCancelClick}>Back to home</button>
          {/* <button className="back-to-feed-button" onClick={onCancelClick}>
            Back to Home
          </button> */}
          <button className="active" onClick={() => sendInvitation()}>
            Go to my talk list
          </button>
        </div>
      </React.Fragment>
    );
  };

  const coffeeDetailsData = () => {
    return (
      <React.Fragment>
        <div className="grab-coffee-meeting-details-titles">
          <div className="coffee-details-text">
            <span className="title">Meeting with</span>
            <span className="bold-details-text">
              {_.get(coffee, "user.name")}
            </span>
          </div>

          <div className="coffee-details-text">
            <span className="title">Time & Date</span>
            <div className="grab-coffee-meeting-details-time">
              <div className="text-button-container">
                <span className="bold-details-text">
                  {TimePickerService.isTodayDate(timeFrom)
                    ? "Today at"
                    : `${moment(timeFrom * 1000).format("ll")}`}
                  {" at "}
                  {meetingStartTime}
                </span>
              </div>

              {/* <div className="details-text">
                <span className="details-text">
                  for a {coffee.duration_time} minute conversation
                </span>
              </div> */}
            </div>
          </div>

          {vicinity !== "Zoom" ? (
            <div className="coffee-details-text">
              <span className="title">Coffee shop</span>
              <div className="grab-coffee-meeting-details-coffee-shop">
                <span className="bold-details-text">
                  {selectedCoffees[0].name}
                </span>
                <span className="details-text">
                  {selectedCoffees[0].vicinity}
                </span>
              </div>
            </div>
          ) : (
            <div className="coffee-details-text zoom">
              <span>Zoom meeting</span>
            </div>
          )}

          {currentCoffeeDetailStep === 1 ? (
            <>
              <div className="coffee-details-text">
                <span className="title">Offer to pay?</span>
                <span className="offer-to-pay-text">
                  {treat
                    ? `You are buying ${_.get(coffee, "user.name")} a coffee.`
                    : "No"}
                </span>
              </div>
              <div className="coffee-details-text">
                <span className="title">Next steps</span>
                <span className="offer-to-pay-text">
                  We’ve sent an invitation to {_.get(coffee, "user.name")}{" "}
                  She/He will have the option to accept or pass on the
                  invitation.
                </span>
              </div>
            </>
          ) : null}
        </div>
      </React.Fragment>
    );
  };

  const offerToPay = () => {
    if (vicinity !== "Zoom") {
      return (
        <React.Fragment>
          <div className="offer-to-pay-container">
            <p className="grab-offer-to-pay">Offer to pay?</p>
            <div className="treat-container">
              <p className="grab-offer-to-pay-text">
                Do you want to treat {_.get(coffee, "user.name")}?
              </p>
              <label className="toggle-button">
                <span
                  className={treat ? "slider-on" : "slider-off"}
                  onClick={() => setTreatStatus(!treat)}
                >
                  <span className="slider-button" />
                </span>
              </label>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const coffeeDetailsStep = () => {
    return currentCoffeeDetailStep === 0 ? (
      <CoffeeDetails
        coffee={coffee}
        title={title}
        coffeeDetailsData={coffeeDetailsData}
        buttons={coffeeDetailsButtons}
        perspectiveTextareaComponent={perspectiveTextareaComponent}
        offerToPay={offerToPay}
      />
    ) : (
      <CoffeeDetails
        coffee={coffee}
        title={title}
        coffeeDetailsData={coffeeDetailsData}
        buttons={coffeeMeetingConfirmationButtons}
        perspectiveTextareaComponent={perspectiveTextComponent}
      />
    );
  };

  const steps = [
    { text: "Where we meeting?", component: meetingStep },
    { text: "Talk Details", component: coffeeDetailsStep },
  ];

  return (
    <div className="grab-coffee-container">
      <div className="stepper-container">
        {steps.map((item, index) => (
          <div
            key={index}
            className={`step ${index <= currentStep ? "active" : ""}`}
          >
            <div className="stepper-body">
              <p>{item.text}</p>
            </div>
            <div
              className={`arrow-container ${
                index + 1 === steps.length ? "last-step" : ""
              }`}
            >
              <div className="stepper-arrow" />
              <div className="stepper-arrow-border-div" />
              <div className="stepper-arrow-border" />
            </div>
          </div>
        ))}
      </div>
      {steps[currentStep].component()}
    </div>
  );
};

export default GrabCoffeePage;
